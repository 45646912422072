import React, { useState } from "react";
import {
  startOfMonth,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  eachDayOfInterval,
  format,
} from "date-fns";
import moment, { Moment } from "moment";
import { BookingSchema, KitchenSpaceSchema } from "../data/types";
// import { darkenColor, getBookingStatusColor } from "../utils/helpers";

type MonthViewProps = {
  currentDate: Date;
  bookings: BookingSchema[];
  nHours: number;
  frequency?: 'daily' | 'weekly' | 'monthly' | 'annual',
  endRecurrence?: string
  updateStartTime: (startTime: Moment) => void;
  updateEndTime: (endTime: Moment) => void;
  currentKitchen?:KitchenSpaceSchema

};
interface TimeBlock {
  start: Moment;
  end: Moment;
}

const ReservationCalendar: React.FC<MonthViewProps> = ({
  bookings,
  currentDate,
  nHours,
  frequency,
  endRecurrence,
  updateEndTime,
  updateStartTime,
  currentKitchen,
}) => {
  const firstDayOfMonth = startOfWeek(startOfMonth(currentDate));
  const lastDayOfMonth = endOfWeek(endOfMonth(currentDate));
  // const [bookingStart, setBookingStart] = useState(new Date());
  // var bookingStart = new Date();
  const [showTimes, setShowTimes] = useState(false);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [availableTimeBlocks, setBlocks] = useState<TimeBlock[]>([]);
  const [selectedBlock, setSelectedBlock] = useState<TimeBlock>();

  // const availableTimesRef = useRef(null);
  // const scrollToAvailableTimes = () => {
  //   // availableTimesRef.current.scrollTo({ behavior: 'smooth' })
  //   const section = document.querySelector( '#available-times' );
  //   if (section){section.scrollIntoView( { behavior: 'smooth', block: 'start' } );}
  // };

  const daysInMonth = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMonth,
  });
  const findBookingsForDay = (day: Moment) => {
    const bookingsForDay = [];

    for (const booking of bookings) {
      const startTime = moment(booking.start_time);
      const endTime = moment(booking.end_time);

      // Check if the booking spans the specified day
      if (
        startTime.isSameOrBefore(day, "day") &&
        endTime.isSameOrAfter(day, "day")
      ) {
        // If yes, add it to the result array
        bookingsForDay.push(booking);
      }
    }

    return bookingsForDay;
  };

  const areDatesInSameMonth = (date1: Date, date2: Date) => {
    // Extract year and month components from both dates
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();

    // Compare the year and month components
    return year1 === year2 && month1 === month2;
  };

  const areBookingsLessThan12Hours = (day: Date) => {
    // const startOfDay = moment(day).startOf("day");
    // const endOfDay = moment(day).endOf("day");
    // console.log(moment());
    // console.log(day)
    let totalTime = 0;
    const bookingsInDay = findBookingsForDay(moment(day));
    for (const booking of bookingsInDay) {
      totalTime += moment(booking.end_time).diff(booking.start_time);
    }
    const test = totalTime < 12 * 60 * 60 * 1000;

    return test;
  };

  const generateWorkingHours = (
    startTime: Moment,
    endTime: Moment,
    hours: number,
    day: Moment,
    bBuffer: number = 0,
    aBuffer: number = 0
  ) => {
    const availableBlocks: TimeBlock[] = [];
    const bookingsForDay = findBookingsForDay(day);
    // Clone the start time to avoid modifying the original moment object
    let currentTime = startTime.clone();
  
    // Loop until the current time is less than the end time
    while (currentTime.isBefore(endTime)) {
      // Calculate the end time for the current block
      const blockEndTime = currentTime.clone().add(hours, "hours");
      if (blockEndTime.isAfter(endTime)) {
        break;
      }
      let overlapFound = false;
  
      for (const booking of bookingsForDay) {
        const bookingStartTime = moment(booking.start_time);
        const bookingEndTime = moment(booking.end_time);
  
        // If the block overlaps with the booking, set the flag and break
        if (
          currentTime.isSameOrBefore(bookingEndTime) &&
          blockEndTime.isSameOrAfter(bookingStartTime)
        ) {
          overlapFound = true;
          break;
        }
      }
      if (!overlapFound) {
        availableBlocks.push({
          start: currentTime.clone(),
          end: blockEndTime.clone(),
        });
      }
      // Move the current time to the next block, considering buffer time
      currentTime.add(0.5, "hours");
    }
  
    setBlocks(availableBlocks);
  };
  const dayAvailability = (day: Date) => {
    const startOfDay = moment(day).startOf("day");
    // const endOfDay = moment(day).endOf("day");
    // const startTime = startOfDay.toDate();
    // const endTime = endOfDay.toDate();
    const dayOfWeek = startOfDay.format("ddd").toLowerCase();

    const kitchenOptions = currentKitchen?.kitchen_options;
    const kitchenOptionsKeys = Object.keys(kitchenOptions? kitchenOptions: {});
    const dayAvailability = kitchenOptionsKeys.includes(dayOfWeek);
    if (dayAvailability) {
      return dayAvailability;
    }
  };
  const handleCalendarClick = (day: Date) => {
    if (
      areDatesInSameMonth(day, currentDate) &&
      areBookingsLessThan12Hours(day) &&
      nHours !== 0
    ) {
      const dayOfWeek = moment(day).format("ddd").toLowerCase();
      var kitchenOptions = currentKitchen?.kitchen_options;
      var kitchenOptionsKeys = Object.keys(kitchenOptions? kitchenOptions: {});
      var kitchenOptionsValues = Object.values(kitchenOptions? kitchenOptions: {});
      var weekDayHours = kitchenOptionsValues[kitchenOptionsKeys.indexOf(dayOfWeek)];
      var openTimeHour = weekDayHours.open_time.split(":")[0];
      var closeTimeHour = weekDayHours.close_time.split(":")[0];
      // console.log(openTimeHour);
      // console.log(closeTimeHour);

      if (weekDayHours.close_time?.includes("PM")) {
        closeTimeHour = parseInt(closeTimeHour) + 12;
      }
      if(weekDayHours.open_time?.includes("PM")){
        openTimeHour = parseInt(openTimeHour) + 12;
      }
      const openTimeMinutes = weekDayHours.open_time?.split(":")[1];
      const closeTimeMinutes = weekDayHours.close_time?.split(":")[1];
      console.log(
        moment(selectedDay)
          .clone()
          .set("hour", closeTimeHour)
          .set("minute", closeTimeMinutes)
      );
      console.log(
        moment(selectedDay)
          .clone()
          .set("hour", openTimeHour)
          .set("minute", openTimeMinutes)
      );
      console.log();
      setShowTimes(true);
      setSelectedDay(day);
      generateWorkingHours(
        moment(day)
          .clone()
          .set("hour", openTimeHour)
          .set("minute", openTimeMinutes),
        moment(day)
          .clone()
          .set("hour", closeTimeHour)
          .set("minute", closeTimeMinutes),
        nHours,
        moment(day)
      );
      // areBookingsLessThan12Hours(day)
      // console.log(availableTimeBlocks);
    }
  };

  const handleTimeClick = (block: TimeBlock) => {
    setSelectedBlock(block);
    const startTime = moment(selectedDay)
      .clone()
      .hours(block.start.hours())
      .minutes(block.start.minutes());
    const endTime = moment(selectedDay)
      .clone()
      .hours(block.end.hours())
      .minutes(block.end.minutes());
    // console.log(endTime.format("HH:mm"));
    // console.log(startTime.format("HH:mm"));
    updateStartTime(startTime);
    updateEndTime(endTime);
    // console.log(moment(selectedDay));
    // console.log(block);
    // console.log(startTime);
    // console.log(endTime);
  };
  const isWithinDays = (frequency: "daily" |  "weekly" | "monthly" | "annual", date: Date, selectedDate: Date): boolean => {
    return moment(date).isSameOrAfter(moment(selectedDate)) && frequency ==='daily' && moment(date).isSameOrBefore(moment(endRecurrence));
  };

  
  const isWithinAWeek = (frequency: "daily" | "weekly" | "monthly" | "annual", date: Date, selectedDate: Date): boolean => {
    const differenceInDays = moment(date).diff(moment(selectedDate), 'days');
    // return [7, 14, 21, 28].includes(differenceInDays);
    return moment(date).isSameOrAfter(moment(selectedDate)) && differenceInDays % 7 === 0 && frequency ==='weekly' && moment(date).isSameOrBefore(moment(endRecurrence));
  };

  const isWithinAMonth = (frequency: "daily" |  "weekly" | "monthly" | "annual", date: Date, selectedDate: Date): boolean => {
    const differenceInDays = moment(date).diff(moment(selectedDate), 'days');
    return moment(date).isSameOrAfter(moment(selectedDate)) && differenceInDays % 30 === 0 && frequency ==='monthly' && moment(date).isSameOrBefore(moment(endRecurrence));
  };
  

  return (
    <div>
      <div className="grid grid-cols-7 gap-2 grid-flow-dense md:ml-20">
        {daysInMonth.slice(0, 7).map((day) => (
          <div
            key={day.toISOString()}
            className=" text-slate-600  p-2 relative col-span-1 text-left md:px-5 "
          >
            <div>{format(day, "E")}</div>
          </div>
        ))}
        {/* ${
                  areDatesInSameMonth(day, currentDate)
                    ? ` ${
                        moment(day).isSame(moment(selectedDay))
                          ? "bg-primary-400 hover:cursor-pointer"
                          : "bg-white hover:cursor-pointer"
                      } ${
                        (!areBookingsLessThan12Hours(day) || (moment(currentDate).day() > moment(day).day() && moment(currentDate).month() > moment(day).month() ) )
                          ? " !bg-transparent "
                          : ""
                      } `
                    : " text-transparent"
                }  */}
        {daysInMonth.map((day) => {
          return (
            <div key={day.toISOString()} className={`p-2 relative col-span-1 `}>
              <div
                className={`${
                  !areBookingsLessThan12Hours(day) || (moment().isSameOrAfter(moment(day)))|| !dayAvailability(day) ? " !bg-transparent hover:cursor-default disabled": ""
                } ${(
                (moment(day).isSame(moment(selectedDay))) || 
                (frequency && (
                    isWithinDays(frequency, day, selectedDay) ||
                    isWithinAWeek(frequency, day, selectedDay) ||
                    isWithinAMonth(frequency, day, selectedDay)
                  )
                  
                )
                ) ? "bg-primary-400 hover:cursor-pointer"
                    : "bg-white hover:cursor-pointer "
                }
                ${
                  areDatesInSameMonth(day, currentDate)
                    ? ""
                    : "!text-transparent !bg-transparent"
                }
                justify-center  md:m-2 text-center  md:w-1/4 rounded-full md:py-2 `}
                onClick={() =>  !areBookingsLessThan12Hours(day) || (moment().isSameOrAfter(moment(day)))|| !dayAvailability(day) ?  null: handleCalendarClick(day)}
              >
                {format(day, "dd")}
              </div>
            </div>
          );
        })}
      </div>

      {showTimes ? (
        <div
          className={`grid ${
            availableTimeBlocks && availableTimeBlocks.length < 3
              ? `sm:grid-cols-${availableTimeBlocks.length} grid-cols-1`
              : "md:grid-cols-5 grid-cols-1"
          } space-y-1 space-x-3 grid-flow-row-dense items-center `}
        >
          {availableTimeBlocks.map((block, index) => (
            <div
              key={index}
              className={`${
                block === selectedBlock ? "bg-primary-400" : ""
              } border-primary-400 border-2 p-2 rounded-lg hover:cursor-pointer hover:bg-primary-400 `}
              onClick={() => handleTimeClick(block)}
            >
              {block.start.format("LT")} - {block.end.format("LT")}
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReservationCalendar;
