import {useEffect} from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom';

import phoneIcon from '../../icons/phoneIcon.svg'
import emailIcon from '../../icons/emailIcon.svg'
import locationIcon from '../../icons/locationIcon.svg'

import { ReactComponent as DateIcon } from '../../icons/dateIcon.svg'
import { ReactComponent as CommunityIcon } from '../../icons/Community.svg'

import LineGraph from '../../components/LineGraph'

import { useAuthState } from '../../contexts/authcontext'
import { useMemberState } from '../../contexts/membercontext'

import { BookingSchema } from "../../data/types";


const timeSeries = ["07/2023", "08/2023", "09/2023", "10/2023"]

const chartData = {
  labels: timeSeries,
  datasets: [
    {
      label: "Grapes",
      data: [10, 15, 12, 8],
      borderColor: "#F34F26",
      fill: false,
    },
    {
      label: "Broccoli",
      data: [8, 12, 10, 15],
      borderColor: "#9CCE5D",
      fill: false,
    },
    {
      label: "Cucumbers",
      data: [5, 8, 6, 9],
      borderColor: "#fff",
      fill: false,
    },
    {
      label: "Beef",
      data: [4, 3, 8, 7],
      borderColor: "#F34F26",
      fill: false,
    },
  ],
};


const Account = () => {
    var { authUser, primaryCommunity } = useAuthState()
    var { bookings, 
        // getBookingByID 
    } = useMemberState()
    // const [currentMenu, changeMenu] = useState('profile')
    bookings = (bookings && bookings.length > 0) ? bookings : []
    bookings = bookings.length > 3 ? bookings.slice(0, 3) : bookings


    // var account_avatar = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`

    var navigate = useNavigate();

    const formatBookingDate = (startDate: any, endDate: any) => {
        startDate = moment(startDate)
        endDate = moment(endDate)

        if (startDate.day() === endDate.day()){
            return `${startDate.format("ddd MMM Do, YYYY")} (${startDate.format("h:mm a")} - ${endDate.format("h:mm a")})`
        } else if (startDate.month() === endDate.month()){
            return `${startDate.format("ddd MMM Do h:mm a")} - ${endDate.format("Do h:mm a, YYYY")}`
        } else if (startDate.year() === endDate.year()){
            return `${startDate.format("ddd MMM Do h:mm a")} - ${endDate.format("MMM Do h:mm a (YYYY)")}`
        }
    }

    const userCommunities = [
        {name: "Morgan", icon: CommunityIcon},
        {name: "PGCC", icon: CommunityIcon},
        {name: "MCC", icon: CommunityIcon},
        {name: "dssf", icon: CommunityIcon},
        {name: "scsc", icon: CommunityIcon},
        {name: "scs", icon: CommunityIcon},
    ]

    useEffect(() => {
        document.title = `HomeCook | Account`
      }, [])

    return (
        <div className='flex flex-col font-open-sans bg-[#D9D9D9] h-[900px] sm:h-screen p-4 overflow-y-scroll'>
            
            <div className='h-full flex flex-col items-center text-[#013847]/80'> Account
                <div className='flex flex-col md:flex-row mx-auto bg-white rounded-md h-auto w-full p-2 shadow-sm'>
                    <div className='flex flex-col justify-center items-center m-2'> <img src={primaryCommunity?.logo} alt="account_avatar" className='h-[150px] w-[150px] md:h-[200px] md:w-[200px] rounded-full m-2' /></div>
                    <div className='flex flex-col justify-center p-4 w-full'>
                            <p className='text-[#013847] font-bold text-[20px] m-1'>{authUser?.first_name} {authUser?.last_name}</p>
                            <p className='text-[#013847] font-normal text-[16px] m-1'>{primaryCommunity?.name} (Operator)</p>
                            <div className='flex flex-row text-[#013847] font-light text-[14px] m-1'><img src={phoneIcon} alt="phoneIcon"  className='mr-2 h-[20px]' />  (202) 555-5555 </div>
                            <div className='flex flex-row text-[#013847] font-thin text-[14px] m-1'><img src={emailIcon} alt="emailIcon"  className='mr-2 h-[20px]' />{authUser?.email}</div>
                            <div className='flex flex-row text-[#013847] font-thin text-[14px] m-1'><img src={locationIcon} alt="emailIcon"  className='mr-2 h-[20px]' />{primaryCommunity?.address}</div>
                            <span className='flex flex-row justify-end w-full text-[12px] text-slate-300 dark:text-slate-500 m-1'><span className='font-bold text-slate-400'>Joined</span>&nbsp;{moment(authUser?.created).fromNow()}</span>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row justify-evenly w-full mx-auto mt-4 text-[#013847]'>
                    <div className='flex flex-col justify-start items-center bg-white rounded-md h-auto w-auto sm:w-4/12 m-1 p-4 shadow-sm font-bold text-[18px]'>
                        ANALYTICS
                        
                        <div className='flex flex-col items-start my-5 mx-auto text-green-800'>TOTAL RESERVATIONS THIS MONTH</div>
                        <LineGraph data={chartData} />
                        <div className='flex flex-col items-start my-5 mx-auto text-green-800'>AVG TOTAL ORDERS</div>

                    </div>
                    <div className='flex flex-col justify-start items-center bg-white  rounded-md h-[600px] w-auto sm:w-4/12 m-1 p-4 shadow-sm font-bold text-[18px]'>
                        RESERVATIONS
                        <div className='flex flex-col items-start my-5 mx-auto w-[80%]'>
                            {
                                bookings?.map((reservation: BookingSchema, i: number) => {
                                    return (
                                        <div key={i} className='flex flex-row my-5 justify-start w-full hover:bg-[#eef7fa]/80 hover:text-[#013847]/70 cursor-pointer hover:scale-95 duration-1000 rounded-md p-1' onClick={async () => {
                                            if (reservation){
                                                var reservationId = reservation?.id ? reservation?.id : ""
                                                if (reservation && reservationId && reservation?.community_id){
                                                //   getBookingByID(reservation.community_id, reservationId)
                                                  navigate(`/community/${reservation?.community_id}/bookings/${reservationId}`)
                                                }
                                              }
                                        }}>
                                            {/* <img src={credentialIcon} className='my-auto' / > */}
                                            <DateIcon />
                                            <div className='flex flex-col mx-4 my-auto'>
                                                <p className='font-normal text-[16px]'>{reservation.summary}</p>
                                                <p className='font-light text-[14px]'>{reservation.location}</p>
                                                {/* <p className='font-light text-[14px]'>{moment(new Date(reservation?.start_time)).format("ddd MMM Do, h:mm a")} - {moment(new Date(reservation?.end_time)).format(`${checkSameMonth(reservation?.start_time, reservation?.end_time) ? '' : 'MMM, Doz,'}  h:mm a`)}</p> */}
                                                <p className='font-light text-[14px]'>{formatBookingDate(reservation?.start_time, reservation?.end_time)}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className='flex flex-row justify-between w-full items-end h-full'>
                                <div className='font-light text-[16px] text-[#5DB6CE] cursor-pointer hover:text-[#013847]'></div>
                                <div className='font-light text-[16px] text-[#5DB6CE] cursor-pointer hover:text-[#013847]' onClick={() => navigate('/kitchens/reservations')}>All Reservations</div>
                        </div>
                    </div>
                    <div className='flex flex-col justify-start items-center bg-white rounded-md h-[600px] w-auto sm:w-4/12 m-1 p-4 shadow-sm font-bold text-[18px]'>
                        MEMBERS
                        <div className='grid grid-cols-3 grid-flow-row-dense p-4'>
                            {
                                userCommunities?.map((community, i) => {
                                    return (
                                        <div key={i} className='flex rounded-full bg-[#eee] m-4 h-[80px] w-[80px] hover:scale-95 duration-1000 hover:bg-[#eef7fa] cursor-pointer'>
                                                <div className='flex-item m-auto text-[#5DB6CE]/50' />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                
            </div>

            
             
         
        </div>
      )
}



export default Account