// import { SVGProps } from 'react'
import { ReactComponent as TrendLineIcon } from '../icons/TrendLine.svg'

// import { ReactComponent as BaconIcon } from '../../icons/foodIcons/Bacon.svg'
// import { ReactComponent as BeefIcon } from '../icons/foodIcons/Beef.svg'
// import { ReactComponent as BroccoliIcon } from '../icons/foodIcons/Broccoli.svg'
// import { ReactComponent as CucumberIcon } from '../icons/foodIcons/Cucumber.svg'
// import { ReactComponent as GrapesIcon } from '../icons/foodIcons/Grapes.svg'

// const chartLegends: {name: string, color: string, Icon: React.FC<SVGProps<SVGSVGElement>>, currentPrice: string}[] = [
//   {name: 'Grapes', color: '#F34F26', Icon: GrapesIcon, currentPrice: '2.12'},
//   {name: 'Broccoli', color: '#9CCE5D', Icon: BroccoliIcon, currentPrice: '2.12'},
//   {name: 'Cucumber', color: '#D9D9D9', Icon: CucumberIcon, currentPrice: '2.12'},
//   {name: 'Beef', color: '#F34F26', Icon: BeefIcon, currentPrice: '2.12'}
// ]

// import BeefIcon  from '../icons/foodIcons/Beef.svg'
// import BroccoliIcon from '../icons/foodIcons/Broccoli.svg'
// import CucumberIcon from '../icons/foodIcons/Cucumber.svg'
// import GrapesIcon from '../icons/foodIcons/Grapes.svg'



type ChartLegendProps = {
  chartLegends: {name: string, color: string, Icon: string, currentPrice: string}[]
}



const ChartLegend:React.FC<ChartLegendProps>  = ({chartLegends})  => {
  return (
    <div className='flex flex-col text-white justify-evenly items-center p-5'>
        {
          chartLegends.map((legend, i) => (<div className='flex w-full justify-between text-[#5DB6CE] cursor-pointer hover:scale-95 duration-1000' key={i}> <img src={legend.Icon} alt={legend.name} /> <TrendLineIcon style={{color: legend.color}} /> &nbsp; ${legend.currentPrice}</div>))
        }
    </div>
  )
}


export default ChartLegend