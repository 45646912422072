import React, { useState, useEffect, useRef} from "react";
import { usePreferences } from "../contexts/preferencecontext";
import { useNavigate } from "react-router-dom";
import { updatePreferences } from "../api/crud";
import ProgressBar from "./ProgressBar";
import { useParams } from "react-router-dom";


const Wizard = ({ steps }: any) => {
  const { category } = useParams();
  var navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [collectedData, setCollectedData] = useState({});
  const { preferences, isClicked, setClicked } = usePreferences();
  var categoryLabels = {
    "Experience":0,
    "Location":2,
    "Kitchen":4,
    "Booking":6,
    "Miscellaneous":8
  }

// console.log(category)
const initialStepSet = useRef(false);

  useEffect(() => {
    if (category && !initialStepSet.current) {
      setCurrentStep(categoryLabels[category as keyof typeof categoryLabels]);
      initialStepSet.current = true;
    }
    // eslint-disable-next-line 
  }, [category]);

  const handleNext = async (data: any) => {
    setClicked(false);
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      // // Merge collected data with existing preferences
      const updatedPreferences = { ...preferences, ...collectedData };
      // console.log(updatedPreferences);
      await updatePreferences({
        communityId: "64c6ca60aa76dc3a3326b8f9",
        data: updatedPreferences,
      });
      navigate("/kitchens/preferences");
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const handleStepChange = (data: any) => {
    // console.log(data);
    setCollectedData((prevData) => ({ ...prevData, ...data }));
    // console.log(isClicked);
  };

  const renderStep = () => {
    const StepComponent = steps[currentStep].component;
    return <StepComponent onChange={handleStepChange} step={currentStep} />;
  };

  return (
    <div>
      <ProgressBar
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      ></ProgressBar>
      <div>{renderStep()}</div>
      <div className="grid justify-end grid-flow-col">
        {currentStep !== 0 ? (
          <button
            onClick={handlePrevious}
            disabled={currentStep === 0}
            className="rounded-lg text-black text-xl m-3 p-2 px-5 bg-back-300 hover:bg-back-200"
          >
            Previous
          </button>
        ) : null}

        <button
          disabled={isClicked ? false : true}
          onClick={handleNext}
          className={`buttonsPrimary ${
            isClicked ? "" : "bg-[#51a0b5]"
          } text-black text-xl m-3 p-2 px-5`}
        >
          {currentStep === steps.length - 1 ? "Submit" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default Wizard;
