import { useQuery, useMutation} from '@tanstack/react-query'
import { 
    getAllKitchens, getKitchenID, 
    addKitchen, updateKitchen, updateMembershipPlans, getMembershipPlan, 
    updateKitchenOptions, getCommunityAllInventory, addCommunityInventory,
    deleteMembershipRequirement, addMembershipRequirement, 
    addMembershipPlans, getMembershipPlans, getMembershipRequirements, } from '../api/crud'
import { InventorySchema, KitchenOptionsType, KitchenSpaceSchema, PlanSchema, RequirementSchema, FilterOptionSchema } from "../data/types";
import { useKitchenState } from '../contexts/kitchencontext'
import { filterItemsByCategoryAndName } from '../utils/helpers';
import inventoryCatalog from '../data/inventoryCatalog.json';

const staleTime = 1000
const isEnabled = true

type KitchenRespType = {
    primaryKitchen: KitchenSpaceSchema
    kitchens: KitchenSpaceSchema[]
}


export const useLoadCommunityKitchen = () => {
    var { communityId, searchQuery, nameSort, idSort, setKitchen, updateKitchens } = useKitchenState()
    communityId = communityId ? communityId : "0"
    const {data: kitchenResponse, refetch: reloadKitchens,  isLoading: isLoadingKitchens} = useQuery({ 
        queryKey: ['kitchens', communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching kitchens")
                var res = await getAllKitchens(communityId)
                let primaryKitchen = (res && res.length > 0) ? res[0] : null
                if (primaryKitchen && updateKitchens !== undefined && setKitchen!== undefined){
                    if (searchQuery) {
                        res = res.filter((kitchen: KitchenSpaceSchema) => {
                            // filter the kitchens based on the provided id in the search.
                            return kitchen.id === searchQuery;
                        })
                    }
                    if (nameSort) {
                        res = res.sort((first: KitchenSpaceSchema, second: KitchenSpaceSchema) => {
                            if (first.name && second.name && first.name < second.name) return -1;
                            if (first.name && second.name && first.name > second.name) return 1;
                            return 0;
                        });
                    }
                    if (idSort) {
                        res = res.sort((first: KitchenSpaceSchema, second: KitchenSpaceSchema) => {
                            if (first.id && second.id && first.id < second.id) return -1;
                            if (first.id && second.id && first.id > second.id) return 1;
                            return 0;
                        });
                    }
                    updateKitchens(res ? res : []);
                    setKitchen(primaryKitchen)
                }   

    
                const kitchenResponse: KitchenRespType = {
                    primaryKitchen,
                    kitchens: res
                }
                return kitchenResponse
            }
        },
        staleTime,
        enabled: isEnabled
    })

    useLoadPlans()
    useLoadRequirements()
   
    if (kitchenResponse){
        let {primaryKitchen, kitchens} = kitchenResponse
        return {primaryKitchen, kitchens, reloadKitchens}
    }
    return { isLoadingKitchens, primaryKitchen: null, kitchens: [], reloadKitchens}
}



export const useLoadCurrentKitchen = (kitchenId: string) => {
    let { communityId, setKitchen } = useKitchenState()
    const {data: currentKitchen, refetch: reloadCurrentKitchen, isLoading: isLoadingKitchen} = useQuery({ 
        queryKey: ['kitchen', communityId, kitchenId], 
        queryFn: async () => {
            console.log("fetching kitchen by id")
            var res = await getKitchenID({communityId: communityId ? communityId : "", id: kitchenId})
            if (res && setKitchen!== undefined){
                setKitchen(res)
            }
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    var { isLoadingPlans } = useLoadPlans()
    var { isLoadingRequirements } = useLoadRequirements()
    var isLoadingCurrentKitchen = isLoadingKitchen || isLoadingPlans || isLoadingRequirements
    return { isLoadingCurrentKitchen, currentKitchen, reloadCurrentKitchen}
}



export const useLoadPlans = () => {
    let { communityId, setPlans } = useKitchenState()
    const {data: plans, refetch: reloadPlans, isLoading: isLoadingPlans} = useQuery({ 
        queryKey: ["plans", communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching kitchen plans")
                var res = await getMembershipPlans(communityId)
                if (res && setPlans!== undefined){
                    setPlans(res)
                }
                return res
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingPlans, plans, reloadPlans}
}


export const useAddInventory = () => {
    var { communityId } = useKitchenState()
    const reloadInventoryList  = useLoadInventoryList().reloadInventoryList
    const mutation = useMutation({ 
        mutationFn: async (inventory: InventorySchema) => {
            console.log("add community inventory")
            if (communityId){
                const res = await addCommunityInventory(communityId, inventory)
                reloadInventoryList()
                return res
            }
            return null
    }})

    const addInventory = (inventory: InventorySchema) => {
        mutation.mutate(inventory)
        return mutation?.data ? true : false
    }

    const searchInventoryCatalog = (category: string, query: string): string[] => {
        const inventoryItems: FilterOptionSchema[] = inventoryCatalog as FilterOptionSchema[];
        return filterItemsByCategoryAndName(inventoryItems, category, query)
    }

    const isLoadingInventory = mutation.isPending
    return { addInventory, searchInventoryCatalog, isLoadingInventory }
}


export const useLoadInventoryList = () => {
    let { communityId, setInventoryList } = useKitchenState()
    const {data: inventoryList, refetch: reloadInventoryList, isLoading: isLoadingInventoryList} = useQuery({ 
        queryKey: ["inventory", communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching community inventory")
                var res = await getCommunityAllInventory(communityId)
                if (res && setInventoryList!== undefined){
                    setInventoryList(res)
                }
                return res
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingInventoryList, inventoryList, reloadInventoryList}
}


export const useLoadRequirements = () => {
    let { communityId, setRequirements } = useKitchenState()
    const {data: requirements, refetch: reloadRequirements, isLoading: isLoadingRequirements} = useQuery({ 
        queryKey: ['requirements', communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching kitchen requirements")
                var res = await getMembershipRequirements(communityId)
                if (res && setRequirements!== undefined){
                    setRequirements(res)
                }
                return res                
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingRequirements, requirements, reloadRequirements}
}


export const useKitchenHook = () => {
    var { communityId, plans, setKitchen, setPlans } = useKitchenState()
    const { reloadPlans } = useLoadPlans()
    const { reloadRequirements } = useLoadRequirements()
    const { reloadKitchens } = useLoadCommunityKitchen()
    
    // add kitchen space
    const addKitchenMutation = useMutation({ 
        mutationFn: (kitchen: KitchenSpaceSchema | {}) => {
            console.log("add kitchen")
            const res = addKitchen(communityId ? communityId : "", kitchen)
            reloadKitchens()
            return res
    }})
    
    const addKitchenSpace = (kitchen: KitchenSpaceSchema | {}) => {
        addKitchenMutation.mutate(kitchen)
        return addKitchenMutation?.data ? true : false
    }

    // update kitchen space
    const updateKitchenMutation = useMutation({ 
        mutationFn: async ({spaceId, kitchenUpdates}: {spaceId: string, kitchenUpdates: KitchenSpaceSchema}) => {
            console.log("update kitchen space")
            const res = await updateKitchen({communityId: communityId ? communityId : "", spaceId, kitchenUpdates})
            if (res && setKitchen !== undefined){
                setKitchen(res)
            }
            return res
    }})
    const updateKitchenSpace = (spaceId: string, kitchenUpdates: KitchenSpaceSchema) => {
        updateKitchenMutation.mutate({spaceId, kitchenUpdates})
        reloadKitchens()
        return updateKitchenMutation?.data ? true : false
    }

    // add kitchen plan
    const addPlanMutation = useMutation({ 
        mutationFn: async (plan: PlanSchema | {}) => {
            console.log("add plan")
            const res = await addMembershipPlans(communityId ? communityId : "", plan)
            if (res && setPlans !== undefined){
                let updated_plans: any = (plan && plans) ? [...plans] : []
                updated_plans.push(res)
                setPlans(updated_plans)
            }
            return res
    }})
    const addKitchenPlan = (plan: PlanSchema | {}) => {
        addPlanMutation.mutate(plan)
        return addPlanMutation?.data ? true : false
    }

    // update kitchen plan
    const updatePlanMutation = useMutation({ 
        mutationFn: async ({planId, planUpdate}: {planId: string, planUpdate: PlanSchema}) => {
            console.log("update plan")
            const res = await updateMembershipPlans(communityId ? communityId : "", planUpdate, planId)
            reloadPlans()
            return res
    }})

    const updateKitchenPlan = (planId: string, planUpdate: PlanSchema | {}) => {
        updatePlanMutation.mutate({planId, planUpdate})
        return updatePlanMutation?.data ? true : false
    }

    // add requirement
    const addRequirementMutation = useMutation({ 
        mutationFn: async (requirement: RequirementSchema | {}) => {
            console.log("add requirement")
            const res = await addMembershipRequirement(communityId ? communityId : "", requirement)
            if (res){
                // let updated_reqs: RequirementSchema[] = (requirement && requirements) ? [...requirements] : []
                // updated_reqs.push(res)
                // setRequirements(updated_reqs)
                reloadRequirements()
            }
            return res
    }})
    const addKitchenRequirement = (requirement: RequirementSchema | {}) => {
        addRequirementMutation.mutate(requirement)
        return addRequirementMutation?.data ? true : false
    }

    // delete requirement
    const deleteRequirementMutation = useMutation({ 
        mutationFn: async (req_id: string) => {
            console.log("delete requirement")
            const res = await deleteMembershipRequirement(communityId ? communityId : "", req_id)
            reloadRequirements()
            return res
    }})
    const deleteKitchenRequirement = (req_id: string) => {
        deleteRequirementMutation.mutate(req_id)
        return deleteRequirementMutation?.data ? true : false
    }

    // update kitchen options
    const updateKitchenOptionsMutation = useMutation({ 
        mutationFn: async ({spaceId, optionsUpdates}: {spaceId: string, optionsUpdates: KitchenOptionsType}) => {
            console.log("update kitchen space")
            const res = await updateKitchenOptions(communityId ? communityId : "", spaceId, optionsUpdates)
            if (res && setKitchen !== undefined){
                setKitchen(res)
            }
            return res
    }})

    const updateOptions = (spaceId: string, optionsUpdates: KitchenOptionsType) => {
        updateKitchenOptionsMutation.mutate({spaceId, optionsUpdates})
        return updateKitchenMutation?.data ? true : false
    }

    const isLoading = (
        addKitchenMutation.isPending ||
        updateKitchenMutation.isPending ||
        addPlanMutation.isPending || 
        updatePlanMutation.isPending || 
        addRequirementMutation.isPending || 
        deleteRequirementMutation.isPending ||
        updateKitchenOptionsMutation.isPending
        // || isLoadingCommunity
    );

    return { isLoading, addKitchenSpace, addKitchenPlan, addKitchenRequirement, updateKitchenSpace, updateOptions, updateKitchenPlan, deleteKitchenRequirement }
}

export const useGetKitchenPlan = (planId: string) => {
    let { communityId } = useKitchenState()
    const {data: plans, refetch: reloadPlans, isLoading: isLoadingPlan} = useQuery({ 
        queryKey: ["plan", communityId, planId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching plan by id")
                var res = await getMembershipPlan(communityId, planId)
                return res
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return {isLoadingPlan, plans, reloadPlans}
}