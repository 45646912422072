import React, { useState } from 'react'
import { EquipmentsWidgetProps } from "../data/types";




var kitchenEquipments = [
  {id: "0", name: 'Range', price: 10.99},
  // {id: "1", name: 'Commercial Oven', price: 21.99},
  {id: "2", name: 'Fryer', price: 23.29},
  {id: "3", name: 'Food Slicer', price: 35.99},
  {id: "4", name: 'Retail', price: 23.99},
  {id: "5", name: 'Prep Tables', price: 25.99},
  // {id: "6", name: 'Convection Oven', price: 23.99},
  {id: "7", name: 'Deck Oven', price: 19.99},
  {id: "8", name: 'Mixer', price: 23.99},
  // {id: "9", name: 'Food Dehydrator', price: 23.89},
  {id: "10", name: 'Rack Oven', price: 9.99},
  {id: "11", name: 'Commercial Grinder', price: 23.99},
  {id: "12", name: 'Industrial Coffee Maker', price: 23.99},
  {id: "13", name: 'Induction Cooktop', price: 23.99},
  {id: "14", name: 'Steam Kettle', price: 4.59},
  {id: "15", name: 'Tilt Skillet', price: 23.99},
  {id: "16", name: 'Vertical Cutter/Mix', price: 23.99},
  {id: "17", name: 'Food Processor', price: 7.99},
  {id: "18", name: 'Food Mixer', price: 43.65},
  {id: "19", name: 'Charbroiler', price: 3.99},
  {id: "20", name: 'Ice cream maker', price: 23.99},
  {id: "21", name: 'Bottling Line', price: 12.99},
  {id: "22", name: 'Blast Freezer', price: 22.25},
  {id: "23", name: 'Canning line', price: 23.00},
  {id: "24", name: 'Water Chiller', price: 15.99},
  {id: "25", name: 'Proofer', price: 23.99},
  {id: "26", name: 'Vacuum Sealer', price: 4.79},
  {id: "27", name: 'Dough Sheeter', price: 15.00},
  {id: "28", name: 'Steam Kettle', price: 23.99},
  {id: "29", name: 'Packaging Heat Seal', price: 6.29}
]





const EquipmentsWidget: React.FC<EquipmentsWidgetProps> = props => {
  // useState<string[]>([])
  var {selectedEquipments, updateSelectedEquipments } =  props
  const [seeMore, updateSeeMore] = useState(false)
  const [all_equipments, updateAllEquipments] = useState((!seeMore && kitchenEquipments?.length > 8) ? kitchenEquipments.slice(0,8) : kitchenEquipments)

  const onSelectEquipment = (equipment: string) => {
    let all_eqps = selectedEquipments ? [...selectedEquipments] : []

    if (selectedEquipments && !(selectedEquipments.includes(equipment))) {
        all_eqps.push(equipment)
        updateSelectedEquipments(all_eqps)
    } else {
        all_eqps = all_eqps.filter(item => item !== equipment)
        updateSelectedEquipments(all_eqps)
    }
  }

  const handleSeeMore = () => {
    let updateVal = seeMore ? false : true
    updateSeeMore(updateVal)
    if (!updateVal && kitchenEquipments?.length > 8) {
       updateAllEquipments(kitchenEquipments.slice(0,8))
    } else {
      updateAllEquipments(kitchenEquipments)
    }
  }

  return (
        <>
          <div className='grid grid-cols-4 grid-flow-dense w-full space-x-2'>
              {
                  (all_equipments && all_equipments.length > 0) ? (
                    all_equipments.map((equipment, id) => {

                          let has_equipment = selectedEquipments?.includes(equipment?.id.toString())

                          return (
                              <div key={id} onClick={() => onSelectEquipment(equipment?.id.toString())} className={`flex ${!has_equipment ? ' bg-back-300 text-black ' : 'bg-[#53a3b9] text-[#CCC]'} text-[11px] rounded-md cursor-pointer m-1 p-1 items-center justify-evenly w-auto text-base`}> {equipment.name} </div>
                          )
                      })
                  ) : null
              }
          </div>

          <p className='text-[#5DB6CE] cursor-pointer m-2 text-[12px]' onClick={() => handleSeeMore()}>
              See {seeMore ? 'Less' : 'More'}
          </p>
        </>
  )
}

export default EquipmentsWidget