import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { ReactComponent as LocationIcon } from "../icons/locationIcon.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { ReactComponent as VisaCardIcon } from '../icons/visaCardIcon.svg'
// import { ReactComponent as DiscoverCardIcon } from '../../icons/discoverCardIcon.svg'
import { ReactComponent as MasterCardIcon } from '../icons/masterCardIcon.svg'
import { ReactComponent as AmexCardIcon } from '../icons/amexCardIcon.svg'

// import { useMemberState } from "../contexts/membercontext";
import {useLoadBooking, useLoadMember, useUpdateBooking, useGetPaymentMethods} from "../hooks/memberhooks";
import { useKitchenState } from "../contexts/kitchencontext";

// import { useAuthState } from "../contexts/authcontext";
import NotFound from "../pages/shared/notfound";
import LoadingScreen from "../pages/shared/LoadingScreen";

import { getBookingStatusColor, styleThousandCommas } from "../utils/helpers";

const BookingDetail = () => {
  var navigate = useNavigate();
  var { bookingId } = useParams();
  var { currentKitchen } = useKitchenState()

  var { currentBooking, isLoadingBooking } = useLoadBooking(bookingId ? bookingId : "")
  var { updateBooking } = useUpdateBooking(bookingId ? bookingId : "")

  const { getPaymentMethod } = useGetPaymentMethods(currentBooking?.username ? currentBooking.username : "")

  const handleGetPaymentMethod = () => currentBooking ? getPaymentMethod(currentBooking) : {}

  let username = currentBooking?.username ? currentBooking.username : ""

  var { currentMember } = useLoadMember(username)


  var currentPaymethod = handleGetPaymentMethod()
 
  const approveBooking = () => {
    if (bookingId && currentBooking && updateBooking !== undefined ){
      let bookingUpdate: any = {status: "approved"}
      updateBooking(bookingUpdate)
    }
  }

  const rejectBooking = () => {
    if (bookingId && currentBooking && updateBooking !== undefined ){
      let bookingUpdate: any = {status: "canceled"}
      updateBooking(bookingUpdate)
    }
  }

  const capitalizeWords = (str: string) => {
    if (str) {
      // Split the string into an array of words
      const words = str.split(" ");
      // Capitalize the first letter of each word
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      // Join the capitalized words back into a string
      const capitalizedString = capitalizedWords.join(" ");
      return capitalizedString;
    }
  };

  const getCardIcon = (paymentMethod: string) => {
    switch (paymentMethod) {
      case 'visa':
        return <VisaCardIcon className='w-8 my-auto' />;
      case 'mastercard':
        return <MasterCardIcon className='w-8 my-auto' />;
      case 'amex':
        return <AmexCardIcon className='w-8 my-auto' />;
      // Add cases for other card types
      default:
        return <img src="/icons/unknown.svg" alt="Unknown" />;
    }
  };


  useEffect(() => {
    // loadCurrentBooking();
    document.title = `HomeCook | Reservation Details`;
    // currentPaymethod = handleGetPaymentMethod()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  var duration = currentBooking
    ? moment.duration(
        moment(currentBooking?.end_time)?.diff(
          moment(currentBooking?.start_time)
        )
      )
    : null;
  var hoursDiff = duration ? duration.asHours() : 0;

  // var hourlyCost = (currentBooking?.plan_rate && currentBooking?.plan_recurrence  === 'hourly') ? currentBooking.plan_rate * hoursDiff : currentBooking?.plan_rate;
  // hourlyCost = hourlyCost ? hourlyCost : 0

  const equipmentCost = 0

  const hourlyCost = currentBooking?.subtotal_cost ? currentBooking.subtotal_cost : 0
  const bookingFees = currentBooking?.fees ? currentBooking.fees : []
  const totalCost = currentBooking?.total_cost ? currentBooking.total_cost : 0
  

  if (isLoadingBooking){
    return (
      <div className='flex flex-col items-center font-open-sans text-inherit p-4 col-span-1 rounded-md justify-evenly'> 
        <LoadingScreen />
      </div>
    )
  } else {return (currentBooking?
    (<div className="flex w-full p-10 h-screen overflow-y-scroll bg-[#D9D9D9]">
      <div className="flex flex-col w-full relative items-start p-4">
        <ArrowBackIosIcon
          className="text-[#5DB6CE] hover:text-gray-500 cursor-pointer"
          onClick={() => navigate("/kitchens/reservations")}
        />

        <div className="font-raleway text-[25px] text-primary-300  w-full text-center m-2">
          Reservation Details
        </div>
        {/* TODO: BOOKING REQUEST TITLE */}

        <div className="grid md:grid-cols-4 sm:grid-cols-1 w-full rid-flow-row-dense">
          <div className="col-span-2 w-full">
            <div className="flex flex-col mt-4">
              <p className="font-open-sans font-bold text-[#000] text-md">
                Summary
              </p>
              <p className="font-open-sans font-bold text-gray-500 text-sm">
                {currentMember?.first_name} {currentMember?.last_name}
                  &nbsp; - &nbsp;
                {currentBooking?.summary}
              </p>
            </div>

            <div className="flex flex-col mt-4">
              <p className="font-open-sans font-bold text-[#000] text-md">
                Location
              </p>
              <p className="font-open-sans font-normal text-gray-500 text-sm">
                <p className="flex flex-row font-open-sans font-normal text-gray-500 text-sm">
                  {/* {currentBooking?.summary} &nbsp; - &nbsp;{" "}
                  {currentBooking.location} */}
                  <LocationIcon className="w-3 h-3 m-1 text-primary-300" /> &nbsp;
                  {currentKitchen?.address ? `${currentKitchen.address}, `: ``} 
                  {currentKitchen?.city ? `${currentKitchen.city}, `: ``} 
                  {/* {kitchen.county ? `${kitchen.county}, `: ``}   */}
                  {currentKitchen?.state ? `${currentKitchen.state} `: ``}  
                  {currentKitchen?.zipcode} 
                </p>
              </p>
            </div>

            <div className="flex flex-row gap-10">
               <div className="flex flex-col mt-4">
                 <p className=" font-open-sans font-semibold text-[#000] text-md">
                   Reservation Date
                 </p>
                 <p className="font-open-sans font-light text-gray-500 text-sm">
                   {currentBooking && currentBooking?.start_time
                     ? moment(new Date(currentBooking.start_time)).format(
                         "MMMM Do YYYY"
                       )
                     : ""}{" "}
                   
                 </p>
               </div>
               <div className="flex flex-col mt-4">
                 <p className=" font-open-sans font-semibold text-[#000] text-md">
                   Reservation Time
                 </p>
                 <p className="font-open-sans font-light text-gray-500 text-sm">
                   {currentBooking && currentBooking?.start_time
                     ? moment(new Date(currentBooking.start_time)).set('minutes', moment(new Date(currentBooking.start_time)).minutes()).format(
                         "h:mm a"
                       )
                     : ""}{" "}
                   &nbsp; - &nbsp;{" "}
                   {currentBooking && currentBooking?.end_time
                     ? moment(new Date(currentBooking.end_time)).set('minutes', moment(new Date(currentBooking.end_time)).minutes()).format(
                         "h:mm a"
                       )
                     : ""}
                 </p>
               </div>
             </div>

            {/* <div className='flex flex-col mt-4'>
                      <p className=' font-open-sans font-bold text-[#000] dark:text-white/60 text-[16px]'>Storage & Equipments</p>
                      <p className='font-open-sans font-light text-[#000000] dark:text-white text-[13px]'>No storage or equipments available</p>
                   </div> */}

            <div className="flex flex-col mt-4">
              <p className="font-open-sans font-bold text-[#000]  text-md">
                Additional Equipments
              </p>
              <p className="font-open-sans font-light text-gray-500 text-sm">
                No additional equipments currently
              </p>
            </div>

            <div className="flex flex-col mt-4">
              <p className="font-open-sans font-bold text-[#000]  text-md">
                Notes
              </p>
              <p className="font-open-sans font-light text-gray-500 text-sm">
                {currentBooking?.notes ? currentBooking.notes : "No notes currently"}
              </p>
            </div>

            <div className="flex flex-col mt-4">
              <p className="font-open-sans font-bold text-[#000] text-md">
                Payment
              </p>
              <p className="font-open-sans font-light text-gray-500 text-sm">
                {/* {currentBooking?.payment_method  ? currentBooking.payment_method : "No payment method available"} */}
                {
                  (currentPaymethod && currentPaymethod.card) ? (
                    <>
                      {getCardIcon(currentPaymethod.card.brand)} &nbsp;
                      <span className='text-primary-200 font-medium'>
                        {currentPaymethod.type} ending in {currentPaymethod.card.last4 || '---'} &nbsp;
                        (Exp: {currentPaymethod.card.exp_month}/{currentPaymethod.card.exp_year})
                      </span>
                    </>
                  ) : (
                    <p className="font-open-sans font-light text-gray-500 text-sm">No Existing Payment Method</p>
                  )
                }
              </p>
            </div>

            {/* <div className='flex flex-col mt-4'>
                    <button className='border-[#5DB6CE] bg-white text-[#5DB6CE] w-80'>Contact Community</button>
                   </div> */}
          </div>

          <div className="sm:col-span-1 sm:w-full hidden sm:flex"></div>

          <div className="flex flex-col items-center">
            {currentBooking?.status ? (
              <div
                style={{
                  backgroundColor: getBookingStatusColor(currentBooking?.status),
                }}
                className={`rounded-md p-2 m-1 text-[11px] ml-[50%] text-[#fff]/80`}
              >
                {capitalizeWords(currentBooking?.status)}
              </div>
            ) : null}

            <div className="flex flex-col font-open-sans text-[#D9D9D9] p-4 col-span-1 bg-[#555555] rounded-md w-8/12">
              <div className="flex flex-row w-full justify-between p-2 m-1">
                <p className=" font-normal text-[#FFF] text-xl">
                  Price Details
                </p>
              </div>

              {
                (currentBooking?.plan_rate && currentBooking?.plan_name) ? (
                    <div className="flex flex-row w-full justify-between p-2 m-1">
                      <div className="flex flex-col font-light text-[#FFF] ">
                        <span className="text-[11px] text-white/60 ">
                        <b>{currentBooking?.plan_name?.toUpperCase()} PLAN:</b> &nbsp; 
                        </span>
                      </div>
                      <p className=" font-light text-[#FFF]/80 text-[13px]">
                        ${styleThousandCommas(currentBooking.plan_rate)} ({currentBooking.plan_recurrence})
                      </p>
                    </div>
                ) : null
              }

              <div className="flex flex-row w-full justify-between p-2 m-1">
                <div className="flex flex-col font-light text-[#FFF] ">
                  <span className="text-[11px] text-white/60 ">
                  {
                    (currentMember?.member_balance) ? 
                      "Current Balance: " + currentMember.member_balance.toString() + ` hour${currentMember?.member_balance > 1 ? "s" : ""}`
                    : "Current Balance: 0 hours"
                  }
                  </span>
                  Kitchen Rental{" "}
                  <span className="text-[11px] text-white/60 ">
                    {hoursDiff
                      ? "(" +
                        hoursDiff.toString() +
                        ` hour${hoursDiff > 1 ? "s" : ""})`
                      : ""}
                  </span>
                </div>
                <p className=" font-light text-[#FFF]/80 ">
                  {/* ${styleThousandCommas(hourlyCost)} */}
                  <br/>
                  {!hourlyCost && hoursDiff ? `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""}` : `$${styleThousandCommas(hourlyCost)}`}
                </p>
              </div>

              <div className="flex flex-row w-full justify-between p-2 m-1">
                <p className=" font-light text-[#FFF] ">
                  Equipment
                </p>
                <p className=" font-light text-[#FFF]/80 ">
                  {equipmentCost}
                </p>
              </div>

              {
                (bookingFees).length > 0 && bookingFees.map((fee, i) => (
                  <div key={i} className="flex flex-row w-full justify-between p-2 m-1">
                    <p className=" font-light text-[#FFF] ">{capitalizeWords(fee.name)} Fee</p>
                    <p className=" font-light text-[#FFF]/80 ">{fee.rate}</p>
                  </div>
                ))
              }

              <div className="flex flex-row w-full justify-between p-2 m-1">
                <p className=" font-light text-[#5DB6CE] ">
                  Member Discount
                </p>
                <p className=" font-light text-[#5DB6CE]/80 ">
                  - 0
                </p>
              </div>

              <div className="flex flex-row w-full justify-between p-2 m-1 border-t-[1px] border-white/60">
                <p className=" font-light text-[#fff] ">
                  Total (USD)
                </p>
                <p className=" font-light text-[#fff]/80 ">
                  $ {styleThousandCommas(totalCost)}
                </p>
              </div>

            </div>

            {currentBooking &&
            (currentBooking.status === "canceled" ||
              currentBooking.status === "completed") ? null : (
                
              <>
              {
                currentBooking && currentBooking.status === "pending" ? (
                <>
                  <button
                  className="border-0 bg-primary-400 text-[#013847] hover:bg-[#013847] hover:text-[#ddd] m-2 w-8/12 shadow-md rounded-md p-1"
                  onClick={() => approveBooking()}
                >
                  Approve Reservation
                </button>
                <button
                  className="hover:bg-[#722121] hover:text-[#eee] bg-[#eee] text-[#722121] m-2 w-8/12 shadow-md border-[1px] border-[#01384735] rounded-md p-1"
                  onClick={() => rejectBooking()}
                >
                  Reject Reservation
                </button>
                </>
                ) : (
                <>
                <button
                  className="border-0 bg-primary-400 text-[#013847] hover:bg-[#013847] hover:text-[#ddd] m-2 w-8/12 shadow-md rounded-md p-1"
                  onClick={() => null}
                >
                  Modify Reservation
                </button>
                <button
                  className="bg-[#722121] text-[#eee] hover:bg-[#eee] hover:text-[#722121] m-2 w-8/12 shadow-md border-[1px] border-[#01384735] rounded-md p-1"
                  onClick={() => rejectBooking()}
                >
                  Cancel Reservation
                </button>
                {/* <button
                  className="bg-[#D9D9D9] text-[#013847] hover:bg-[#888] hover:text-[#eee] m-2 w-8/12 shadow-md border-[1px] border-[#01384735] rounded-md p-1"
                  onClick={() => null}
                >
                  Complete Reservation
                </button> */}
                </>
                )
              }
                
              </>
            )}
          </div>
        </div>
      </div>
    </div>):<div className="bg-back-400 min-h-screen w-screen">
      <NotFound
        dataName="Reservation"
        previousPath="/kitchens/reservations"
      />
    </div>
      
  );}
};

export default BookingDetail;
