import React, { useState } from "react";
import { states, MeasurementUnits, InventoryCategories } from "../utils/enums";
import { DSCommunitySchema, CredentialSchema, KitchenSpaceSchema, PlanSchema, InventorySchema } from "../data/types";
import { useKitchenState } from "../contexts/kitchencontext";

import StartBooking from "./StartBooking";
import CreateMembership from "./CreateMembership";
import AddMessageList from "../molecules/addMessageList";
import { ReactComponent as Avatar } from "../icons/ChatbotIcon.svg";
import { ReactComponent as InfoIcon } from '../icons/InfoIcon.svg'
import { ReactComponent as UploadIcon } from '../icons/UploadIcon.svg'
import { useMemberState } from "../contexts/membercontext";
import { useLoadMessages } from "../hooks/memberhooks";
import { useAddInventory } from "../hooks/kitchenhooks";
import { maxHours, styleThousandCommas, capitalizeString } from "../utils/helpers";


// TO BE LOADED FROM COMMUNITIES / REQUIRED DOCS
// const credentialCategories = ["ID", "license", "certificate", "insurance", "other"]
const requirementsLookUp: {
  requirementCode: string;
  requirementName: string;
}[] = [
  {
    requirementCode: "FMP",
    requirementName: "Foodservice Management Professional Certification",
  },
  { requirementCode: "MFRST", requirementName: "ManageFirst" },
  {
    requirementCode: "CLNRY",
    requirementName: "National Restaurant Association Culinary Exams",
  },
  { requirementCode: "PSTRT", requirementName: "ProStart" },
  { requirementCode: "SSA", requirementName: "ServSafe Alcohol" },
  {
    requirementCode: "SSALRGNS",
    requirementName: "ServSafe Allergens Online Course",
  },
  { requirementCode: "SSFHNDLR", requirementName: "ServSafe Food Handler" },
  {
    requirementCode: "SSPRC",
    requirementName: "ServSafe Instructor and Proctor Certificates",
  },
  {
    requirementCode: "SSI",
    requirementName: "ServSafe International Food Safety",
  },
  {
    requirementCode: "PIC Ohio",
    requirementName: "ServSafe Person in Charge – Ohio",
  },
  { requirementCode: "SSWP", requirementName: "ServSafe Workplace" },
  {
    requirementCode: "SSMGR",
    requirementName: "ServSafe: Food Protection Manager Certification",
  },
  { requirementCode: "SMMR", requirementName: "Summer Institutes" },
  { requirementCode: "TXFG", requirementName: "Texas FoodGuard" },
  { requirementCode: "ID", requirementName: "Government ID" },
  { requirementCode: "PID", requirementName: "Photo ID" },
  { requirementCode: "GLI", requirementName: "General Liability Insurance" },
  { requirementCode: "HACCP", requirementName: "HACCP Certificate" },
];

// const capitalizeString = (string: string) => string?.replace(/\w/, c => c?.toUpperCase())

type AddCredentialFormProps = {
  handleClose: () => void;
  handleSubmitCredential: (credential: CredentialSchema) => void;
};

const AddCredentialForm: React.FC<AddCredentialFormProps> = (props) => {
  var { handleSubmitCredential, handleClose } = props;
  var { requirements } = useKitchenState();

  const [credential, setCredential] = useState<CredentialSchema>(
    {} as CredentialSchema
  );
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedCategory(event.target.value);
    setCredential((prevCred: CredentialSchema) => ({
      ...prevCred,
      category: event.target.value,
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setCredential((prevCredential: CredentialSchema) => ({
        ...prevCredential,
        [name]: file_data,
      }));
    }
  };

  const handleSubmit = () => {
    if (credential) {
      handleSubmitCredential(credential);
      handleClose();
    }
  };

  return (
    <div className="flex flex-col justify-evenly items-center my-10">
      <button
        className="absolute top-0 left-0 p-2 text-gray-600 hover:text-gray-800 "
        onClick={handleClose}
      />
      <div className="flex flex-col mx-auto">
        <img src="/logo192.png" alt="homecook-logo" className="w-[110px] h-[82px] self-center"/>
        <h1 className="text-2xl text-primary-200 text-center">Add Credentials</h1>
        <h1 className="text-base text-back-200 text-center mb-5">Choose Credential Requirement</h1>
      </div>
      <div className="w-full grid grid-flow-row gap-y-3">
        <select
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="formInput text-slate-500 bg-inherit w-max mx-auto h-full"
        >
          <option value="" className="text-slate-400">
            -- Requirement --
          </option>
          {requirements &&
            requirements.map((c, i) => (
              <option key={i} value={c.name}>
                {c.name}
              </option>
            ))}
        </select>

        <div className="flex flex-col mx-auto">
          <label className="text-slate-500 w-max text-left">Upload File</label>
          <input
            type="file"
            placeholder="Upload File"
            className="formInput m-1 w-max mx-auto h-full bg-inherit mb-5"
            name="file"
            onChange={handleFileChange}
          />
        </div>
        <button
          className="buttonsPrimary h-10 w-1/3 mx-auto text-xl"
          onClick={handleSubmit}
          data-testid="submit-button"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

type AddKitchenFormProps = {
  handleClose: () => void;
  handleAddKitchen: (kitchen: KitchenSpaceSchema | {}) => void;
};

const AddKitchenForm: React.FC<AddKitchenFormProps> = (props) => {
  var { handleAddKitchen, handleClose } = props;
  const [selectedState, setSelectedState] = useState<string>("");
  const [kitchen, setKitchen] = useState<KitchenSpaceSchema | {}>({});
  const [width, setWidth] = useState<string>("");
  const [length, setLength] = useState<string>("");

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedState(event.target.value);
    setKitchen((prevKitchen) => ({
      ...prevKitchen,
      state: event.target.value,
    }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setKitchen((prevKitchen) => ({
      ...prevKitchen,
      [name]: value,
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setKitchen((prevKitchen) => ({
        ...prevKitchen,
        [name]: file_data,
      }));
    }
  };

  const handleLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLength(value);
  };

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setWidth(value);
  };

  const handleSubmit = () => {
    var size = `0x0`;
    if (length && width && kitchen) {
      size = `${length}x${width}`;
      const kitchenObj = kitchen as DSCommunitySchema;
      kitchenObj.size = size;
      setKitchen(kitchenObj);
    }
    handleAddKitchen(kitchen);
  };

  return (
    <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 md:justify-center items-center md:my-20 md:gap-x-2 m-10 md:m-0">
      <button
        className="absolute top-0 left-0 p-2 text-gray-600 hover:text-gray-800 "
        onClick={handleClose}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="text-center md:w-1/3 pr-16 ml-24 md:ml-0">
        <h1 className="text-4xl text-primary-400">Add Kitchen</h1>
        <p className="text-xl text-black">
          Please fill in the following information
        </p>
      </div>
      <div className="w-full md:w-1/2  grid grid-flow-row gap-y-5">
        <input
          placeholder="Kitchen Name"
          name="name"
          className="formInput "
          onChange={handleInputChange}
        />
        <div className="grid grid-cols-4 gap-x-5">
          <input
            placeholder="Address"
            className=" col-span-2"
            name="address"
            onChange={handleInputChange}
          />
          <input
            placeholder="City"
            className="formInput"
            name="city"
            onChange={handleInputChange}
          />
          <input
            placeholder="County"
            className="formInput"
            name="county"
            onChange={handleInputChange}
          />
        </div>
        <div className="grid grid-cols-3 gap-x-5">
          {/* <input placeholder="Country" className="form-input"  name="country"
          onChange={handleInputChange} /> */}
          <select
            value={selectedState}
            onChange={handleStateChange}
            className="formInput text-slate-500"
          >
            <option value="">Select a state</option>
            {Object.values(states).map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
          <input
            placeholder="Zipcode"
            className="formInput"
            name="zipcode"
            onChange={handleInputChange}
          />
        </div>
        <div className="grid ">
          <label className="text-slate-500">Background Image</label>
          <input
            type="file"
            placeholder="Background Image URL"
            className="form-input"
            name="bgPhoto"
            onChange={handleFileChange}
          />
          <label className="text-slate-500">Logo</label>
          <input
            type="file"
            placeholder="Logo URL"
            className="form-input"
            name="logo"
            onChange={handleFileChange}
          />
        </div>
        <input
          placeholder="Website"
          className="formInput"
          name="website"
          onChange={handleInputChange}
        />
        <div className="grid grid-cols-2 gap-x-5">
          <div className="grid grid-cols-2 gap-x-5">
            <input
              placeholder="Kitchen Width"
              className="formInput"
              name="width"
              onChange={handleWidthChange}
            />
            <input
              placeholder="Kitchen Length"
              className="formInput"
              name="length"
              onChange={handleLengthChange}
            />
          </div>
          <input
            placeholder="Kitchen Capacity"
            className="formInput"
            name="capacity"
            onChange={handleInputChange}
          />
        </div>
        <textarea
          placeholder="Description"
          className="formInput"
          name="description"
          onChange={handleInputChange}
        />
        <button
          className="buttonsPrimary h-10 text-xl"
          onClick={handleSubmit}
          data-testid="submit-button"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

type AddPlanProps = {
  kitchen: KitchenSpaceSchema
  handleClose: () => void;
  handleAddPlan: (plan: DSCommunitySchema | {}) => void;
};

const AddPlanForm: React.FC<AddPlanProps> = (props) => {
  var { kitchen, handleAddPlan } = props;
  const recurrences = ["hourly", "daily", "weekly", "monthly", "annual"];
  const [selectedRecurrence, setSelectedRecurrence] = useState<string |'hourly' | 'daily' | 'weekly' | 'monthly' | 'annual'>("");

  const emptyPlan: PlanSchema = {
    plan_description: "",
    plan_name: "",
    plan_rate: 0,
    plan_recurrence: "hourly",
    plan_max_hours: 0
  }

  const [plan, setPlan] = useState<PlanSchema>(emptyPlan);

  const handleRecurrenceChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const {name, value} = event.target
    setSelectedRecurrence(value);
    setPlan((prevPlan) => ({
      ...prevPlan,
      [name]: value,
      plan_max_hours: maxHours((kitchen?.capacity) ? parseInt(kitchen.capacity) : 1, value)
    }));
  };


  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    if (name === "rate" || name === "plan_max_hours") {
      setPlan((prevPlan) => ({
        ...prevPlan,
        [name]: Number(value),
      }));
    } else {
      setPlan((prevPlan) => ({
        ...prevPlan,
        [name]: value,
      }));
    }
    // const { name, value } = event.target;
  };

  // const capitalizeString = (string: string) => string?.replace(/\w/, c => c?.toUpperCase())

  return (
    <div className="flex flex-col space-y-2 md:justify-center items-center md:gap-x-2 m-10 md:mx-0">
      <div className="flex flex-col">
        <img src="/logo192.png" alt="homecook-logo" className="w-[110px] h-[82px] self-center"/>
        <h1 className="text-2xl text-primary-200 text-center">Add Membership Plan</h1>
        <h1 className="text-base text-back-200 text-center">Enter plan information below</h1>

      </div>
      <div className="font-open-sans w-full px-5  grid grid-flow-row gap-y-5">
        <label className="text-primary-200 text-xs">
          <span className="flex flex-row font-bold">Plan Name 
            {/* <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300" /> */}
          </span>
          <input
            id="plan_name"
            placeholder="Name"
            className="formInput bg-inherit w-full my-1"
            name="plan_name"
            onChange={handleInputChange}
          />
        </label>

        <label className="text-primary-200  text-xs">
          <span className="flex flex-row font-bold">
            Plan Description 
            {/* <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300" /> */}
          </span>
          <input
            placeholder="Description"
            className="formInput bg-inherit w-full my-1"
            name="plan_description"
            onChange={handleInputChange}
          />
        </label>

        <label className="flex flex-col text-primary-200 text-xs">
          <span className="flex flex-row font-bold">
            Plan Recurrence 
            <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300 cursor-pointer" />
          </span>
          <select
            name="plan_recurrence"
            value={selectedRecurrence}
            onChange={handleRecurrenceChange}
            className="formInput text-slate-500 bg-inherit w-full my-1"
          >
            <option value="">-- Recurrence --</option>
            {Object.values(recurrences).map((recurrence) => (
              <option key={recurrence} value={recurrence}>
                {capitalizeString(recurrence)}
              </option>
            ))}
          </select>
        </label>

        
        {
          (selectedRecurrence && (!['hourly', 'daily'].includes(selectedRecurrence))) ? (
            <>
              <label className="text-primary-200 text-xs">
                <span className="flex flex-row font-bold">
                  Maximum {capitalizeString(selectedRecurrence)} Hours / Member (capacity: ~{kitchen?.capacity ? kitchen?.capacity : 0})
                  <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300 cursor-pointer" />
                </span>
                <input
                  // placeholder={`${styleThousandCommas(maxHours(kitchen?.capacity ? parseInt(kitchen.capacity) : 1, selectedRecurrence))} hours`}
                  className="formInput bg-inherit w-full my-1"
                  name="plan_max_hours"
                  // type="number"
                  disabled
                  // value={maxHours(kitchen?.capacity ? parseInt(kitchen.capacity) : 1, selectedRecurrence)}
                  value={`${styleThousandCommas(maxHours(kitchen?.capacity ? parseInt(kitchen.capacity) : 1, selectedRecurrence))} hours`}
                  onChange={handleInputChange}
                />
              </label>
            </>
          ) : (
            <>
            </>
          )
        }



        <label className="text-primary-200 text-xs">
          <span className="flex flex-row font-bold">
            Plan Rate (USD) 
            <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300 cursor-pointer" />
          </span>
          <input
            placeholder="Rate ($)"
            className="formInput bg-inherit w-full my-1"
            name="plan_rate"
            type="number"
            step={0.01}
            min={0}
            onChange={handleInputChange}
          />
        </label>
        <button
          className="buttonsPrimary h-10 text-xl"
          onClick={() => {
            handleAddPlan(plan)
            setPlan({})
          }}
          data-testid="submit-button"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

type AddRequirementProps = {
  handleClose: () => void;
  handleAddRequirement: (kitchen: DSCommunitySchema | {}) => void;
};

const AddRequirementForm: React.FC<AddRequirementProps> = (props) => {
  var { handleAddRequirement } = props;

  const [requirement, setRequirement] = useState({
    // description: "",
    name: "",
    validity: "",
  });

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    setRequirement((prevReq) => ({
      ...prevReq,
      [name]: value,
    }));
  };

  return (
    <div className="flex flex-col space-y-2   md:justify-center items-center  md:gap-x-2 m-10 ">
      <div className="flex flex-col">
        <img src="/logo192.png" alt="homecook-logo" className="w-[110px] h-[82px] self-center"/>
        <h1 className="text-2xl text-primary-200 text-center">Add Requirement</h1>
        <h1 className="text-base text-back-200 text-center">Choose membership requirement below</h1>

      </div>
      <div className="grid grid-flow-row gap-y-5 ">
        {/* <input placeholder="Name" className="formInput" name="name" onChange={handleInputChange}/>         */}
        {/* <input placeholder="Description" className="formInput" name="description" onChange={handleInputChange}/> */}
        <select
          name="name"
          value={requirement.name}
          onChange={handleInputChange}
          className="formInput text-slate-500 bg-inherit"
        >
          <option value="" className="text-slate-400">
            -- Requirement --
          </option>
          {requirementsLookUp.map((c, i) => (
            <option key={i} value={c.requirementName}>
              {c.requirementName}
            </option>
          ))}
        </select>
        <button
          className="buttonsPrimary h-10 text-xl"
          onClick={() => handleAddRequirement(requirement)}
          data-testid="submit-button"
        >
          Submit
        </button>
      </div>
    </div>
  );
};


type AddInventoryFormProps = {
  kitchen?: KitchenSpaceSchema
  handleClose: () => void;
  handleAddInventory: (inventory: InventorySchema) => void;
};

const AddInventoryForm: React.FC<AddInventoryFormProps> = (props) => {
  var { kitchen, handleAddInventory } = props;
  // const recurrences = ["hourly", "daily", "weekly", "monthly", "annual"];
  // const measureUnits = ["hourly", "daily", "weekly", "monthly", "annual"];
  // const [selectedRecurrence, setSelectedRecurrence] = useState<'hourly' | 'daily' | 'weekly' | 'monthly' | 'annual'>();

  const emptyInventory: InventorySchema = {
    item_name: "",
    total_quantity: 0,
    category: 'equipment',
    measure_unit: 'kg'
  }

  const [inventory, setInventory] = useState<InventorySchema>(emptyInventory);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>(emptyInventory.category);
  const [selectedMeasureUnit, setSelectedMeasureUnit] = useState<string>(emptyInventory.measure_unit);
  const { searchInventoryCatalog } = useAddInventory()

  const [suggestions, setSuggestions] = useState<string[]>([])



  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      // Read the selected image file as a data URL
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage("");
    }
    handleFileChange(event);
  };


  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    if (name === "unit_price" || name === "total_quantity") {
      setInventory((prevInv) => ({
        ...prevInv,
        [name]: Number(value),
      }));
    } else {
      setInventory((prevInv) => ({
        ...prevInv,
        [name]: value,
      }));
    }
    if (name === "item_name" && value){
      handleSuggestion(value)
    }
  };

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    // console.log("{ name, value }", { name, value })
    setInventory((prevInv) => ({
      ...prevInv,
      [name]: value,
    }));

    if(name === 'category' ){
      setSelectedCategory(value)
    } else if(name === 'measure_unit' ){
      setSelectedMeasureUnit(value)
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setInventory((prevInv) => ({
        ...prevInv,
        [name]: file_data,
      }));
    }
  };

  const getEnumValue = (key: string): string =>  {
    return MeasurementUnits[key as keyof typeof MeasurementUnits].toLowerCase() as string
  }



  const handleSuggestion = (query: string) => {
    if (inventory && query && inventory?.category){
      let suggestionList = searchInventoryCatalog(inventory.category, query)
      setSuggestions(suggestionList)
    } else {
      setSuggestions([])
    }
    
  }

  return (
    <div className="flex flex-col space-y-2 md:justify-center items-center md:gap-x-2 m-10 md:mx-0">
      <div className="flex flex-col">
        <img src="/logo192.png" alt="homecook-logo" className="w-[110px] h-[82px] self-center"/>
        <h1 className="text-2xl text-primary-200 text-center">Add Inventory Item</h1>
        <h1 className="text-base text-back-200 text-center">Enter information below</h1>

      </div>
      <div className="font-open-sans w-full px-5  grid grid-flow-row gap-y-5">
        <label className="text-primary-200 text-xs">
          <span className="flex flex-row font-bold">Item Name <span className='text-red-900 text-sm mx-2'>*</span>
            {/* <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300" /> */}
          </span>
          <input
            id="item_name"
            placeholder="Name"
            className="formInput bg-inherit w-full my-1"
            name="item_name"
            value={inventory.item_name}
            onChange={handleInputChange}
          />
          {
            (suggestions && suggestions.length > 0 && inventory?.item_name) && 
            (
              <div className="bg-gray-200 h-auto w-auto fixed">
              {
                suggestions.slice(0, 3).map((suggestion, i) => (
                    <p 
                      key={i} 
                      className="font-normal text-[16px] text-primary-300 cursor-pointer mb-1 p-1 bg-gray-200"
                      onClick={()=> {
                        setInventory(prev => ({...prev, item_name: suggestion}))
                        handleSuggestion("")
                      }}
                      >
                      {suggestion}
                    </p>
                  )
                )
              }
              </div>
            )
          }
        </label>

        <label className="flex flex-col text-primary-200 text-xs">
          <span className="flex flex-row font-bold">
            Item Category <span className='text-red-900 text-sm mx-2'>*</span>
            <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300 cursor-pointer" />
          </span>
          <select
            name="category"
            value={selectedCategory}
            onChange={handleSelectChange}
            className="formInput text-slate-500 bg-inherit w-full my-1"
          >
            <option value="" disabled>-- Category --</option>
            {Object.keys(InventoryCategories).map((category, i) => (
              <option key={i} value={category}>
                {capitalizeString(category)}
              </option>
            ))}
          </select>
        </label>

        <div className="flex flex-row justify-between">
          <label className="text-primary-200 text-xs w-3/5">
            <span className="flex flex-row font-bold">
              Item Quantity <span className='text-red-900 text-sm mx-2'>*</span>
              {/* <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300" /> */}
            </span>
            <input
              // placeholder="Quantity"
              className="formInput bg-inherit w-full my-1"
              id="total_quantity"
              name="total_quantity"
              type="number"
              step={1}
              min={0}
              onChange={handleInputChange}
            />
          </label>

          <label className="flex flex-col text-primary-200 text-xs w-2/6 ">
            <span className="flex flex-row font-bold">
              Measurement Unit <span className='text-red-900 text-sm mx-2'>*</span>
              <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300 cursor-pointer" />
            </span>
            <select
              name="measure_unit"
              value={selectedMeasureUnit}
              onChange={handleSelectChange}
              className="formInput text-slate-500 bg-inherit w-full my-1"
            >
              <option value="" disabled>-- Unit --</option>
              {Object.keys(MeasurementUnits).map((measureUnit, i) => (
                <option key={i} value={measureUnit}>
                  {capitalizeString(getEnumValue(measureUnit))} ({measureUnit})
                </option>
              ))}
            </select>
          </label>
        </div>

        <label className="text-primary-200 text-xs">
          <span className="flex flex-row font-bold">
            Unit Price (USD) <span className='text-red-900 text-sm mx-2'>*</span>
            <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300 cursor-pointer" />
          </span>
          <input
            placeholder="Rate ($)"
            className="formInput bg-inherit w-full my-1"
            name="unit_price"
            type="number"
            step={0.01}
            min={0}
            onChange={handleInputChange}
          />
        </label>

        <label className="text-primary-200 text-xs">
          <span className="flex flex-row font-bold">
            Item Image 
            <InfoIcon className="h-3 w-3 my-auto mx-1 text-primary-300 cursor-pointer" />
          </span>
          <div className="flex flex-col border-primary-200 border-[1px] rounded-xl text-center my-1 p-1 cursor-pointer">
            {
                (selectedImage) ?  (
                    <img
                      id="image-input-logo"
                      src={selectedImage}
                      alt="Kitchen Logo"
                      className="relative m-auto max-w-sm max-h-32"
                  />) : (<>
                  <UploadIcon className="text-primary-200 hover:text-primary-300 m-auto" />
                  Choose File 
                  </>)
            }
            <input 
              id="image_url"
              name="image_url" 
              type="file" 
              className="invisible" 
              onChange={handleImageChange}
            />
          </div>
        </label>

        <button
          className="buttonsPrimary h-10 text-xl"
          onClick={() => {
            console.log("current inventory: ", inventory)
            if (kitchen?.id){
              inventory.kitchen_id = kitchen.id
            }
            handleAddInventory(inventory)
            setInventory(emptyInventory)
          }}
          data-testid="submit-button"
          disabled={!inventory?.item_name || !inventory?.category || !inventory?.total_quantity|| !inventory?.measure_unit || !inventory?.unit_price}
        >
          Add
        </button>
      </div>
    </div>
  );
};

type AddMemberFormProps = {
  isOpen: boolean
  handleClose: () => void;
  handleAddMember: (kitchen: DSCommunitySchema | {}) => void;
  
};

const AddMemberForm: React.FC<AddMemberFormProps> = (props) => {
  return <CreateMembership isOpen={props.isOpen} handleClose={props.handleClose}/>;
};

type AddReservationFormProps = {
  handleClose: () => void;
  isOpen?: boolean
};

const AddReservationForm: React.FC<AddReservationFormProps> = (props) => {
  return <StartBooking isOpen={props.isOpen} handleClose={props.handleClose} />;
};

type DeleteConfirmationFormProps = {
  handleClose: () => void;
  onDeleteConfirmation: () => void;
};

const DeleteConfirmationForm: React.FC<DeleteConfirmationFormProps> = (
  props
) => {
  let { handleClose, onDeleteConfirmation } = props;
  return (
    <div className="grid container space-x-2 space-y-2 justify-center align-center">
      <h1 className="text-xl text-primary-400">
        Are you sure you want to delete?
      </h1>
      <div className="text-lg text-center pb-5">
        Please confirm kitchen deletion
      </div>
      <div className="text-center grid grid-cols-2 gap-5">
        <button
          className="h-10 text-center text-white bg-cancel rounded-md drop-shadow-lg"
          onClick={onDeleteConfirmation}
          data-testid="delete-button"
        >
          Yes
        </button>
        <button
          className="text-center text-white bg-stone-400 rounded drop-shadow-lg"
          onClick={handleClose}
        >
          No
        </button>
      </div>
    </div>
  );
};

type NewMessageFormProps = {
  handleClose: () => void;
};

const NewMessageForm: React.FC<NewMessageFormProps> = (props) => {
  const { handleClose } = props
  const { newMessageMember } = useMemberState();
  const { switchMember } = useLoadMessages()
  

  // const capitalizeString = (string: string) =>
  //   string?.replace(/\w/, (c) => c?.toUpperCase());

  let get_primary_text = (member?: string) => {
    return (
      <p className="font-open-sans font-extrabold text-[14px] text-[#2D3748]">
        {member === "chatbot" ? capitalizeString(member) : member}
      </p>
    );
  };
  const handleNewMessage = () => {
    switchMember(newMessageMember);
    handleClose();
  }
  return (
    <div className="flex flex-row container space-x-2 space-y-2 justify-center align-center w-[500px] ">
      <div className="flex flex-col w-full">
      <div className="flex flex-col border-b-2 w-full text-center p-2">
        <img src="/logo192.png" alt="homecook-logo" className="w-[110px] h-[82px] self-center"/>
        <h1 className="text-2xl text-primary-200 text-center">Start New Message</h1>
        <h1 className="text-base text-back-200 text-center">Choose a user to message below</h1>

      </div>
        <div className="text-xl border-b-2  flex flex-row px-2 pt-2">
          <h1 className="mt-1">To: </h1>{" "}
          {newMessageMember ? (
            <div
              className={`flex justify-start m-1 px-5 cursor-pointer h-[40px] rounded `}
            >
              <Avatar
                className={`h-[30px] w-auto ${
                  newMessageMember === "chatbot"
                    ? "text-[#51a0b5]/90"
                    : "text-[#013847]/90"
                }`}
              />
              <div className="flex flex-col mx-4">
                <div>{get_primary_text(newMessageMember)}</div>
              </div>
            </div>
          ) : null}
        </div>
        <hr />
        <ul>
          <AddMessageList />
        </ul>
        <button className="buttonsPrimary text-black p-1 m-2" onClick={() => handleNewMessage()}>Start Chat</button>
      </div>
    </div>
  );
};

export {
  AddKitchenForm,
  AddReservationForm,
  DeleteConfirmationForm,
  NewMessageForm,
  AddMemberForm,
  AddPlanForm,
  AddInventoryForm,
  AddRequirementForm,
  AddCredentialForm,
};
