import  { useState, useEffect } from "react";
import { useMemberState } from "../contexts/membercontext";

import { MembershipSchema } from "../data/types";
import { ReactComponent as Avatar } from "../icons/ChatbotIcon.svg";

const capitalizeString = (string: string) =>
  string?.replace(/\w/, (c) => c?.toUpperCase());

// List of users
const AddMessageList = () => {
  var { changeNewMessageMember, memberships } = useMemberState()
  
  const [selectedUser, setSelectedUser] = useState("")
  useEffect(() => {
    changeNewMessageMember(selectedUser);
    // if (loadData !== undefined){loadData()}
    // eslint-disable-next-line
  }, [selectedUser])

  let get_primary_text = (member: string) => {
    return (
      <p className="font-open-sans font-extrabold text-[14px] text-[#2D3748]">
        {member === "chatbot" ? capitalizeString(member) : member}
      </p>
    );
  };
 
  return (
    <div className=" overflow-y-auto">
      <div>
        {/* CHATBOT MESSAGES */}
        <div
          style={{
            backgroundColor:
              "chatbot" === selectedUser ? "#b2c2c7" : "#f7fafc",
          }}
          className={`flex justify-start m-1 p-5 cursor-pointer h-[80px] rounded`}
          onClick={() => setSelectedUser("chatbot")}
        >
          <Avatar className={`h-auto w-auto text-[#51a0b5]/90`} />
          <div className="flex flex-col mx-4">
            <div>{get_primary_text("chatbot")}</div>
          </div>
        </div>
        {/* MEMBER MESSAGES */}
        {memberships !== undefined
          ? memberships.map(
              (member: MembershipSchema, i: number) => {
                if (member.username !== "chatbot") {
                  return (
                    <div
                      style={{
                        backgroundColor:
                          member.username === selectedUser ? "#b2c2c7" : "#f7fafc",
                      }}
                      className={`flex justify-start m-1 p-5 cursor-pointer h-[80px] rounded`}
                      key={i}
                      onClick={() => setSelectedUser(member.username? member.username : "")}
                    >
                      <Avatar
                        className={`h-auto w-auto ${
                          member.username === "chatbot"
                            ? "text-[#51a0b5]/90"
                            : "text-[#013847]/90"
                        }`}
                      />
                      <div className="flex flex-col mx-4">
                        <div>{get_primary_text(member.username? member.username : "")}</div>
                      </div>
                    </div>
                  );
                } else {
                  return <></>;
                }
              }
            )
          : null}
      </div>
    </div>
  );
};

export default AddMessageList;
