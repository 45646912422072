import {useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SecurityIcon } from '../../icons/SecurityIcon.svg'
import { ReactComponent as PreferenceIcon } from '../../icons/PreferenceIcon.svg'
import { ReactComponent as InvoiceIcon } from '../../icons/InvoiceIcon.svg'
import { ReactComponent as NotificationIcon } from '../../icons/NotificationIcon.svg'
import { ReactComponent as PaymentIcon } from '../../icons/PaymentIcon.svg'
import { ReactComponent as EditProfileIcon } from '../../icons/EditProfileIcon.svg'

const settingsOptions = [
  {name: "Edit Profile", icon: EditProfileIcon, path: "/"},
  {name: "Login & Security", icon: SecurityIcon, path: "/"},
  {name: "Preferences", icon: PreferenceIcon, path: "/kitchens/preferences"},
  {name: "Invoices", icon: InvoiceIcon, path: "/"},
  {name: "Payment", icon: PaymentIcon, path: "/"},
  {name: "Notifications", icon: NotificationIcon, path: "/"}
]

const Settings = () => {

  const navigate = useNavigate();
  
  useEffect(() => {
    document.title = `HomeCook | Settings`
  }, [])

  return (
      <div className='flex flex-col h-[900px] sm:h-screen items-center bg-[#D9D9D9] text-[#013847]/80 p-5'> 
        {/* Settings  */}
        <div className='grid grid-cols-1 sm:grid-cols-2 grid-flow-dense w-8/12 mx-auto my-5'>
          {
            settingsOptions.map((setting, i) => (
              <div key={i} className='flex flex-col bg-[#5DB6CE]  hover:bg-[#5DB6CE]/80 text-[#013847] text-2xl rounded-md h-[180px] m-5 w-[360px] justify-evenly items-center cursor-pointer' onClick={() => (setting?.path) ? navigate(setting.path) : null}>
                  <setting.icon />
                  {setting.name}
              </div>
            ))
          }
        </div>
      </div>
    )
}

export default Settings