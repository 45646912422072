import moment from 'moment'

import LoadingScreen from "../pages/shared/LoadingScreen"
import { MdCheckBox, MdCheckBoxOutlineBlank} from "react-icons/md";
// import OperatingHoursList from './OperatingHoursList'

import { useKitchenHook } from "../hooks/kitchenhooks";

import { KitchenOptionsType, selectedDaysType } from "../data/types";
import {  capitalizeString } from "../utils/helpers";


type daysType = 'sun'|'mon'|'tue'|'wed'|'thu'|'fri'|'sat'

// const account_avatar: string = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`

type OperatingHoursListProps = {
//   setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
kitchenOptions: KitchenOptionsType;
setKitchenOptions: React.Dispatch<React.SetStateAction<KitchenOptionsType>>
selectedDays: selectedDaysType
setSelectedDays: React.Dispatch<React.SetStateAction<selectedDaysType>>
//   plans: PlanSchema[] | undefined;
};

const OperatingHoursList: React.FC<OperatingHoursListProps> = ({ kitchenOptions, selectedDays, setKitchenOptions, setSelectedDays }) => {
  const daysOfWeek: Set<daysType> = new Set([ "sun", "mon","tue",  "wed","thu",  "fri", "sat"] as daysType[])


  const handleSelectDay = (dayName: daysType) => {
    setSelectedDays(prevDays => {
        return {
            ...prevDays,
            [dayName]: !prevDays[dayName]
        }
    })
  }
  

  const isDaySelected = (day: daysType) => {
    return (selectedDays && selectedDays[day]) ? true : false
  }


  const getFieldTime = (name: 'open_time' | 'close_time', day: daysType) => {
    let dayTimes = kitchenOptions[day]
    // console.log("getFieldTime kitchenOptions", kitchenOptions)
    // console.log("getFieldTime dayTimes", dayTimes)
    if (dayTimes){
      let dayTimesName = dayTimes[name]
      // console.log("getFieldTime dayTimesName", dayTimesName)
      let newTime = moment(dayTimesName, 'HH:mm A').format('HH:mm');
      // console.log("getFieldTime newTime", newTime)

      return newTime
    }
    return ""
  }

  // Function to round time to nearest 30 minutes
  const roundToNearest30Minutes = (inputTime: string) => {
    const momentTime = moment(inputTime, 'HH:mm');
    // Round to nearest 30 minutes
    const minutes = momentTime.minutes();
    const roundedMinutes = minutes < 15 ? 0 : minutes < 45 ? 30 : 0;
    return momentTime.minutes(roundedMinutes).format('HH:mm');
  };

  // const isTimeSameOrGreater = (timeString1: string, timeString2: string): boolean => {
  //   const moment1 = moment(timeString1, 'HH:mm');  // Use format string (not deprecated)
  //   const moment2 = moment(timeString2, 'HH:mm');  // Use format string (not deprecated)
  //   if (!moment1.isValid() || !moment2.isValid()) {
  //     throw new Error('Invalid time string format');
  //   }
  //   // Use isBefore or isSame for comparison
  //   return moment1.isBefore(moment2) === false; // Not strictly before means same or after
  // }

  const isSameOrLess = (timeString1: string, timeString2: string): boolean => {
    const moment1 = moment(timeString1, 'h:mmA');  // Use format string (not deprecated)
    const moment2 = moment(timeString2, 'h:mmA');  // Use format string (not deprecated)
    if (!moment1.isValid() || !moment2.isValid()) {
      throw new Error('Invalid time string format');
    }
    // Use isBefore or isSame for comparison
    return moment1.isBefore(moment2) || moment1.isSame(moment2);
  }

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>, day: daysType) => {
      event.preventDefault()
      const timeFieldName = event.target.name;
      const inputTime = event.target.value;
      var formattedTime = moment(roundToNearest30Minutes(inputTime), 'HH:mm').format('hh:mmA');
    
      setKitchenOptions(prev => {
        let prevHours = prev[day]
        formattedTime = timeFieldName === "open_time" ? formattedTime : (prevHours?.open_time && formattedTime && isSameOrLess(prevHours?.open_time, formattedTime) ? formattedTime : prevHours?.open_time) as string
        return {
          ...prev,
          [day]: {
            ...prevHours,
            [timeFieldName]: formattedTime,
          }
        }
      })
  };



  // Memoized onChange handler function
  // const handleChange = useMemo(() => {
  //   return (event: React.ChangeEvent<HTMLInputElement>) => {
  //       const dayName = event.target.value as daysType
  //       isDaySelected(dayName);
  //   };
  // }, []); // No dependencies, so the function is memoized only once


  const { isLoading } = useKitchenHook()
  if (isLoading){
    return (
      <LoadingScreen />
    )
  }


  return (
 
    <div className="flex flex-col mt-6 ">
        <div className="flex flex-col text-[#555] font-open-sans bg-slate-200/80 rounded-md p-3 w-max h-max">
            <p className="font-raleway font-bold text-lg">
              Operating Hours
            </p>
            {
                Array.from(daysOfWeek).map((day: daysType, i: number) => {
                    return (
                    <OperatingDayHours 
                        key={i} 
                        day={day} 
                        daysOfWeek={daysOfWeek}
                        handleSelectDay={handleSelectDay} 
                        isDaySelected={isDaySelected}
                        getFieldTime={getFieldTime}
                        handleTimeChange={handleTimeChange}
                    />
                    )
                })
            }
        </div>
    </div>
    
  );
};



type OperatingDayHoursProps = {
  day: daysType
  daysOfWeek: Set<daysType>
  handleSelectDay: (day: daysType) => void
  isDaySelected: (day: daysType) => boolean, 
  getFieldTime: (name: 'open_time' | 'close_time', day: daysType) => string, 
  handleTimeChange: (event: React.ChangeEvent<HTMLInputElement>, day: daysType) => void
}




const OperatingDayHours: React.FC<OperatingDayHoursProps> = ({day, handleSelectDay, isDaySelected, getFieldTime, handleTimeChange}) => {

//   const selectedDay = useMemo(() => isDaySelected(day), [day, daysOfWeek]);
  return (
    <div className="flex flex-row text-md font-bold my-auto">
            <span className="flex flex-row m-auto cursor-pointer" onClick={() => handleSelectDay(day)}>
              {
                (isDaySelected(day)) ? (
                  <MdCheckBox style={{fontSize: 20}} className="mx-2 my-auto  text-primary-300 border-0"  />
                ) : (
                  <MdCheckBoxOutlineBlank style={{fontSize: 20}} className="mx-2 my-auto text-primary-300 border-0"  />
                )
              }
              
              {/* <input 
                className="mx-2 my-auto bg-inherit form-checkbox h-4 w-4 text-primary-300" 
                type="checkbox"
                checked={isDaySelected(day)}
                // value={day}
              /> */}
              {capitalizeString(day)}
            </span>

            <span className="flex flex-row text-left font-normal ml-auto">
              <input 
                onChange={e => handleTimeChange(e, day)} 
                className="formInput mx-2 my-auto bg-inherit w-full" 
                type="time" 
                name="open_time"
                value={getFieldTime('open_time', day)}
                step="1800"
              />
              &nbsp; - &nbsp; 
              <input 
                onChange={e => handleTimeChange(e, day)} 
                className="formInput mx-2 my-auto bg-inherit w-full" 
                type="time"
                name="close_time"
                value={getFieldTime('close_time', day)}
                min={getFieldTime('open_time', day)}
                step="1800"
              />
              &nbsp; 
            </span>
          </div>
  )
}

export default OperatingHoursList;
