const env = process.env.REACT_APP_NODE_ENV;

let apiUrl = process.env.REACT_APP_API_URL_DEV;
if (env === 'test') {
  apiUrl = process.env.REACT_APP_API_URL_TEST;
} else if (env === 'prod') {
  apiUrl = process.env.REACT_APP_API_URL_PROD;
}

let webUrl = process.env.REACT_APP_WEB_URL_DEV;
if (env === 'test') {
  webUrl = process.env.REACT_APP_WEB_URL_TEST;
} else if (env === 'prod') {
  webUrl = process.env.REACT_APP_WEB_URL_PROD;
}

const config = {
  apiUrl,
  webUrl
}

export default config;
