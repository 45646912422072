import KitchenRecord from "../molecules/KitchenRecord";
import { useKitchenState } from "../contexts/kitchencontext";

import { DSCommunitySchema } from "../data/types";


const KitchenList = () => {
  let { kitchens } = useKitchenState()

  return (
    <div className=" my-3 h-auto rounded-3xl">
      <div className="flex flex-wrap gap-10 m-5 justify-center bg-back-500 p-10 rounded-3xl border-2 border-black">
        {kitchens && kitchens.length !== 0 ? (
          kitchens.map((kitchen: DSCommunitySchema, i: number) => (
            <KitchenRecord
              key={i}
              kitchen={kitchen}
            />
          ))
        ) : (
          <div className="flex flex-row justify-evenly">
            <div>No Kitchens</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KitchenList;
