import { useEffect, useState } from "react";

import { useLoadBookings } from '../../hooks/memberhooks'

import SearchBox from "../../components/SearchBox";
import BookingsGrid from "../../molecules/BookingsGrid";
import Calendar from "../../molecules/Calendar";
// import { AddReservationModal } from '../../components/AppModal'

import ToggleSwitch from "../../components/ToggleSwitch";
import StartBooking from "../../components/StartBooking";
import StartMultiBooking from "../../components/StartMultiBooking";
import StaggeredDropDown from "../../molecules/Dropdown"

// import {useKitchenState} from '../../contexts/kitchencontext'

const Bookings = () => {
  const [addBooking, toggleAddBooking] = useState(false);
  const [calendarView, toggleCalendarView] = useState(false);
  const [multiBooking, toggleMultiBooking] = useState(false);
  

  const toggleAddBookingModal = () => {
    addBooking? (toggleAddBooking(false)) : (toggleAddBooking(true));
  }
  const toggleAddMultiBookingModal = () => {
    multiBooking? (toggleMultiBooking(false)) : (toggleMultiBooking(true));
  }
  const closeAppModal = () => {
    toggleAddBooking(false);
  };

  const options = [
    {text:"New one-time reservation", action: toggleAddBookingModal},
    {text:"New recurring reservation", action: toggleAddMultiBookingModal}
  ]

  var { bookings } = useLoadBookings();
  bookings = bookings && Array.isArray(bookings) ? bookings : [];
  console.log(multiBooking)
  useEffect(() => {
    document.title = "HomeCook | Reservations";
    // eslint-disable-next-line
  }, [bookings]);

  if (addBooking) {
    return (
      <>
        <StartBooking handleClose={closeAppModal}  />
      </>
    );
  } else if(multiBooking){
    return (
      <>
        <StartMultiBooking handleClose={toggleAddMultiBookingModal} />
      </>
    );
  } else {
    return (
      <>
        <div className="md:p-[1%] pt-16 md:pt-16 mx-auto w-full flex flex-col font-open-sans h-[900px] sm:h-screen overflow-y-scroll bg-[#D9D9D9]">
          <h1 className="flex font-raleway justify-center text-xl text-[#013847]/40">
            Reservations
          </h1>
          <div className="flex" style={{ color: "#888" }}>
            {bookings && bookings.length ? bookings.length : "0"} Reservations
          </div>
          <div className="grid">
            <div className="flex flex-row ">
              <SearchBox searchType="reservations" setIsOpen={toggleAddBookingModal}  />
              <StaggeredDropDown bodyStyle="pl-34 flex items-end justify-end" buttonStyle="flex items-center gap-2 px-3 py-2 rounded-md buttonsPrimary w-48 h-10 p-2 " title="New Reservations" options={options}/>  
            </div>
            <div className="flex flex-row justify-end">
              <ToggleSwitch
                onChange={() => toggleCalendarView(!calendarView)}
                isChecked={calendarView}
              />
            </div>

            {calendarView ? (
              <div className="p-4">
                <Calendar
                  bookings={bookings}
                  toggleCalendarView={toggleCalendarView}
                />
              </div>
            ) : (
              <BookingsGrid bookings={bookings} />
            )}
          </div>
        </div>
      </>
    );
  }
};

export default Bookings;
