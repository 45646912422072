import { ReactNode, useState,useContext, createContext } from "react";

// import { getAllKitchens, getKitchenID, addKitchen, updateKitchen, getMembershipPlans, addMembershipPlans,getMembershipPlan, updateMembershipPlans, getMembershipRequirements, addMembershipRequirement, deleteMembershipRequirement } from "../api/crud";
import { KitchenStateType, KitchenSpaceSchema, AccountSchema, PlanSchema, RequirementSchema, InventorySchema } from "../data/types";

const KitchenContext = createContext({});

type PropsChildren = {
  children?: ReactNode;
  communityId: string
  initialKitchens?: KitchenSpaceSchema[];
  authUser: AccountSchema | {}
  // primaryCommunity: CommunitySchema | {}
}

const KitchenProvider = (props: PropsChildren) => {
  const [kitchens, updateKitchens] = useState(props.initialKitchens || undefined);
  const [searchQuery, setSearchQuery] = useState("");
  const [nameSort, setNameSort] = useState(false);
  const [idSort, setIDSort] = useState(false);
  const [currentKitchen, setKitchen] = useState<KitchenSpaceSchema | null>(null);
  const [plans, setPlans] = useState<PlanSchema[]>([])
  const [requirements, setRequirements] = useState<RequirementSchema[]>([])
  const [inventoryList, setInventoryList] = useState<InventorySchema[]>([])


  var { communityId } = props
  // var communityId = (authUser && 'primary_community' in authUser && authUser.primary_community) ? authUser.primary_community : ""

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };
  const toggleNameSort = () => {
    setNameSort((prevSort) => !prevSort);
  };

  const toggleIDSort = () => {
    setIDSort((prevSort) => !prevSort);
  };


  const clearCurrentKitchen = () => {
    setKitchen(null)
  }


  let data = { 
    communityId, idSort,nameSort,
    kitchens, currentKitchen,
    plans, requirements, searchQuery,
    inventoryList,
    setKitchen, setRequirements,
    clearCurrentKitchen, updateKitchens,
    setPlans, handleSearch,
    toggleNameSort, toggleIDSort,
    setInventoryList
  }

  return <KitchenContext.Provider value={data} {...props} />;
};

const useKitchenState = () => {
  const context: KitchenStateType = useContext(KitchenContext);
  if (context === undefined) {
    throw new Error(`useKitchenState must be used within KitchenProvider`);
  }
  return context;
};

export { useKitchenState, KitchenProvider, KitchenContext };
