import axios from "axios";
// import BaconIcon from '../icons/foodIcons/Bacon.svg'
// import BeefIcon  from '../icons/foodIcons/Beef.svg'
// import BroccoliIcon from '../icons/foodIcons/Broccoli.svg'
// import ChickenIcon  from '../icons/foodIcons/Chicken.svg'
// import CucumberIcon from '../icons/foodIcons/Cucumber.svg'
// import GrapesIcon from '../icons/foodIcons/Grapes.svg'
// import PeachIcon  from '../icons/foodIcons/Peach.svg'
// import  WhiteBreadIcon from '../icons/foodIcons/WhiteBread.svg'


import { 
  PreferenceSchema, DSCommunitySchema, AccountSchema, 
  BookingSchema, AuthSchema, MembershipSchema, PlanSchema, InventorySchema,
  MessageSchema, CredentialSchema, RequirementSchema, CommunitySchema, KitchenSpaceSchema,
  KitchenOptionsType
} from "../data/types";

import config from '../config';

const new_api_host = config.apiUrl
const secretKey = process.env.REACT_APP_SECRET_KEY ? process.env.REACT_APP_SECRET_KEY : ""
const apiKey = process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : ""

const axiosInstance = axios.create({
  baseURL: new_api_host,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});


// Adding API access keys to headers
axiosInstance.defaults.headers.common['HOMECOOK-SECRET-KEY'] = secretKey
axiosInstance.defaults.headers.common['HOMECOOK-API-KEY'] = apiKey


axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && error.response.statusText === "Unauthorized") {
        const refresh_token = localStorage.getItem('refresh_token');

        return axiosInstance
            .post('/community/auth', {refresh: refresh_token})
            .then((response) => {

                let data = (response.data && response.data.body) ? response.data.body : response.data

                localStorage.setItem('access_token', data.login_token);
                localStorage.setItem('refresh_token', data.refresh_token);

                // axiosInstance.defaults.headers['Authorization'] = data.login_token
                originalRequest.headers['Authorization'] = data.login_token

                return axiosInstance(originalRequest);
            })
            .catch(err => {
                console.log(err)
            });
    }
    // return Promise.reject(error);
}
)

type KitchenIdParameter = {
  communityId: string
  id: string;
}

type UpdateKitchenParameter = {
  communityId: string;
  spaceId: string;
  kitchenUpdates: DSCommunitySchema | {};
}

// type UpdateKitchenOptionsParameter = {
//   communityId: string;
//   spaceId: string;
//   kitchenUpdates: KitchenOptionsType | {};
// }

type PreferenceParameter = {
  communityId: string;
  data?: PreferenceSchema;
}


const startNewAuth = async (user: AccountSchema) => {
  let data: AccountSchema | null
  let new_user_api_url = `/community/auth`
  if (user?.email || (user?.phone && user.phone.length === 12)){
      var response = await axiosInstance.post(new_user_api_url, user)

      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          if (data && data?.login_token && data.refresh_token){
              // console.log("data", data)
              // axiosInstance.defaults.headers['Authorization'] = data.login_token
              localStorage.setItem('access_token', data.login_token)
              localStorage.setItem('refresh_token', data.refresh_token)
          }
          return data
      }
  }
  return null
}



const createNewCommunity = async (community: CommunitySchema, account: AccountSchema) => {
  if (community && account){
    let fetch_api_url = `/community/admin`;
    let adminCommunity = {...community, ...account}
    // console.log("adminCommunity", adminCommunity)
    var communityForm = objectToFormData(adminCommunity)
    try {
      let response = await axiosInstance.post(fetch_api_url, communityForm, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response && response.status === 201) {
        console.log("create success");
        return response.data
      }
    } catch (err) {
      console.log(err);
      return null
    }
  }
}


const updateUserData = async (authUser: AccountSchema, user_data: AccountSchema) => {
  let data: AccountSchema | null
  // phone = (phone.length === 10) ? phone :
  let update_user_api_url = `/community/account/${authUser.username}`
  let phone = authUser.phone

  let first_name = authUser.first_name
  if (user_data.hasOwnProperty('first_name') && user_data.first_name){
      first_name = user_data.first_name
  }

  let last_name = authUser.last_name
  if (user_data.hasOwnProperty('last_name') && user_data.last_name){
      last_name = user_data.last_name
  }

  let email = authUser.email
  if (user_data.hasOwnProperty('email') && user_data.email){
      email = user_data.email
  }

  if (phone && phone.length === 12 && user_data){
      // user_data = {...user_data, phone}
      user_data = {...user_data, first_name, last_name, email, phone}
      // console.log("update user_data 2", user_data)
      let response = await axiosInstance.put(update_user_api_url, user_data)
      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
  }
  return null
}


const getAuthUser = async ({phone, email, token_username}: AuthSchema) => {
  let data: AccountSchema | null
  let new_user_api_url = `/community/auth`

  let payload = {}

  if (phone){
    payload = {phone}
  } else if (email){
    payload = {email}
  } else if (token_username){
    payload = {token_username}
  }

  if (payload) {
    let response = await axiosInstance.post(new_user_api_url, payload)
    if (response){
        data = (response.data && response.data.body) ? response.data.body : response.data
        return data
    }
  }
  return null
}


const verifyUserOTP = async (otp_code: string, username: string) => {
  let data: AccountSchema | null
  let new_user_api_url = `/community/auth/verify`
  // console.log("otp_code, phone", otp_code, username)

  if (otp_code){
      let response = await axiosInstance.post(new_user_api_url, {otp_code, username})
      // console.log('response token_username', response)
      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
  }
  return null
}


const fetchCommunity = async (communityId: string) => {
  let data: CommunitySchema | null
  let community_api_url = `/community/${communityId}`
  if (communityId){
      let response = await axiosInstance.get(community_api_url)
      // console.log('response', response)
      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
  }
  return null
}


const signOut = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
}


// GET PREFERENCES
const getPreferences = async ({communityId}: {communityId: string}) => {
  let fetch_api_url = `/community/${communityId}/preferences`

  try{
    let response = await axiosInstance.get(fetch_api_url);
    if (response) {
      let data = response.data;
      return data;
    }
    return []
  }catch(err){
    console.log(err);
  }
}


// GET MEMBER REVIEWS
const getMemberReviews = async (username: string) => {
  let fetch_api_url = `/community/all/review/${username}`

  try {
    let response = await axiosInstance.get(fetch_api_url);
    if (response && response.data) {
      return response.data;
    }
  }catch(err){
    console.log(err);
  }
  return []
}


// CREATE PREFERENCES
const createPreference = async ({communityId, data}: PreferenceParameter) => {
  let fetch_api_url = `/community/${communityId}/preferences`

  try{
    let response = await axiosInstance.post(fetch_api_url,data);
    if (response) {
      console.log("create success");
    }
  } catch (err) {
    console.log(err);
  }
}


// UPDATE PREFERENCES 
const updatePreferences = async ({communityId, data}: PreferenceParameter) => {
  let fetch_api_url = `/community/${communityId}/preferences`
  try{
    let response = await axiosInstance.patch(fetch_api_url, data);
    if (response) {
      console.log("update success");
    }
  } catch (err) {
    console.log(err);
  }
}

// GET ALL KITCHENS
const getAllKitchens = async (communityId: string) => {
  let data: KitchenSpaceSchema[] = []
  if (communityId){
    let fetch_api_url = `/community/${communityId}/kitchens`;

    try{
      let response = await axiosInstance.get(fetch_api_url);
      if (response) {
        let data = response.data;
        return data;
      }
      
    } catch(err){
      console.log(err);
    }
  }
  return data
}


// GET ALL MEMBERSHIP PLANS
const getMembershipPlans = async (communityId: string) => {
  if (communityId){
    let fetch_api_url = `/community/${communityId}/plans`;

    try{
      let response = await axiosInstance.get(fetch_api_url);
      if (response) {
        let data = response.data;
        return data;
      }
      return []
    }catch(err){
      console.log(err);
    }
  }
}

const updateMembershipPlans = async (communityId:string, data: PlanSchema, planId: string) => {
  if (communityId){
    console.log(data);
    let fetch_api_url = `/community/${communityId}/plans/${planId}`;

    try{
      let response = await axiosInstance.put(fetch_api_url,data);
      if (response) {
        console.log("Plan update success!");
        let data = response.data;
        return data;
      }
    }catch(err){
      console.log(err);
    }
  }
}

const getMembershipPlan = async (communityId:string, planId: string) => {
  if (communityId){
    let fetch_api_url = `/community/${communityId}/plans/${planId}`;

    try{
      let response = await axiosInstance.get(fetch_api_url);
      if (response) {
        console.log("Plan get success!");
        let data = response.data;
        return data;
      }
    }catch(err){
      console.log(err);
    }
  }
}


const addMembershipPlans = async (communityId: string, plan: PlanSchema | {}) => {
  if (communityId){
    let fetch_api_url = `/community/${communityId}/plans`;
    try {
      let response = await axiosInstance.post(fetch_api_url, plan)
      if (response && response.status === 201) {
        console.log("create success");
        return response.data
      }
    } catch (err) {
      console.log(err);
      return null
    }
  }
}

const getMembershipRequirements = async (communityId: string) => {
  if (communityId){
    let fetch_api_url = `/community/${communityId}/requirements`;
    try{
      let response = await axiosInstance.get(fetch_api_url);
      if (response) {
        let data = response.data;
        console.log(data);
        return data;
      }
      return []
    }catch(err){
      console.log(err);
    }
  }
}

const deleteMembershipRequirement = async (communityId: string, reqId: string) => {
  if (communityId){
    let delete_api_url = `/community/${communityId}/requirements/${reqId}`;
    try {
      let response = await axiosInstance.delete(delete_api_url);
      if (response) {
        console.log("delete success");
      }
    } catch (error) {
      console.error("Error deleting kitchen:", error);
    }
  }
}

const addMembershipRequirement = async (communityId: string, requirement: RequirementSchema) => {
  if (communityId){
    let fetch_api_url = `/community/${communityId}/requirements`;
    try {
      console.log(requirement);
      let response = await axiosInstance.post(fetch_api_url, requirement)
      if (response && response.status === 201) {
        console.log("create success");
        return response.data
      }
    } catch (err) {
      console.log(err);
      return null
    }
  }
}



const getKitchenID = async ({communityId, id }: KitchenIdParameter) => {
  var data: KitchenSpaceSchema
  if (communityId && id){
    console.log("communityId", communityId)
    let fetch_api_url = `/community/${communityId}/kitchens/${id}`;

    let response = await axiosInstance.get(fetch_api_url);
    if (response) {
      data = response.data;
      return data;
    }
  }
  return {} as KitchenSpaceSchema;
}


const objectToFormData = (obj: { [key: string]: any }): FormData =>  {
  const formData = new FormData();
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      formData.append(key, value);
    }
  }
  return formData;
}


const addKitchen = async (communityId: string, kitchen: DSCommunitySchema | {}) => {
  if (communityId){
    let fetch_api_url = `/community/${communityId}/kitchens/`;
    var kitchenForm = objectToFormData(kitchen)
    try {
      let response = await axiosInstance.post(fetch_api_url, kitchenForm, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response && response.status === 201) {
        console.log("create success");
        return response.data
      }
    } catch (err) {
      console.log(err);
      return null
    }
  }
}


const addCommunityMember = async (communityId: string, membership: MembershipSchema | {}) => {
  if (communityId){
    let fetch_api_url = `/community/${communityId}/members`;
    try {
      let response = await axiosInstance.post(fetch_api_url, membership);
      if (response && response.status === 201) {
        console.log("create success");
        return response.data
      }
    } catch (err) {
      console.log(err);
      return null
    }
  }
}

const addCommunityMembers = async (communityId: string, memberships: MembershipSchema[] | {}) => {
  if (communityId){
    let fetch_api_url = `/community/${communityId}/members/many`;
    try {
      let response = await axiosInstance.post(fetch_api_url, memberships);
      if (response && response.status === 201) {
        console.log("create success");
        return response.data
      }
    } catch (err) {
      console.log(err);
      return null
    }
  }
}


const deleteKitchen = async ({communityId, id }: KitchenIdParameter) => {
  if (communityId){
    let delete_api_url = `/community/${communityId}/kitchens/${id}`;

    try {
      let response = await axiosInstance.delete(delete_api_url);
      if (response) {
        console.log("delete success");
      }
    } catch (error) {
      console.error("Error deleting kitchen:", error);
    }
  }
}


const updateKitchen = async ({communityId, spaceId, kitchenUpdates}: UpdateKitchenParameter) => {
  if (communityId){
    let update_api_url = `/community/${communityId}/kitchens/${spaceId}`;
    var kitchenForm = objectToFormData(kitchenUpdates)
    console.log(kitchenUpdates);
    try {
      let response = await axiosInstance.put(update_api_url, kitchenForm, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response) {
        console.log("update success");
        return response.data
      }
    } catch (error) {
      console.error(`Error was found while updating: ${error}`);
      return null
    }
  }
};


const updateKitchenOptions = async (communityId: string, spaceId: string, kitchenUpdates: KitchenOptionsType) => {
  if (communityId){
    let update_api_url = `/community/${communityId}/options/${spaceId}`
    // var kitchenForm = objectToFormData(kitchenUpdates)
    // console.log("updateKitchenOptions kitchenUpdates", kitchenUpdates);
    try {
      let response = await axiosInstance.post(update_api_url, kitchenUpdates);
      if (response) {
        console.log("update success");
        return response.data
      }
    } catch (error) {
      console.error(`Error was found while updating: ${error}`);
      return null
    }
  }
};


// GET ALL COMMUNITY INVENTORY
const getCommunityAllInventory = async (communityId: string) => {
  if (communityId){
    let fetch_api_url = `/community/${communityId}/inventory`;

    try{
      let response = await axiosInstance.get(fetch_api_url);
      if (response) {
        let data = response.data;
        return data;
      }
      return []
    }catch(err){
      console.log(err);
    }
  }
}


const addCommunityInventory = async (communityId: string, inventory: InventorySchema) => {
  if (communityId && inventory){
    var inventoryForm = objectToFormData(inventory)
    let post_api_url = `/community/${communityId}/inventory`;
    try {
      let response = await axiosInstance.post(post_api_url, inventoryForm, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response && response.status === 201) {
        console.log("create success");
        return response.data
      }
    } catch (err) {
      console.log(err);
      return null
    }
  }
}

// GET ALL ACTIVE MEMBERSHIPS
const getMemberships = async (communityId: string) => {
  var data: MembershipSchema[] = []
  if (communityId){
    let fetch_api_url = `/community/${communityId}/members`;

    try{
      let response = await axiosInstance.get(fetch_api_url);
      if (response) {
        data = response.data;
        return data;
      }
      
    }catch(err){
      console.log(err);
    }
  }
  return data
}


// GET INACTIVE MEMBERSHIPS
const getMembershipRequests = async (communityId: string) => {
  var data: MembershipSchema[] = []
  if (communityId){
    let fetch_api_url = `/community/${communityId}/members/requests`

    try{
      let response = await axiosInstance.get(fetch_api_url);
      if (response && response?.data) {
        data = response.data
      }
      
    } catch(err){
      console.log(err);
    }
  }
  return data
}



// CREATE NEW BOOKING
const createBooking = async (new_booking: BookingSchema) => {
  let { community_id } = new_booking
  let booking_api_url = `/community/${community_id}/bookings`


  if (community_id){
      let response = await axiosInstance.post(booking_api_url, new_booking)
      // console.log('createBooking response', response)
      if (response){
          return response.data
      }
  }
  return null
}


// FETCH MEMBER BOOKINGS
const fetchCommunityBookings = async (community_id: string) => {
  let data: BookingSchema[] = []
  let booking_api_url = `/community/${community_id}/bookings`
  if (community_id){
      let response = await axiosInstance.get(booking_api_url)
      // console.log('fetchCommunityBookings response', response)
      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
  }
  return data
}


// FETCH MEMBER BOOKINGS
const fetchBookingByID = async (communityId: string, bookingId: string) => {
  let data: BookingSchema | null
  let booking_api_url = `/community/${communityId}/bookings/${bookingId}`
  if (communityId && bookingId){
      let response = await axiosInstance.get(booking_api_url)
      // console.log('response', response)
      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
  }
  return null
}


// UPDATE MEMBER BOOKING
const updateBookingData = async (communityId: string, bookingId: string, bookingUpdate: BookingSchema) => {
  let data: BookingSchema | null
  if (communityId && bookingId){
      let booking_api_url = `/community/${communityId}/bookings/${bookingId}`
      let response = await axiosInstance.patch(booking_api_url, bookingUpdate)
      console.log('updateBooking response', response)
      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
  }
  return null
}

// FETCH MEMBER PROFILE
const fetchMemberByID = async (communityId: string, username: string) => {
  let data: MembershipSchema | null
  if (communityId && username){
      let booking_api_url = `/community/${communityId}/members/${username}`
      let response = await axiosInstance.get(booking_api_url)
      // console.log('response', response)
      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
  }
  return null
}

// UPDATE MEMBER PROFILE
const updateCommunityMembership = async (communityId: string, membershipUpdate: MembershipSchema) => {
  let data: MembershipSchema | null
  let username = membershipUpdate?.username
  if (communityId && username){
      let booking_api_url = `/community/${communityId}/members/${username}`
      delete membershipUpdate.username; 
      let response = await axiosInstance.patch(booking_api_url, membershipUpdate)
      console.log('updateCommunityMembership response', response)
      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
  }
  return null
}


// Fetch All Message Threads
const getAllMessages = async (communityId: string) => {
  let data: MessageSchema[]
  
  if (communityId){
      let api_url = `/community/${communityId}/messages`
      let response = await axiosInstance.get(api_url)
      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
  }
  return []
}

const fetchMemberCredentials = async (communityId: string, username: string) => {
  let data: CredentialSchema[] = []
  try {
    if (communityId){
    let fetch_api_url = `/community/${communityId}/documents/${username}`
    let response = await axiosInstance.get(fetch_api_url)
      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
    }
  }
  catch (err) {
    console.log(err)
  }
  return data
}


const addMembershipCredentials = async (communityId: string, username: string, cred: CredentialSchema) => {
  if (communityId && username && cred){
    let fetch_api_url = `/community/${communityId}/documents/${username}/verify/new`
    // let fetch_api_url = `/community/${communityId}/documents/${username}/upload`
    var credForm  = objectToFormData(cred)
    try {
      let response = await axiosInstance.post(fetch_api_url, credForm)
      if (response && response.status === 201) {
        console.log("create success");
        return response.data
      }
    } catch (err) {
      console.log(err);
      return null
    }
  }
}


// Fetch Messages in User Thread
const getUserMessages = async (communityId: string, username: string) => {
  let data: MessageSchema[] | null
  
  if (communityId){
      let api_url = `/community/${communityId}/messages/${username}`
      let response = await axiosInstance.get(api_url)
      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
  }
  return null
}


// Send Message to New or Existing User
const sendMessageToUser = async (communityId: string, username: string, content: string) => {
  let data: MessageSchema | null
  let api_url = `/community/${communityId}/messages/${username}`

  if (content){
      let response = await axiosInstance.post(api_url, {content})
      if (response){
          data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
  }
  return null
}


const generateAIResponse = async (communityId: string, content: string) => {
  let data: MessageSchema | null
  let ai_api_url = `/community/${communityId}/messages/ai`

  if (content){
      let response = await axiosInstance.post(ai_api_url, {content})
      // console.log('response token_username', response)
      if (response){
          // console.log("generateAIResponse response", response)
          data = (response.data && response.data.body) ? response.data.body : response.data
          // console.log("generateAIResponse data", data)
          return data
      }
  }
  return null
}


const fetchPaymentMethods = async (username: string) => {
  try {
    if (username){
    let fetch_api_url = `/community/account/${username}/paymethods`
    let response = await axiosInstance.get(fetch_api_url)
      if (response && response.data){
          let data = (response.data && response.data.body) ? response.data.body : response.data
          return data
      }
    }
  }
  catch (err) {
    console.log(err)
  }
  return []
  // return samplePaymentMethods
}



export {
  startNewAuth, getAuthUser, verifyUserOTP, signOut, updateUserData, 
  createNewCommunity, fetchCommunity, 
  addCommunityInventory, getCommunityAllInventory,
  getAllKitchens, getKitchenID, addKitchen, deleteKitchen, updateKitchen, updateKitchenOptions,
  addCommunityMember, fetchMemberByID,updateCommunityMembership,addCommunityMembers,
  getMemberships, getMembershipRequests, 
  fetchMemberCredentials, addMembershipCredentials, getMemberReviews,
  getMembershipPlans, updateMembershipPlans,addMembershipPlans, getMembershipPlan,
  getMembershipRequirements, addMembershipRequirement, deleteMembershipRequirement,
  getPreferences, createPreference, updatePreferences,
  createBooking, fetchCommunityBookings, fetchBookingByID, updateBookingData,
  generateAIResponse, fetchPaymentMethods, 
  sendMessageToUser, getUserMessages, getAllMessages
};
