import { Dialog } from "@mui/material";
import { AddKitchenForm, AddReservationForm, AddMemberForm, DeleteConfirmationForm, AddPlanForm, AddInventoryForm, AddCredentialForm, AddRequirementForm, NewMessageForm } from "./forms";
// import KitchenDetails from "../molecules/KitchenDetails";
import CredentialDetails from "../molecules/CredentialDetails"
import LoadingScreen from "../pages/shared/LoadingScreen"

import { deleteKitchen } from "../api/crud";
import { useAuthState } from "../contexts/authcontext";
import { useKitchenHook, useAddInventory } from "../hooks/kitchenhooks";

import { BookingSchema, DSCommunitySchema, PlanSchema, CredentialSchema, RequirementSchema, KitchenSpaceSchema, InventorySchema } from "../data/types";
// import { usePreferences } from "../contexts/preferencecontext";

type ModalProps = {
  isOpen: boolean;
};


type AddCredentialModalProps = ModalProps & {
  closeAppModal: () => void;
  handleSubmitCredential: (credential: CredentialSchema) => void
}

const AddCredentialModal: React.FC<AddCredentialModalProps> = (props) => {
  var { isOpen, handleSubmitCredential, closeAppModal } = props

  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-black opacity-60" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-credential-modal"
        className="z-50 top-20 bg-[#EEE] p-4 rounded-md shadow-md md:w-1/2"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddCredentialForm handleSubmitCredential={handleSubmitCredential} handleClose={closeAppModal} />
      </dialog>
    </div>
  );
}

type CredentialDetailsModalProps = ModalProps & {
  closeAppModal: () => void;
  credential: CredentialSchema
  handleUpdateCredential: (credential: CredentialSchema) => void
}

const CredentialDetailsModal: React.FC<CredentialDetailsModalProps> = (props) => {
  var { isOpen, credential, handleUpdateCredential, closeAppModal } = props

  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-black opacity-60" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="view-kitchen-modal"
        className="z-50 top-20 bg-[#EEE] p-4 rounded-md shadow-md w-auto"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <CredentialDetails credential={credential} handleUpdateCredential={handleUpdateCredential} />
      </dialog>
    </div>
  );
}

type AddKitchenModalProps = ModalProps & {
  closeAppModal: () => void;
};


const DefaultModal: React.FC<AddKitchenModalProps> = (props) => {
  var { isOpen, closeAppModal } = props
  const { isLoading, addKitchenSpace } =  useKitchenHook()
  if (isLoading){
    return (
      <LoadingScreen />
    )
  }
  

  const handleAddKitchen = (kitchen: KitchenSpaceSchema | {}) => {
    addKitchenSpace(kitchen)
    closeAppModal();
  }

  return (
    <div
      className={` inset-0 ${isOpen ? "block" : "hidden"} max-w-full`}
    >
      <div className="bg-black opacity-60 absolute inset-0 w-full max-h-full"></div>

      <dialog
        data-testid="add-kitchen-modal"
        className="z-50 bg-white p-4 rounded-md shadow-md md:w-[1158px] w-full max-w-screen-md"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddKitchenForm handleClose={closeAppModal} handleAddKitchen={handleAddKitchen} />
      </dialog>
    </div>
  );
};


const AddKitchenModal: React.FC<AddKitchenModalProps> = (props) => {
  var { isOpen, closeAppModal } = props
  const { isLoading, addKitchenSpace } =  useKitchenHook()
  if (isLoading){
    return (
      <LoadingScreen />
    )
  }
  const handleAddKitchen = (kitchen: DSCommunitySchema | {}) => {
    addKitchenSpace(kitchen)
    closeAppModal();
  }

  return (
    <div
      className={` inset-0 ${isOpen ? "block" : "hidden"} w-full `}
    >
      <div className="fixed inset-0 bg-black opacity-60" onClick={() => closeAppModal()}></div>
      <dialog
        data-testid="add-kitchen-modal"
        className="absolute z-50 top-20 p-4 rounded-md shadow-md md:w-[1158px} ml-14 md:mx-36"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddKitchenForm handleClose={closeAppModal} handleAddKitchen={handleAddKitchen} />
      </dialog>
    </div>
  );
};

type AddPlanModalProps = ModalProps & {
  kitchen: KitchenSpaceSchema
  closeAppModal: () => void;
}

const AddPlanModal: React.FC<AddPlanModalProps> = (props) => {
  var { isOpen, kitchen, closeAppModal } = props
  const { isLoading, addKitchenPlan} = useKitchenHook();
  if (isLoading){
    return (
      <LoadingScreen />
    )
  }
  const handleAddPlan = (plan: PlanSchema | {}) => {
    if(plan !== undefined){addKitchenPlan(plan)}

    closeAppModal();
  }

  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-black opacity-60" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-kitchen-modal"
        className="fixed z-50 top-20 bg-[#EEE] px-4 rounded-md shadow-md md:w-1/4 "
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddPlanForm kitchen={kitchen} handleClose={closeAppModal} handleAddPlan={handleAddPlan} />
      </dialog>
    </div>
  );
}


type AddRequirementProps = ModalProps & {
  closeAppModal: () => void;
}

const AddRequirementModal: React.FC<AddRequirementProps> = (props) => {
  var { isOpen, closeAppModal } = props
  const { isLoading, addKitchenRequirement} = useKitchenHook();
  if (isLoading){
    return (
      <LoadingScreen />
    )
  }
  const handleAddRequirement = (req: RequirementSchema| {}) => {
    if(req !== undefined){addKitchenRequirement(req)}
    closeAppModal();
  }

  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-black opacity-60" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-kitchen-modal"
        className="fixed z-50 top-20 bg-[#EEE] p-4 rounded-md shadow-md w-2/6"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddRequirementForm handleClose={closeAppModal} handleAddRequirement={handleAddRequirement} />
      </dialog>
    </div>
  );
}


type AddInventoryModalProps = ModalProps & {
  kitchen?: KitchenSpaceSchema
  closeAppModal: () => void;
}

const AddInventoryModal: React.FC<AddInventoryModalProps> = (props) => {
  var { isOpen, closeAppModal } = props
  const { isLoadingInventory, addInventory} = useAddInventory();
  if (isLoadingInventory){
    return (
      <LoadingScreen />
    )
  }
  const handleAddInventory = (inventory: InventorySchema) => {
    if(inventory !== undefined){addInventory(inventory)}

    closeAppModal();
  }

  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-black opacity-60" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-kitchen-modal"
        className="fixed z-50 top-20 bg-[#EEE] px-4 rounded-md shadow-md md:w-1/4 "
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddInventoryForm handleClose={closeAppModal} handleAddInventory={handleAddInventory} />
      </dialog>
    </div>
  );
}


type AddReservationModalProps = ModalProps & {
  closeAppModal: () => void;
  bookings: BookingSchema[];
}

const AddReservationModal: React.FC<AddReservationModalProps> = (props) => {
  var { isOpen, closeAppModal } = props
  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-black opacity-60" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-kitchen-modal"
        className="z-50 top-20 bg-[#EEE] p-4 rounded-md shadow-md w-fit"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddReservationForm isOpen={isOpen} handleClose={closeAppModal} />
      </dialog>
    </div>
  );
};



type AddMemberFormModalProps = ModalProps & {
  closeAppModal: () => void;
};

const AddMemberFormModal: React.FC<AddMemberFormModalProps> = (props) => {
  var { isOpen, closeAppModal } = props
  const handleAddMember = (kitchen: DSCommunitySchema | {}) => {
    closeAppModal();
  }

  return (
    <div
      className={` inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-black opacity-60" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-kitchen-modal"
        className="z-50 top-20 bg-back-400 p-4 rounded-md shadow-md md:w-[1158px}"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddMemberForm isOpen={isOpen} handleClose={closeAppModal} handleAddMember={handleAddMember} />
      </dialog>
    </div>
  );
};



type NewMessageModalProps = ModalProps & {
  closeAppModal: () => void;
};

const NewMessageModal: React.FC<NewMessageModalProps> = (props) => {
  var { isOpen, closeAppModal } = props
  


  return (
    <div
      className={` inset-0 ${isOpen ? "block" : "hidden"} flex items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-60 z-50" onClick={() => closeAppModal()}></div>

      <dialog
        className="absolute z-50 bg-slate-50 p-4 rounded-md shadow-md md:w-[1158px} top-10  "
        
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <NewMessageForm handleClose={closeAppModal} />
      </dialog>
    </div>
  );
};


// type UpdateKitchenModalProps = ModalProps & {
//   closeAppModal: () => void;
//   kitchen: DSCommunitySchema ;
// };
// const UpdateKitchenModal: React.FC<UpdateKitchenModalProps> = (props) => {
//   let { isOpen, closeAppModal } = props
//   const {communityId} = usePreferences();
//   const {kitchen} = props

//   const handleUpdateKitchen = async ( data: DSCommunitySchema | {}) => {
//     if(updateKitchen !== undefined &&  kitchen.id !== undefined && data !== undefined){
//       await updateKitchen({
//         communityId: communityId,// Provide the communityId
//         id: kitchen.id, // Provide the kitchen id
//         data: data, // Provide the updated data
//       });
//     }
//     closeAppModal();
//   }

//   return (
//     <div
//       className={` inset-0 ${isOpen ? "block" : "hidden"}`}
//     >
//       <div className="bg-black opacity-60 absolute inset-0 h-full"></div>

//       <dialog
//         data-testid="update-kitchen-modal"
//         className="z-50 bg-white p-4 rounded-md shadow-md absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-[1158px] h-fit"
//         open={isOpen}
//         onClose={() => closeAppModal()}
//       >
//         <UpdateKitchenForm handleClose={closeAppModal} handleUpdateKitchen={handleUpdateKitchen} />
//       </dialog>
//     </div>
//   );
// };

type DeleteConfirmationModalProps = ModalProps & {
  closeDeleteModal: () => void;
  kitchen: DSCommunitySchema;
};

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = (
  props
) => {
  let { isOpen, closeDeleteModal, kitchen } = props;
  var { authUser } = useAuthState();
  const handleConfirmation = () => {
    if (authUser && kitchen.id) {
      let { primary_community } = authUser;
      primary_community = primary_community ? primary_community : "";
      deleteKitchen({ communityId: primary_community, id: kitchen.id });
      closeDeleteModal(); // Close the modal
    }
  };
  return (
    <Dialog
      className=" mb-10"
      open={isOpen ? true : false}
      onClose={() => closeDeleteModal()}
    >
      <div className="gridLayout">
        <div className="gridLayout">
          <DeleteConfirmationForm
            handleClose={() => closeDeleteModal()}
            onDeleteConfirmation={handleConfirmation}
          />
        </div>
      </div>
    </Dialog>
  );
};

// type InformationModalProps = ModalProps & {
//   closeInfoModal: () => void;
//   kitchen: DSCommunitySchema;
// };

// const InformationModal: React.FC<InformationModalProps> = (props) => {
//   let { isOpen, closeInfoModal, kitchen } = props;
//   return (
//     <Dialog
//       fullWidth
//       open={isOpen ? true : false}
//       onClose={() => closeInfoModal()}
//     >
//       <div className="grid">
//         <div className="grid">
//           <KitchenDetails kitchen={kitchen} />
//         </div>
//       </div>
//     </Dialog>
//   );
// };

export { 
  AddKitchenModal, DeleteConfirmationModal, AddInventoryModal,
  NewMessageModal, AddReservationModal, AddMemberFormModal, DefaultModal, 
  AddPlanModal,AddRequirementModal, AddCredentialModal, CredentialDetailsModal 
};
