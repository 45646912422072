import React, { useState } from "react";
// import moment from 'moment'
import { AiOutlineLink } from "react-icons/ai";

import { FaSave } from "react-icons/fa";
import { MdDelete} from "react-icons/md";

import { ReactComponent as LocationIcon } from "../../icons/locationIcon.svg";
import LoadingScreen from "./LoadingScreen"
import OperatingHoursList from '../../molecules/OperatingHoursList'

import { useKitchenState } from "../../contexts/kitchencontext";
import { useKitchenHook, 
  // useLoadCurrentKitchen 
} from "../../hooks/kitchenhooks";

import { DSCommunitySchema, KitchenSpaceSchema, PlanSchema, KitchenOptionsType, selectedDaysType } from "../../data/types";
import { styleThousandCommas } from "../../utils/helpers";
import { states } from "../../utils/enums";



type EditKitchenDetailsoProps = {
  currentKitchen: KitchenSpaceSchema;
  plans: PlanSchema[] | undefined;
  // selectedDays: selectedDaysType
  // kitchenOptions: KitchenOptionsType
  // setSelectedDays: React.Dispatch<React.SetStateAction<selectedDaysType>>;
  // setKitchenOptions: React.Dispatch<React.SetStateAction<KitchenOptionsType>>;
  updateKitchenData: (kitchen: KitchenSpaceSchema, options: {kitchenOptions: KitchenOptionsType, selectedDays: selectedDaysType}) => void
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditKitchenDetails: React.FC<EditKitchenDetailsoProps> = (props) => {  
  var { currentKitchen, updateKitchenData, setIsEditing } = props;
  const [selectedState, setSelectedState] = useState<string>("");
  const [kitchen, setKitchen] = useState<DSCommunitySchema>(currentKitchen);
  const [selectedbgPhoto, setSelectedbgPhoto] = useState(kitchen.bgPhoto);
  const [selectedLogo, setSelectedLogo] = useState(kitchen.logo);

  

  const [kitchenOptions, setKitchenOptions] = useState<KitchenOptionsType>(currentKitchen?.kitchen_options ? currentKitchen.kitchen_options : {});

  // console.log("EditKitchenDetails kitchenOptions: ", kitchenOptions)
  const defaultSelectedDays = {
    sun: (kitchenOptions?.sun && kitchenOptions.sun?.open_time && kitchenOptions.sun?.close_time) ? true :  false,
    mon: (kitchenOptions?.mon && kitchenOptions.mon?.open_time && kitchenOptions.mon?.close_time) ? true :  false, 
    tue: (kitchenOptions?.tue && kitchenOptions.tue?.open_time && kitchenOptions.tue?.close_time) ? true :  false,
    wed: (kitchenOptions?.wed && kitchenOptions.wed?.open_time && kitchenOptions.wed?.close_time) ? true :  false, 
    thu: (kitchenOptions?.thu && kitchenOptions.thu?.open_time && kitchenOptions.thu?.close_time) ? true :  false, 
    fri: (kitchenOptions?.fri && kitchenOptions.fri?.open_time && kitchenOptions.fri?.close_time) ? true :  false, 
    sat: (kitchenOptions?.sat && kitchenOptions.sat?.open_time && kitchenOptions.sat?.close_time) ? true :  false
  }

  const [selectedDays, setSelectedDays] = useState<selectedDaysType>(defaultSelectedDays)

  var { plans, requirements} = useKitchenState();
  // var spaceId = kitchen?.id ? kitchen.id : "";
  const { isLoading, deleteKitchenRequirement } = useKitchenHook()

  const handleImageChange = (
    imgType: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      // Read the selected image file as a data URL
      const reader = new FileReader();
      reader.onload = (e) => {
        if (imgType === "logo") {
          setSelectedLogo(e.target?.result as string);
        } else if (imgType === "bgPhoto") {
          setSelectedbgPhoto(e.target?.result as string);
        }
      };
      reader.readAsDataURL(file);
    } else {
      if (imgType === "logo") {
        setSelectedLogo("");
      } else if (imgType === "bgPhoto") {
        setSelectedbgPhoto("");
      }
    }
    handleFileChange(event);
  };

  // const { reloadCurrentKitchen } = useLoadCurrentKitchen(spaceId)

  // const updateKitchenData = () => {
  //   // var planChanges = getChangedAttributes(currentPlan,newPlan)
  //   if (spaceId) {
  //     var changes = getChangedAttributes(currentKitchen, kitchen);
  //     // let updated = 
  //     updateKitchenSpace(spaceId, changes);
  //     // if (updated){
  //     //   reloadCurrentKitchen()
  //     // }
      
  //     if (selectedDays){
  //       let currentKitchenOptions = currentKitchen?.kitchen_options ? currentKitchen.kitchen_options : {}
  //       var operatingHrsChanges = getChangedAttributes(currentKitchenOptions, kitchenOptions);
  //     }
  //   }
  //   setIsEditing(false);
  // };

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedState(event.target.value);
    let newKitchen: DSCommunitySchema = {
      ...kitchen,
      state: [event.target.value],
    };
    setKitchen(newKitchen);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setKitchen((prevKitchen) => ({
      ...prevKitchen,
      [name]: value,
    }));
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (name && files && files.length > 0) {
      let file_data = files[0];
      setKitchen((prevKitchen) => ({
        ...prevKitchen,
        [name]: file_data,
      }));
    }
  };

  const handleRequirementDelete = (req_id: any) => {
    deleteKitchenRequirement(req_id)
  }

  if (isLoading){
    return (
      <LoadingScreen />
    )
  } else{
  return (
    <div className="flex mx-auto w-full p-4 h-[900px] sm:h-screen overflow-y-scroll bg-[#D9D9D9]">
      <div className="font-raleway flex flex-col w-full relative items-start p-4">
        <>
          <div className="flex flex-row text-2xl text-[#000] ">
            {/* <img className='h-16 relative my-auto mx-2' alt={kitchen.logo} src={kitchen.logo} />  */}
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange("logo", e)}
              className="hidden"
              id="image-input-logo"
              name="logo"
            />

            <img
              id="image-input-logo"
              src={selectedLogo}
              alt="Kitchen Logo"
              className="h-16 relative my-auto mx-2 max-w-sm max-h-32"
            />
            <label
              htmlFor="image-input-logo"
              className="text-xs rounded-md cursor-pointer my-auto bg-[#bbb] hover:bg-[#999] border-0 text-primary-300 hover:text-primary-200 p-1 px-2"
            >
              Update Logo
            </label>
          </div>

          <div className="flex flex-row font-raleway w-full text-md mx-2 text-primary-200">
            <LocationIcon className="w-4 h-4 my-auto" /> &nbsp;
            <div className="flex flex-row justify ">
              <input
                value={kitchen.address}
                placeholder="Address"
                className="formInput text-slate-700 h-10 text-sm w-max mx-1 bg-inherit"
                name="address"
                onChange={handleInputChange}
              />
              <input
                value={kitchen.city}
                placeholder="City"
                className="formInput text-slate-700 h-10 text-sm w-max mx-1 bg-inherit"
                name="city"
                onChange={handleInputChange}
              />
              {/* <input
                value={kitchen.county}
                placeholder="County"
                className="formInput text-slate-700 h-10 text-sm w-max mx-1 bg-inherit"
                name="county"
                onChange={handleInputChange}
              /> */}

              <select
                value={selectedState ? selectedState : kitchen.state}
                onChange={handleStateChange}
                className="formInput text-slate-700 h-10 text-sm w-max mx-1 bg-inherit"
              >
                <option value="">Select a state</option>
                {Object.values(states).map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>

              <input
                value={kitchen.zipcode}
                placeholder="Zipcode"
                className="formInput text-slate-700 h-10 text-sm w-max mx-1 bg-inherit"
                name="zipcode"
                onChange={handleInputChange}
              />
            </div>
            {/* <span className='flex flex-row text-sm text-[#013847]  hover:text-gray-400 cursor-pointer ml-4' onClick={() => (currentKitchen && kitchen.website) ? openInNewTab(kitchen.website) : null}><AiOutlineLink className='mx-1' />Visit Website</span> */}
          </div>

          <p className="flex flex-row font-raleway w-full text-md mx-2 text-primary-200">
            <AiOutlineLink className="mx-1 my-auto" />
            <input
              value={kitchen.website}
              placeholder="Website"
              className="formInput h-10 text-sm w-3/12 mx-1 my-1 bg-inherit"
              name="website"
              onChange={handleInputChange}
            />
          </p>

          <div className="flex flex-row mx-2 mt-2 w-full">
            {/* <p className="flex flex-row text-sm mx-2">
                            {
                              (memberships && memberships.length !== 0 && memberships.length < 4) ? (Array.from({ length: memberships.length }).map((_, i)=> {
                                return (
                                  <img className='relative h-5 rounded-full -ml-1' alt={account_avatar} src={account_avatar} />
                                )
                              })) : (<img className='relative h-5 rounded-full' alt={account_avatar} src={account_avatar} />)
                            }
                             
                             &nbsp; {(memberships && memberships.length) ? (memberships.length) : "0"} 
                             {
                                memberships && memberships.length === 1 ? ' Member' : ' Members'
                             }
                          </p> */}

            <div className="flex flex-row ml-auto">
              <button
                className="flex flex-row justify-evenly py-1 px-2 py-auto text-sm z-0 bg-red-900 hover:bg-slate-500 border-0 text-[#eee] rounded-md mx-1"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>

              <button
                className="flex flex-row justify-evenly py-1 px-2 py-auto text-sm z-0 bg-primary-200 hover:bg-[#999] border-0 text-[#eee] rounded-md mx-1"
                onClick={() => updateKitchenData(kitchen, {kitchenOptions, selectedDays})}
              >
                <FaSave className="m-auto" /> &nbsp;
                <p className="m-auto">Save</p>
              </button>
            </div>
          </div>

          <div className="flex flex-row justify-evenly w-full h-[300px] my-2">
            {currentKitchen?.bgPhoto ? (
              <div className="flex flex-col items-center">
                {/* <img className='object-cover w-full' src={currentKitchen?.bgPhoto} alt={currentKitchen?.name} /> */}
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange("bgPhoto", e)}
                  className="hidden"
                  id="image-input-bg-photo"
                  name="bgPhoto"
                />

                <label
                  htmlFor="image-input-bg-photo"
                  className="text-xs rounded-md cursor-pointer bg-[#ccc] hover:bg-[#aaa] hover:text-primary-200 text-primary-300 p-1"
                >
                  Update Background Photo
                  <img
                    id="image-input-bg-photo"
                    className="object-cover w-screen max-h-[300px] mb-1 rounded-sm"
                    alt="KitchenBG"
                    src={selectedbgPhoto}
                  />
                </label>
              </div>
            ) : (
              <>
                <div className="h-[280px] w-9/12 rounded-md m-1  bg-slate-400"></div>
                <div className="flex flex-col h-[280px] w-3/12 rounded-md m-1">
                  <div className="h-1/2 w-auto rounded-md m-1  bg-slate-400"></div>
                  <div className="h-1/2 w-auto  rounded-md m-1 bg-slate-400"></div>
                </div>
              </>
            )}
          </div>

          <div className="flex flex-row justify-between w-full">
            <div className="grid sm:grid-cols-2 grid-cols-1 w-full grid-flow-row-dense">
              <div className="col-span-3 w-full">
                <div className="flex flex-col mt-4 font-raleway">
                  <p className=" font-bold text-[#000000]  text-lg mt-4">
                    Description
                  </p>
                  <textarea
                    value={kitchen.description}
                    placeholder="Description"
                    className="formInput m-1 w-2/3 my-auto bg-inherit"
                    name="description"
                    onChange={handleInputChange}
                  />
                </div>

                <div className="flex flex-col mt-4">
                  <p className="font-raleway font-bold text-[#000] text-lg">
                    Membership Plans
                  </p>
                  {/* <p className='font-raleway font-normal text-[#000000]  text-md'> */}
                  {plans ? (
                    <div className="flex flex-row justify-items-start font-normal">
                      {plans.map((plan, i) => (
                        <div key={i} className="flex flex-col font-raleway item w-full text-md mr-auto border-gray-400/60 border-r-2 px-2">
                          {/* <div> */}
                            <span className="font-medium mx-auto">
                              {plan.plan_name && plan.plan_name.toUpperCase()}
                            </span>
                            <div className="text-[#555]/80 mb-auto mx-auto">
                                ${styleThousandCommas(plan?.plan_rate ? plan.plan_rate : "")} ({plan.plan_recurrence})
                            </div>
                            <MdDelete className="text-red-800 cursor-pointer mx-auto" />
                          {/* </div> */}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/40 ">
                      No plans to show yet
                    </div>
                  )}
                </div>
                <div className="flex flex-col mt-4">
                  <p className="font-raleway font-bold text-[#000] text-lg">
                    Membership Requirements
                  </p>
                  {requirements ? (
                    <div className="flex flex-row justify-items-start font-normal">
                      {requirements ? (
                        <div className="flex flex-row justify-items-start font-normal">
                          {requirements.map((requirement, i) => (
                            <div
                              key={requirement.name}
                              className="font-raleway text-md mr-6"
                            >
                              <div className="flex flex-row">
                                <span className="font-medium m-auto text-[#555]/80">
                                  {requirement.name &&
                                    requirement.name.toUpperCase()}
                                </span>
                                <div className="m-auto mr-2">{requirement.description}</div>
                                <MdDelete onClick={() => handleRequirementDelete(requirement.id)} className="text-red-800 cursor-pointer" />
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/40 ">
                      No requirements to show yet
                    </div>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/40 ">
                      No plans to show yet
                    </div>
                  )}
                </div>

                <div className="flex flex-col mt-4">
                  <p className="font-raleway font-bold text-[#000]  text-lg">
                    Kitchen Size
                  </p>
                  <div className="flex flex-row">
                    <input
                      value={kitchen.size}
                      placeholder="Kitchen Size"
                      className="formInput m-1 w-max my-auto bg-inherit"
                      name="size"
                      onChange={handleInputChange}
                    />
                    sqft
                  </div>
                </div>

                <div className="flex flex-col mt-4">
                  <p className="font-raleway font-bold text-[#000] text-lg">
                    Kitchen Capacity
                  </p>
                  <div className="flex flex-row">
                    <input
                      value={kitchen.capacity}
                      placeholder="Kitchen Capacity"
                      className="formInput m-1 w-max my-auto bg-inherit"
                      name="capacity"
                      onChange={handleInputChange}
                    />
                    {kitchen.capacity === "1" ? " Person" : " People"}
                  </div>
                </div>
              </div>
            </div>
            <OperatingHoursList 
              kitchenOptions={kitchenOptions} 
              setKitchenOptions={setKitchenOptions} 
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
            />
          </div>
        </>
      </div>
    </div>
  );
  }
};

export default EditKitchenDetails;
