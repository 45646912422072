import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { states } from "../utils/enums";
// import moment from "moment";

// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import InfoIcon from "@mui/icons-material/Info";
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import CloseIcon from "@mui/icons-material/Close";

// import CheckIcon from '@mui/icons-material/Check';
// import EditIcon from '@mui/icons-material/Edit';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

// import { DeleteConfirmationModal } from "../components/AppModal";
// import { useUpdateMembership } from '../contexts/memberhooks'
import { useKitchenState } from "../contexts/kitchencontext";
import { useMemberState } from "../contexts/membercontext";
import { useAddMembership } from "../hooks/memberhooks";

// import { membershipStatuses } from "../utils/enums";
import { styleThousandCommas } from "../utils/helpers";
import { MembershipSchema } from "../data/types";

// const colors: {[key: string]: string} = statusColors();
// const account_avatar = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`

// const circle = (status: string) => {
//   return (
//     <div
//       className={`z-0 w-2 h-2 animate-pulse ${colors[status]} rounded-full`}
//     ></div>
//   );
// };

type EditMemberRecordProps = {
  cancelEditMember: () => void;
  closeAppModal: () => void;
};

const EditMultipleMembers: React.FC<EditMemberRecordProps> = ({
  closeAppModal, cancelEditMember,
}) => {
  const { plans } = useKitchenState();
  const { emails, setEmails
    // , setAddingMembers 
    } = useMemberState();
  // const [selectedState, setSelectedState] = useState<string>("");
  const [memberChanges, setMemberChanges] = useState<Record<string, MembershipSchema>>({});
  // const [memberChanges, setMemberChanges] = useState<MembershipSchema[]>([]);
  var {
    addMembership,
    // isLoadingMembership,
    // isAddMembershipError,
    // isAddMembershipSuccess,
  } = useAddMembership();

  const handleAddNewMembership = async () => {
    const membershipData = Object.values(memberChanges).map((changes,index) => {
      return {
        email: Object.keys(memberChanges)[index] || "",
        last_name: changes.last_name || "",
        plan_recurrence: changes.plan_recurrence || "hourly",
        state: changes.state || "",
        payment_method: changes.payment_method || "",
        manually_approve: changes.manually_approve || false,
        // status: changes.status || "pending",
        plan_id: changes.plan_id || "",
        team_name: changes.team_name || "",
        phone: changes.phone || "",
        plan_rate: changes.plan_rate || 0,
        plan_description: changes.plan_description || "",
        // is_admin: changes.is_admin || false,
        first_name: changes.first_name || "",
        plan_name: changes.plan_name || "",
        member_balance: changes.member_balance || 0
      };
    });
    console.log('membershipData', membershipData);
    if (membershipData){
      addMembership(membershipData);
    
    // if (isAddMembershipSuccess || added) {
      setMemberChanges({});
      setEmails([])
      // setAddingMembers(false);
      closeAppModal()
      // cancelEditMember()
      // navigate('/kitchens/members')
    // }
    }
  };

  const handleUpdateMembership = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    email: string
  ) => {
    const { name, value } = e.target;
    setMemberChanges((prevData) => ({
      ...prevData,
      [email]: {
        ...prevData[email],
        [name]: value,
      },
    }));
  };

  const handlePlanChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    email: string
  ) => {
    const { value: planId } = event.target;
    const plan = plans?.find((plan) => plan.id === planId);
    if (plan) {
      setMemberChanges((prevData) => ({
        ...prevData,
        [email]: {
          ...prevData[email],
          plan_id: plan.id,
          plan_name: plan.plan_name,
          plan_description: plan.plan_description,
          plan_rate: plan.plan_rate,
          plan_recurrence: plan.plan_recurrence,
          plan_max_hours: plan.plan_max_hours,
          member_balance: plan.plan_max_hours
        },
      }));
    }
  };

  const handleStateChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    email: string
  ) => {
    const { value } = e.target;
    if (value){
      // setSelectedState(value)
      setMemberChanges((prevData) => ({
        ...prevData,
        [email]: {
          ...prevData[email],
          state: value,
        },
      }));
    }
  };

  // const handleStatusChange = (
  //   event: React.ChangeEvent<HTMLSelectElement>,
  //   email: string
  // ) => {
  //   const status = event.target.value;
  //   setMemberChanges((prevData) => ({
  //     ...prevData,
  //     [email]: {
  //       ...prevData[email],
  //       status
  //     }
  //   }));
  // };



  const handleCancelEditMember = () => {
    setMemberChanges({});
    setEmails([])
    closeAppModal()
    // cancelEditMember();
  };

  return (
    <div className="flex flex-col">
      <table className="table-auto my-3">
        <thead className="bg-secondary h-10 border-2 border-black font-open-sans">
          <tr>
            <th className="headItem border-r-0 border-l-0">Full Name</th>

            <th className="headItem border-r-0 border-l-0">Email Address</th>

            <th className="headItem border-r-0 border-l-0">Team</th>

            <th className="headItem border-r-0 border-l-0">Plan</th>
            <th className="headItem border-r-0 border-l-0">State</th>
            <th className="headItem border-r-0 border-l-0">Zipcode</th>
          </tr>
        </thead>

        <tbody className="bg-white">
          {emails?.map((email: string, index: number) => (
            <tr
              key={index}
              className="font-open-sans border-x-2 border-2 border-stone-500"
            >
              {/* Render checkboxes */}
              {/* Render member fields */}
              <td className="recordItem w-full flex flex-row border-r-2">
                {/* This provides a test if the edit button has been checked and if so to open a text field to change the value. */}
                <input
                  value={memberChanges[email]?.first_name || ""}
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  onChange={(e) => handleUpdateMembership(e, email)}
                  className="formInput bg-transparent p-2 rounded-md text-[#000000] text-start w-auto h-full m-1"
                />
                <input
                  value={memberChanges[email]?.last_name || ""}
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  onChange={(e) => handleUpdateMembership(e, email)}
                  className="formInput bg-transparent p-2 rounded-md text-[#000000] text-start w-auto h-full m-1"
                />
              </td>
              <td className="border-r-2 border-black">
                {email}
                {/* Add other fields similarly */}
              </td>
              <td className="border-r-2 border-black">
                <input
                  value={memberChanges[email]?.team_name || ""}
                  type="text"
                  name="team_name"
                  placeholder="Team"
                  onChange={(e) => handleUpdateMembership(e, email)}
                  className="formInput bg-transparent p-2 rounded-md text-[#000000] text-start w-auto h-full m-1 border-0"
                />
                {/* Add other fields similarly */}
              </td>
              {/* <td>
                <select
                  value={memberChanges[email]?.status || ""}
                  onChange={(e) => handleStatusChange(e, email)}
                  className="formInput h-full w-full m-1 bg-transparent p-2 rounded-md mt-1 text-center text-black"
                >
                  <option value="">-- Change Status --</option>
                  {membershipStatuses.map((status) => (
                    <option key={status} value={status}>
                      {status.replace(/\b\w/g, (status) => status.toUpperCase())}
                    </option>
                  ))}
                </select>
              </td> */}
              {/* Render plan dropdown */}
              {/* Render status dropdown */}
              {/* Render submit and cancel buttons */}
              <td>
                <select
                  value={memberChanges[email]?.plan_id || ""}
                  onChange={(e) => handlePlanChange(e, email)}
                  className="formInput w-full h-full m-1 bg-transparent p-2 rounded-md mt-1 text-center text-black mx-auto border-0"
                >
                  <option value="">-- Select a plan --</option>
                  {plans?.map((plan) => (
                    <option key={plan.id} value={plan.id}>
                      {`$${styleThousandCommas(
                        plan.plan_rate ? plan.plan_rate.toString() : ""
                      )} (${plan.plan_recurrence})`}
                    </option>
                  ))}
                </select>
              </td>
              <td className="border-r-2 border-black">
                <select
                  name="state"
                  value={memberChanges[email]?.state || ""}
                  onChange={e => handleStateChange(e, email)}
                  className="formInput w-full h-full m-1 bg-transparent p-2 rounded-md mt-1 text-center text-black mx-auto border-0"
                >
                  <option value="">Select a state</option>
                  {Object.values(states).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>    
              </td>
              <td className="border-r-2 border-black">
                <input
                    value={memberChanges[email]?.zipcode || ""}
                    type="text"
                    name="zipcode"
                    placeholder="Zipcode"
                    onChange={(e) => handleUpdateMembership(e, email)}
                    className="formInput bg-transparent p-2 rounded-md text-[#000000] text-start w-auto h-full m-1 border-0"
                />
              </td>
              {/* <td>
                <select
                  value={memberChanges[email]?.status || ""}
                  onChange={(e) => handleStatusChange(e, email)}
                  className="formInput h-full w-full m-1 bg-transparent p-2 rounded-md mt-1 text-center text-black"
                >
                  <option value="">-- Change Status --</option>
                  {membershipStatuses.map((status) => (
                    <option key={status} value={status}>
                      {status.replace(/\b\w/g, (status) => status.toUpperCase())}
                    </option>
                  ))}
                </select>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <div className=" flex flex-row gap-x-5 justify-self-center place-self-center">
        <button
          className="border-0  bg-back-200 text-[#D9D9D9] px-3 py-1 rounded-md"
          onClick={() => handleCancelEditMember()}
        >
          Cancel
        </button>

        <button
          className="border-0  bg-primary-300 text-[#D9D9D9] px-3 py-1 rounded-md"
          onClick={() => handleAddNewMembership()}
        >
          Continue
        </button>
      </div>{" "}
    </div>
  );
};

export default EditMultipleMembers;
