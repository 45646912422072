import { useEffect } from "react";

import ChatWindow from '../../molecules/chatWindow'
import UserListWindow from '../../molecules/chatUsers'

import { useMemberState } from '../../contexts/membercontext'


const Messages = () => {
  var { currentChatMember, 
    // loadMessages 
  } = useMemberState()
  // const loadCommunityMessages = async () => {
  //   if(loadMessages !== undefined){loadMessages()}
  // }
  
  useEffect(() => {
    document.title = `HomeCook | Messages ${(currentChatMember && currentChatMember === 'chatbot') ? `(${currentChatMember?.replace(/\w/, c => c?.toUpperCase())})` : (currentChatMember ? `(${currentChatMember})` : '')}`
    // loadCommunityMessages()
    // eslint-disable-next-line
  }, [currentChatMember])

      return (
         <div className="mx-auto w-full flex font-open-sans h-[900px] sm:h-screen overflow-y-scroll bg-[#D9D9D9]">
            <div className="flex flex-col sm:flex-row w-10/12 justify-center m-auto ">
                {/* <div className="w-auto sm:w-[400px]  bg-[#fff] rounded-md"> */}
                <div className={`${currentChatMember ? 'hidden sm:block': 'block'} w-auto sm:w-[400px] bg-[#fff] rounded-md`}>
                    <UserListWindow  />
                </div>

                {/* <div className="hidden sm:flex m-2 w-auto  bg-[#fff] rounded-md"> */}
                <div className={`${currentChatMember ? 'flex flex-col': 'hidden sm:block'} m-2 w-auto  bg-[#fff] rounded-md `}>
                    <ChatWindow />
                </div>
            </div>
             

        </div>
      )
}







export default Messages
