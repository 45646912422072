import {
    // FiEdit,
    FiChevronDown,
    // FiTrash,
    // FiShare,
    // FiPlusSquare,
  } from "react-icons/fi";
  import { motion } from "framer-motion";
  import React, { useState } from "react";
// import { duration } from "moment";
  // import { IconType } from "react-icons";
  
  interface OptionType<T = any> {
    text: string;
    action: (arg?: T) => void;
  }
  
  interface StaggeredDropDownProps {
    options: OptionType[];
    title: string;
    buttonStyle: string;
    bodyStyle:string;
  }

  const StaggeredDropDown: React.FC<StaggeredDropDownProps> = ({options, title, buttonStyle, bodyStyle}) => {
    const [open, setOpen] = useState(false);
    // console.log(options)
    const handleOptionClick = (text:any,action:any) => {
        action(text)
        setOpen(false);
    };
  
    return (
      <div className={bodyStyle}>
        <motion.div animate={open ? "open" : "closed"} className="relative" style={{ cursor: 'pointer' }} >
          <button
            onClick={() => setOpen((pv) => !pv)}
            className={buttonStyle}
          >
            <span className="font-medium text-base">{title}</span>
            <motion.span variants={iconVariants}>
              <FiChevronDown />
            </motion.span>
          </button>
  
          <motion.ul
            initial={wrapperVariants.closed}
            variants={wrapperVariants}
            style={{ originY: "top", translateX: "-50%" }}
            className="flex flex-col gap-2 p-2 rounded-lg bg-white shadow-xl absolute top-[120%] left-[50%] w-48 overflow-hidden cursor-pointer"
          >
            {Object.values(options).map((option, index) => (
            <Option key={index} setOpen={handleOptionClick} action={option.action} text={option.text} />
          ))}
          </motion.ul>
        </motion.div>
      </div>
    );
  };
  
  const Option = ({ text, setOpen, action }: any) => {
   
    return (
      <motion.li
        variants={itemVariants}
        onClick={() => setOpen(text,action)}
        className="flex items-center gap-2 w-full p-2 text-xs font-medium whitespace-nowrap rounded-md hover:bg-primary-400 text-slate-700 hover:text-white transition-colors cursor-pointer"
      >
        <motion.span variants={actionIconVariants}></motion.span>
        <span>{text}</span>
      </motion.li>
    );
  };
  
  export default StaggeredDropDown;
  
  const wrapperVariants = {
    open: {
      scaleY: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
        // duration: 0.1
      },
    },
    closed: {
      scaleY: 0,
      transition: {
        when: "afterChildren",
        staggerChildren: 0.1,
        // duration: 0.1
      },
    },
  };
  
  const iconVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
  };
  
  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        when: "beforeChildren",
        // duration: 0.1
      },
    },
    closed: {
      opacity: 0,
      y: -15,
      transition: {
        when: "afterChildren",
        // duration: 0.1
      },
    },
  };
  
  const actionIconVariants = {
    open: { scale: 1, y: 0 },
    closed: { scale: 0, y: -7 },
  };