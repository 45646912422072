import React from 'react'
// import { useNavigate } from 'react-router-dom';
// import moment from 'moment'

import { InventorySchema } from '../data/types'

type  InventoryCardProps = {
  key: number
  inventory: InventorySchema
}
const InventoryCard: React.FC<InventoryCardProps> = ({inventory}) => {

  return (
    <div className='flex flex-col  bg-white hover:bg-slate-50 aspect-square h-[250px] w-[250px] cursor-pointer shadow-md justify-evenly items-center rounded-md p-2 m-2'>
      {/* <div className='h-[50px] w-[80%] bg-[#eee] rounded-md m-1'></div> */}
      <div className='text-center text-md font-bold text-[#013847]'>{inventory.item_name}</div>
      
      {/* <div className='text-center text-[14px]'>{inventory.location}</div> */}
      
      <div className='flex flex-row justify-evenly w-full text-[12px]'>
          <img src={inventory.image_url} alt={inventory.item_name} className='h-[90px] w-[100px] m-2' />

          <div className='flex flex-col justify-evenly w-full text-[14px] font-bold '>
              <div className='flex flex-row'>
                <div className={`text-7xl rounded-md text-[#085614] font-normal`}>
                  {inventory?.unit_price  && inventory.unit_price.toString().split(".")[0]}
                </div>
                <div className='flex flex-col'>
                  <div className={`text-3xl text-[#085614] font-normal`}>{inventory.unit_price && inventory.unit_price.toString().split(".")[1]}</div>
                  <div  className={`text-lg text-[#000] ml-auto`}>{inventory.measure_unit}</div>
                </div>
              </div>
              
              <div className={`text-[#013847] text-md px-2 font-normal`}>Qty: {inventory.total_quantity} {inventory.measure_unit}</div>
          </div>
          
      </div>
      
      
      
    </div>
    )
}


export default InventoryCard