import React, { useState, useEffect } from "react";
import homecook_logo from '../../../assets/img/homecook_logo.png'
import { AccountSchema, LoginProps } from "../../../data/types";


// const homecook_logo = "homecookapp.com.s3-website-us-east-1.amazonaws.com/static/media/homecook_logo.1ca10013af71605db8e8.png"

const EmailLogin: React.FC<LoginProps> = props => {
    useEffect(()=> {
        document.title = 'Sign In | Dashboard'
     }, [])

  
    const [email, updateEmail] = useState('')
    let { 
        registerUser, 
        toggleSignUp,
        // togglePhone 
    }: LoginProps = props

    const isValidEmail = (email: string): boolean => {
        const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // Regular expression for a simple email validation
        return emailRegex.test(email) // Testing the email against the regex
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        if (field === 'email' && (e.target.value || e.target.value  === "")){
                e.preventDefault()
                updateEmail(e.target.value.toLowerCase())
            }
    }

    const goToOTP = async () => {
        if (email && registerUser !== undefined){
            // let phone = ""
            var user_to_register: AccountSchema = {email}
            // console.log("email", email)
            registerUser(user_to_register)
        }
    }

    return (
        <div className='w-full mx-auto p-auto h-screen overflow-y-scroll bg-[#D9D9D9] md:relative flex justify-evenly items-start pt-56 md:pt-0 md:top-11'>
            <div className="relative mx-auto">
                <div className="flex flex-col justify-evenly align-middle w-[320px] mx-auto">
                    <img src={ homecook_logo} alt="HomeCook" className='w-[100px] mx-auto cursor-pointer' onClick={()=> null} />
                    <h4 className=" text-center m-[1%] text-[20px] text-[#2D3748]">Welcome Back!</h4>
                    <p className="text-[16px] text-[#a9b2c0] text-center">Sign in with your email.</p>
                    <div className="flex-row w-[100%]">
                        <input placeholder=""  type="email" value={email} className='h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000] text-start' onChange={e => handleChange(e, 'email')} onKeyDown={e => (e.key === 'Enter' && email) ? goToOTP() : null } />
                    </div>

                    

                    <button className="bg-[#5DB6CE] text-[#013847]  m-3 border-0 font-open-sans buttonsPrimary h-10" disabled={!email && !isValidEmail(email)}  onClick={() => goToOTP()}>ENTER </button>

                    {/* <p className="text-[12px] text-[#013847] dark:text-[#E1E1E1] text-center font-extralight cursor-pointer hover:text-[#5DB6CE]" onClick={togglePhone}>Sign in with phone</p> */}
                    <p className="text-[12px] text-[#a9b2c0] text-center font-extralight cursor-pointer hover:text-[#5DB6CE]" onClick={toggleSignUp}><span className="text-[#013847]">Don't have an account? </span>Sign up</p>

                </div>
           </div>
        </div>
    )
}






export default EmailLogin
