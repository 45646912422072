import React from "react";
import { useNavigate } from "react-router-dom";

import { DSCommunitySchema } from "../data/types";
import { useMemberState } from "../contexts/membercontext";

type KitchenRecordProps = {
  kitchen: DSCommunitySchema;
};

const KitchenRecord: React.FC<KitchenRecordProps> = (props) => {
  var navigate = useNavigate();

  var { memberships } = useMemberState()

  var { kitchen } = props;

  const updateOpenView = () => {
    navigate(`/kitchens/${kitchen.id}`);
  };


  return kitchen ? (
    <div className="font-open-sans grid grid-cols-1 w-96 h-auto bg-white rounded-xl p-2 border-back-300 border-2" >
    <div className="">
      <img
        src={kitchen.bgPhoto}
        alt="Background"
        className="col-span-1 w-96 h-52 rounded-2xl shadow-lg position-absolute object-cover"
      />
    </div>
    <h1 className="text-2xl m-1 text-center">
      {kitchen.name}
    </h1>
    <div className="contain text-center">
      {/* <p className="text-gray-600 text-md text-wrap m-2">
        {kitchen.description
          ? kitchen.description.length > 100
            ? kitchen.description.substring(0, 100) + "..."
            : kitchen.description
          : null}
      </p> */}

      <p className="text-gray-400 text-md m-2">
        {/* {currentKitchen.location} {currentKitchen.country} */}
        {kitchen.address ? `${kitchen.address}, `: ``} 
        {kitchen.city ? `${kitchen.city}, `: ``} 
        {/* {kitchen.county ? `${kitchen.county}, `: ``}   */}
        {kitchen.state ? `${kitchen.state} `: ``}  
        {kitchen.zipcode} 
        {/* {kitchen.country}  */}
        
      </p>

      <p className="text-[#013847] text-md m-2 text-center">
        {(memberships && memberships.length) ? (memberships.length) : "0"} Members
      </p>
    </div>
    <button className="buttonsPrimary h-20 text-lg rounded-xl text-gray-200 font-bold" onClick={updateOpenView}>More Information</button>
  </div>
  ) : null;
};

export default KitchenRecord;
