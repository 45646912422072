import { useState, useEffect } from "react";

import SearchBox from '../../components/SearchBox'
import KitchenList from '../../components/KitchenList'
import { AddKitchenModal } from '../../components/AppModal'

import { useMemberState } from "../../contexts/membercontext";
import { useKitchenState } from "../../contexts/kitchencontext";


const KitchensPage = () => {
  const [add_kitchen, toggleApplication] = useState(false)
  var { memberships } = useMemberState()
  var { kitchens } = useKitchenState()

  useEffect(()=> {
    document.title = 'HomeCook | Your Kitchens'
    // eslint-disable-next-line
  }, [memberships])


  const closeAppModal = () => {
      toggleApplication(false)
  }

  return (
      <>
        <div className="md:p-[1%] w-full bg-back-400 pt-16 md:pt-16">
            <h1 className="flex justify-center text-xl text-[#013847]/40">Your Kitchens</h1>
            <div className="flex" style={{color: '#888'}}>
                {(kitchens && kitchens.length) ? (kitchens.length) : "0"} Kitchens
            </div>
            <div className='grid'>
                <SearchBox searchType="kitchens" setIsOpen={toggleApplication} />
                <KitchenList />
            </div>
        </div>

        <AddKitchenModal closeAppModal={closeAppModal} isOpen={add_kitchen} />
     </>
  )
}


export default KitchensPage;
