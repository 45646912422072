import React, {useState, useEffect} from 'react'
// @ts-ignore  
import { DarkModeSwitch } from 'react-toggle-dark-mode'

function DarkModeSwitcher() {
    const [colorTheme, setTheme] = useDarkSide();
    const [darkSide, setDarkSide] = useState(colorTheme === "light" ? true : false)

    const toggleDarkMode = (checked: boolean) => {
        setTheme(colorTheme)
        setDarkSide(checked);
    }
  return (
    <div className="text-xl py-4 cursor-pointer font-open-sans flex mx-auto w-6">
        <DarkModeSwitch checked={darkSide} onChange={toggleDarkMode} size={25} moonColor="#D9D9D9" sunColor="#5DB6CE" />
    </div>
  )
}

type DarkSwitcherType = [
    'dark' | 'light',
    React.Dispatch<React.SetStateAction<string>>
]

const useDarkSide = (): DarkSwitcherType => {
    const [theme, setTheme] = useState<string>(localStorage.theme)
    const colorTheme = theme === 'dark' ? 'light' : 'dark'

    useEffect(() => {
        const root = window.document.documentElement
        root.classList.remove(colorTheme)
        root.classList.add(theme)
        localStorage.setItem('theme', theme)
    }, [theme, colorTheme])

    return [colorTheme, setTheme]
}

export default DarkModeSwitcher