import React, { useState } from "react";
import { questionaire } from "../utils/enums";
import { usePreferences } from "../contexts/preferencecontext";



const ExperienceStep1 = ({ onChange, step }: any) => {
  // eslin-disable-next-line  
  const [preference, setPreference] = useState("");
  const {setClicked} = usePreferences();

  const handlePreferenceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClicked(true);
    const newValue = event.target.value;
    setPreference(newValue);
    // console.log(newValue);
    onChange({ ownership: newValue });
  };

  const currentStep = `step${step + 1}`; // Change this to the actual step you're rendering

  return (
    <div className="">
      <form className="grid gap-y-5">
        <h1 className="text-primary-200 questions text-center">
          {questionaire[currentStep].question}
        </h1>
        <div className="grid grid-flow-row gap-y-5">
          {Object.entries(questionaire[currentStep].options).map(
            ([optionKey, optionValue]) => (
              <label className="formValuePrimary" key={optionKey}>
                <input
                  type="radio"
                  value={optionValue}
                  className="mr-3"
                  checked={preference === optionValue}
                  onChange={handlePreferenceChange}
                />
                {optionValue}
              </label>
            )
          )}
        </div>
      </form>
    </div>
  );
};


const ExperienceStep2 = ({ onChange,step }: any) => {
  const [preference, setPreference] = useState("");
  const {setClicked} = usePreferences();

  const handlePreferenceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    setClicked(true);
    setPreference(newValue);
    // console.log(newValue);
    onChange({ experience: newValue });
  };

  const currentStep = `step${step + 1}`;
  return (
    <div className="">
      <form className="grid gap-y-5">
        <h1 className="text-primary-200 questions text-center">
          {questionaire[currentStep].question}
        </h1>
        <div className="grid grid-flow-row gap-y-5">
          {Object.entries(questionaire[currentStep].options).map(
            ([optionKey, optionValue]) => (
              <label className="formValuePrimary" key={optionKey}>
                <input
                  type="radio"
                  value={optionValue}
                  className="mr-3"
                  checked={preference === optionValue}
                  onChange={handlePreferenceChange}
                />
                {optionValue}
              </label>
            )
          )}
        </div>
      </form>
    </div>
  );
};


const LocationStep1 = ({ onChange, step }: any) => {
  const {setClicked} = usePreferences();

    const handlePreferenceChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const newValue = event.target.value;
      setClicked(true);
      onChange({ location: newValue });
    };
  
    const currentStep = `step${step + 1}`; // Change this to the actual step you're rendering
  
    return (
      <div className="">
        <form className="grid gap-y-5">
          <h1 className="text-primary-200 questions text-center">
            {questionaire[currentStep].question}
          </h1>
          <div className="grid grid-flow-row gap-y-5">
            <input className="" type="text" onChange={handlePreferenceChange}></input>
          </div>
        </form>
      </div>
    );
};


const LocationStep2 = ({ onChange,step }: any) => {
  const [preference, setPreference] = useState("");
  const {setClicked} = usePreferences();

  const handlePreferenceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClicked(true);
    const newValue = event.target.value;
    setPreference(newValue);
    // console.log(newValue);
    onChange({ size: newValue });
  };

  const currentStep = `step${step + 1}`;
  return (
    <div className="">
      <form className="grid gap-y-5">
        <h1 className="text-primary-200 questions text-center">
          {questionaire[currentStep].question}
        </h1>
        <div className="grid grid-flow-row gap-y-5">
          {Object.entries(questionaire[currentStep].options).map(
            ([optionKey, optionValue]) => (
              <label className="formValuePrimary" key={optionKey}>
                <input
                  type="radio"
                  value={optionValue}
                  className="mr-3"
                  checked={preference === optionValue}
                  onChange={handlePreferenceChange}
                />
                {optionValue}
              </label>
            )
          )}
        </div>
      </form>
    </div>
  );
};


const KitcheSpaceStep1 = ({ onChange,step }: any) => {
  const [preference, setPreference] = useState("");
  const {setClicked} = usePreferences();

  const handlePreferenceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    setClicked(true);
    setPreference(newValue);
    // console.log(newValue);
    onChange({ community_type: newValue });
  };

  const currentStep = `step${step + 1}`;
  return (
    <div className="">
      <form className="grid gap-y-5">
        <h1 className="text-primary-200 questions text-center">
          {questionaire[currentStep].question}
        </h1>
        <div className="grid grid-flow-row gap-y-5">
          {Object.entries(questionaire[currentStep].options).map(
            ([optionKey, optionValue]) => (
              <label className="formValuePrimary" key={optionKey}>
                <input
                  type="radio"
                  value={optionValue}
                  className="mr-3"
                  checked={preference === optionValue}
                  onChange={handlePreferenceChange}
                />
                {optionValue}
              </label>
            )
          )}
        </div>
      </form>
    </div>
  );
};


const KitcheSpaceStep2 = ({ onChange, step }: any) => {
    const [preferences, setPreferences] = useState<string[]>([]);
    const {setClicked} = usePreferences();

    const handleExperienceChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setClicked(true);
      const optionValue = event.target.value;
    setPreferences((prevPreferences) => [...prevPreferences, optionValue]);
      onChange({requirements: preferences.toString()})
    };
  
    const currentStep = `step${step + 1}`;
    return (
      <div className="">
        <form className="grid gap-y-5">
          <h1 className="text-primary-200 questions text-center">
            {questionaire[currentStep].question}
          </h1>
          <div className="flex container justify-center flex-wrap  h-[450px] overflow-y-scroll">
            {Object.entries(questionaire[currentStep].options).map(
              ([optionKey, optionValue]) => (
                <label className="formValuePrimary text-lg w-1/5 justify-center m-2 p-5" key={optionKey}>
                  <input
                    type="checkbox" 
                    value={optionValue}
                    className="mr-3"
                    checked={preferences.includes(optionValue)}
                    onChange={handleExperienceChange}
                  />
                  {optionValue}
                </label>
              )
            )}
          </div>
        </form>
      </div>
    );
};


const BookingStep1 = ({ onChange,step }: any) => {
  const {setClicked} = usePreferences();

  const handlePreferenceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClicked(true);
    const newValue = event.target.value;
    onChange({ availability: newValue });
  };

  const currentStep = `step${step + 1}`;
  return (
    <div className="">
      <form className="grid gap-y-5">
        <h1 className="text-primary-200 questions text-center">
          {questionaire[currentStep].question}
        </h1>
        <div className="grid grid-flow-row gap-y-5">
          <input className="" type="text" onChange={handlePreferenceChange}></input>
        </div>
      </form>
    </div>
  );
};


const BookingStep2 = ({ onChange,step }: any) => {
  const [preference, setPreference] = useState("");
  const {setClicked} = usePreferences();

  const handlePreferenceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClicked(true);
    const newValue = event.target.value;
    setPreference(newValue);
    // console.log(newValue);
    onChange({ urgency: newValue });
  };

  const currentStep = `step${step + 1}`;
  return (
    <div className="">
      <form className="grid gap-y-5">
        <h1 className="text-primary-200 questions text-center">
          {questionaire[currentStep].question}
        </h1>
        <div className="grid grid-flow-row gap-y-5">
          {Object.entries(questionaire[currentStep].options).map(
            ([optionKey, optionValue]) => (
              <label className="formValuePrimary" key={optionKey}>
                <input
                  type="radio"
                  value={optionValue}
                  className="mr-3"
                  checked={preference === optionValue}
                  onChange={handlePreferenceChange}
                />
                {optionValue}
              </label>
            )
          )}
        </div>
      </form>
    </div>
  );
}

const MiscellaneousStep1 = ({ onChange,step }: any) => {
  const [preference, setPreference] = useState("");
  const imageLinks = ['https://images.typeform.com/images/WLABQaHzMtP4/choice/default','https://images.typeform.com/images/MK3tpB2hdgzF/choice/default','https://images.typeform.com/images/T2YjeGT7K9gB/choice/default', 'https://images.typeform.com/images/kCSfRgM3LKyh/choice/default']
  const {setClicked} = usePreferences();

  const handlePreferenceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClicked(true);
    const newValue = event.target.value;
    setPreference(newValue);
    // console.log(newValue);
    onChange({ capacity: newValue });
  };

  const currentStep = `step${step + 1}`;
  return (
    <div className="">
      <form className="grid gap-y-5">
        <h1 className="text-primary-200 questions text-center">
          {questionaire[currentStep].question}
        </h1>
        <div className="flex ">
          {Object.entries(questionaire[currentStep].options).map(
            ([optionKey, optionValue]) => (
              <label className="formValuePrimary text-lg w-1/5  justify-center m-10  p-10" key={optionKey}>
                <img src={imageLinks[parseInt(optionKey) - 1]} alt='Capacity' className="w-30 "></img>
                <input
                  type="radio"
                  value={optionValue}
                  className="mr-3"
                  checked={preference === optionValue}
                  onChange={handlePreferenceChange}
                />
                {optionValue}
              </label>
            )
          )}
        </div>
      </form>
    </div>
  );
}


const MiscellaneousStep2 = ({ onChange,step }: any) => {
  const [preference, setPreference] = useState("");
  const {setClicked} = usePreferences();

  const handlePreferenceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClicked(true);
    const newValue = event.target.value;
    setPreference(newValue);
    // console.log(newValue);
    onChange({ frequency: newValue });
  };

  const currentStep = `step${step + 1}`;
  return (
    <div className="">
      <form className="grid gap-y-5">
        <h1 className="text-primary-200 questions text-center">
          {questionaire[currentStep].question}
        </h1>
        <div className="grid grid-flow-row gap-y-5">
          {Object.entries(questionaire[currentStep].options).map(
            ([optionKey, optionValue]) => (
              <label className="formValuePrimary" key={optionKey}>
                <input
                  type="radio"
                  value={optionValue}
                  className="mr-3"
                  checked={preference === optionValue}
                  onChange={handlePreferenceChange}
                />
                {optionValue}
              </label>
            )
          )}
        </div>
      </form>
    </div>
  );
}


const MiscellaneousStep3 = ({ onChange,step }: any) => {
  const [preference, setPreference] = useState("");
  const {setClicked} = usePreferences();

  const handlePreferenceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClicked(true);
    const newValue = event.target.value;
    setPreference(newValue);
    // console.log(newValue);
    onChange({ budget: newValue });
  };

  const currentStep = `step${step + 1}`;
  return (
    <div className="">
      <form className="grid gap-y-5">
        <h1 className="text-primary-200 questions text-center">
          {questionaire[currentStep].question}
        </h1>
        <div className="grid grid-flow-row gap-y-5">
          {Object.entries(questionaire[currentStep].options).map(
            ([optionKey, optionValue]) => (
              <label className="formValuePrimary" key={optionKey}>
                <input
                  type="radio"
                  value={optionValue}
                  className="mr-3"
                  checked={preference === optionValue}
                  onChange={handlePreferenceChange}
                />
                {optionValue}
              </label>
            )
          )}
        </div>
      </form>
    </div>
  );
};
export {
  ExperienceStep1,
  ExperienceStep2,
  LocationStep1,
  LocationStep2,
  KitcheSpaceStep1,
  KitcheSpaceStep2,
  BookingStep1,
  BookingStep2,
  MiscellaneousStep1,
  MiscellaneousStep2,
  MiscellaneousStep3,
};
