import {useEffect, useState } from "react";
// import PhoneLogin from './PhoneLogin'
import EmailLogin from './EmailLogin'
import SignUp from './SignUp'
// import { useAuthState } from '../../../contexts/authcontext'
import LoadingScreen from "../../shared/LoadingScreen"
import { useLoadAuthUser } from "../../../hooks/authhooks";


const Login = () => {

  const [isSignUp, setIsSignUp] =  useState(false)
    var { registerUser, registerBusiness,isLoading } = useLoadAuthUser()


    useEffect(()=> {
        document.title = 'Signup | HomeCook'
     }, [])  
     
     if (isLoading){
      return (
        <LoadingScreen />
      )
    } else {
      return (isSignUp) ? <SignUp registerBusiness={registerBusiness} toggleSignIn={() => setIsSignUp(false)} /> : <EmailLogin registerUser={registerUser} toggleSignUp={() => setIsSignUp(true)} />
    }
 
}




export default Login
