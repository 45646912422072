import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';

// import { useKitchenState } from "../contexts/kitchencontext";
import { useMemberState } from '../contexts/membercontext'
import { useAddMembership } from "../hooks/memberhooks";
// import { states } from "../utils/enums";
// import { styleThousandCommas } from "../utils/helpers";
import { ReactComponent as Union } from '../icons/Union.svg'

// import { MembershipSchema } from "../data/types";
// import { useMemberState } from '../contexts/membercontext';

import LoadingScreen from "../pages/shared/LoadingScreen";

type CreateMembershipProps = {
  handleClose?: () => void;
  isOpen?: boolean;
 
};

const CreateMembership: React.FC<CreateMembershipProps> = (props) => {
  // const navigate = useNavigate()
  const {emails, setEmails, setAddingMembers} = useMemberState();
  // var { plans } = useKitchenState();
  var {
    // addMembership,
    isLoadingMembership,
    isAddMembershipError,
    isAddMembershipSuccess,
  } = useAddMembership();
  // useMemberState
  var { handleClose, isOpen} = props;

  // const [newMembership, setNewMembership] = useState<MembershipSchema>({});
  // const [selectedState, setSelectedState] = useState<string>("");
  // const [selectedPlan, setSelectedPlan] = useState<PlanSchema>({});
  const [addedMembers, setAddedMembers] = useState<string[]>([]);
  const [currentEmail, setCurrentEmail] = useState<string>("");

  // useEffect(() => {
  // }, [newMembership])

 

  // const phone_style = (phone_number: string) => {
  //   var cleaned = ("" + phone_number).replace(/\D/g, "");
  //   var match = cleaned.match(/^(0-1|)?(\d{3})(\d{3})(\d{4})$/);
  //   // let number;
  //   if (match) {
  //     var intlCode = match[1] ? "+1 " : "";
  //     if (phone_number.length === 10) {
  //       phone_number = [
  //         intlCode,
  //         "(",
  //         match[2],
  //         ") ",
  //         match[3],
  //         "-",
  //         match[4],
  //       ].join("");
  //     } else if (phone_number.length > 3 && phone_number.length < 7) {
  //       phone_number = [intlCode, "(", match[2], ") ", match[3]].join("");
  //     } else if (phone_number.length < 4) {
  //       phone_number = [intlCode, "(", match[2], ")"].join("");
  //     }
  //   }
  //   return phone_number;
  // };

  // const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const { value } = event.target;
  //   setSelectedState(value);
  //   setNewMembership((prevMembership) => ({
  //     ...prevMembership,
  //     state: value,
  //   }));
  // };
const handleCancel = () => {
  if (handleClose!== undefined) {
    handleClose();
    setCurrentEmail('')
    setEmails([])
  }
}

  // const handlePlanChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   let currentPlan = event.target.value ? JSON.parse(event.target.value) : {};
  //   setSelectedPlan(currentPlan);
  //   var { id, plan_name, plan_description, plan_recurrence, plan_rate } =
  //     currentPlan;
  //   setNewMembership((prevMembership) => ({
  //     ...prevMembership,
  //     plan_id: id ? id : "",
  //     plan_name: plan_name ? plan_name : "",
  //     plan_description: plan_description ? plan_description : "",
  //     plan_recurrence: plan_recurrence ? plan_recurrence : "",
  //     plan_rate: plan_rate || plan_rate === 0 ? parseFloat(plan_rate) : 0,
  //   }));
  // };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    // if (name === "phone") {
    //   let phone = ("" + value).replace(/\D/g, "");
    //   if ((!isNaN(parseInt(phone)) && phone.length <= 10) || phone === "") {
    //     setNewMembership((prevMembership) => ({
    //       ...prevMembership,
    //       phone,
    //     }));
    //   }
    // } 
    if(name==="email") {
      setAddedMembers([value]);
      setCurrentEmail(value)

    }
    // else {
    //   setNewMembership((prevMembership) => ({
    //     ...prevMembership,
    //     [name]: value,
    //   }));
    // }
  };
  const handleEmailChange = () => {
    if (addedMembers.length > 0 && !emails?.includes(addedMembers[addedMembers.length - 1])) {
      setEmails((prevEmails: any) => [...prevEmails, addedMembers[addedMembers.length - 1]]);
      setAddedMembers([]);
      setCurrentEmail('')
    }
  }
  const handleEmailDelete = (email: string) => {
    setEmails((prevEmails: any) => prevEmails.filter((e:any) => e !== email));
  }
  // console.log("newMembership", newMembership)
  // console.log("selectedState", selectedState)
  // console.log("selectedPlan", selectedPlan)
  if (isLoadingMembership || isAddMembershipError) {
    return (
      <div className="flex flex-col items-center font-open-sans text-inherit p-4 col-span-1 rounded-md justify-evenly">
        <LoadingScreen bgColor="white" height="h-full" />
      </div>
    );
  } else {
    if (handleClose && isOpen && isAddMembershipSuccess) {
      handleClose();
    }
    return (
      <div className="flex flex-col items-center font-open-sans text-inherit p-4 col-span-1 rounded-md justify-evenly">
        <div className="flex flex-col">
          <img
            src="/logo192.png"
            alt="homecook-logo"
            className="w-[110px] h-[82px] self-center"
          />
          <h1 className="text-2xl text-primary-200 text-center">
            Add New Member.
          </h1>
          {/* <h1 className="text-xl text-primary-200 text-center">
            Add several members at once
          </h1> */}
          <h1 className=" text-back-200 text-center">
            Add new members to your kitchen with an email address.
          </h1>
        </div>
        <div>
          <ul className="flex flex-row gap-3">
            {Object(emails).map((email:string, index:number) => (
              <div className="bg-back-200 rounded-full p-1 flex flex-row">
                <li className="text-sm" key={index}>{email}</li>
                <Union className="w-2 h-2 mt-[7px] ml-1 hover:cursor-pointer" onClick={() => handleEmailDelete(email)}/>
              </div>
            ))}
          </ul>
        </div>
        <div className="flex flex-col w-full font-open-sans my-2">
          {/* <h1 className=" text-xs">Add Email Adress(es)</h1> */}
          <div className="flex flex-row justify-evenly">

            <input onSubmitCapture={handleEmailChange} placeholder="Email address" value={currentEmail} className="formInput mt-1 bg-inherit !w-full border-2 rounded-xl mx-1" name="email" type="email" onChange={handleInputChange} />
            <button
              disabled={currentEmail ? false : true}
              className={`border-0 bg-primary-300 text-[#D9D9D9] text-sm rounded-full m-1 p-1 ${ currentEmail ? 'hover:bg-gray-400' : ''}`}
              onClick={() => handleEmailChange()} >
                <CheckIcon className="my-auto" />
            </button>

          </div>
        
          {/* <button
            className="border-0  bg-primary-300 text-[#D9D9D9] text-sm  py-1 rounded-md justify-end m-2 w-1/2 mx-auto"
            onClick={() => handleEmailChange()}
          >
            Add Email
          </button> */}
          <div className="flex flex-row w-full m-1 justify-between p-2">

            <div className="flex flex-col">

              {/* <div className="flex">
                      <input placeholder="First Name" className="formInput mr-1 bg-inherit" name="first_name" type="text" onChange={handleInputChange} />
                      <input placeholder="Last Name" className="formInput bg-inherit" name="last_name" type="text" onChange={handleInputChange} />
                    </div>
                    <input placeholder="Email address" className="formInput mt-1 bg-inherit" name="email" type="email" onChange={handleInputChange} />
                    <input placeholder="Phone Number" value={(newMembership.phone) ? phone_style(newMembership.phone) : ""} className="formInput mt-1 bg-inherit" name="phone" type="tel" onChange={handleInputChange} />

                    <div className="flex">
                      <select
                        value={selectedState}
                        onChange={handleStateChange}
                        className="formInput text-slate-500 mt-1 mr-1 bg-inherit"
                      >
                        <option value="">Select a state</option>
                        {Object.values(states).map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>    

                      <input placeholder="Zip Code" className="formInput mt-1 w-full bg-inherit" name="zipcode" type="text" onChange={handleInputChange} />
                    </div>

                    <select
                      value={selectedPlan ? JSON.stringify(selectedPlan) : ""}
                      onChange={handlePlanChange}
                      className="formInput text-slate-500 mt-1 bg-inherit"
                    >
                      <option value="">Select a plan</option>
                      {
                        (plans) ? plans.map((plan, i) => (
                          <option key={i} value={plan ? JSON.stringify(plan) : ""}>
                            {plan.plan_name}
                          </option>
                        )) : null 
                      }
                    </select>
                    <div className="flex justify-center p-1 m-1 text-[16px]">
                      {
                        (selectedPlan.hasOwnProperty("plan_name") && selectedPlan.plan_name) ? (
                          <>
                          <b>{selectedPlan.plan_name.toUpperCase()}:</b> &nbsp; ${styleThousandCommas(selectedPlan?.plan_rate ? selectedPlan.plan_rate : "")} ({selectedPlan.plan_recurrence})
                          </>
                        ) : null
                      }
                    </div> */}
            </div>
          </div>
        </div>
        <div className=" flex flex-row gap-x-10">
          <button
            className="border-0  bg-back-200 text-[#D9D9D9] px-3 py-1 rounded-md"
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button
            disabled={(emails && emails.length > 0) ? false : true}
            className="border-0  bg-primary-300 text-[#D9D9D9] px-3 py-1 rounded-md"
            onClick={() => {
              if(emails && emails.length > 0){
                setAddingMembers(true)
              }
            }}
          >
            Continue
          </button>
        </div>{" "}
      </div>
    );
  }
  // else {
  //   if (handleClose && !isOpen){ handleClose()}
  //   return (
  //     <></>
  //   )
  // }
};

export default CreateMembership;
