import React from "react";
// import info from "../assets/img/Info.png";
import moment from "moment";
import { ReactComponent as InfoIcon } from '../icons/InfoIcon.svg'

import { CredentialSchema } from "../data/types";

const validity = (valid: any, expiration: any) => {
  const expirationDate = moment(expiration);
  const now = moment();
  const expiringSoon = expirationDate.diff(now, "days") <= 30;
  return (
    <div className="">
      <div
        className={`${
          expiringSoon
            ? "bg-[#F79E1B]"
            : valid
            ? "bg-[#0B9821]"
            : "bg-[#EB001B]"
        } rounded-full`}
      >
        {" "}
        {expiringSoon ? "Expiring Soon" : valid ? "Valid" : "Invalid"}
      </div>
    </div>
  );
};

type CredentialRecordProps = {
  credential: CredentialSchema;
};

const CredentialRecord: React.FC<CredentialRecordProps> = (props) => {
  var { credential } = props;
  // const expiration = moment(credential.expiration).format("l");
  return credential ? (
    <tr className="border-x-2 border-b-2 border-stone-400 bg-[#F9F9F9]">
      <td className="w-48 pr-5 text-center   border-stone-500">{credential.category}</td>

      <td className="w-48 pr-5 text-center   border-stone-500">
        {credential.credential_number}
        {/* </div> */}
      </td>

      <td className="w-48 pr-5 text-center   border-stone-500">{credential.municipality}</td>

      <td className="w-48 pr-5 text-center   border-stone-500">
        {moment(credential.timestamp).format("MM/YYYY")}
        {/* </div> */}
      </td>
      <td className="w-48 pr-5 text-center   border-stone-500">
        {moment(credential.expiration).format("MM/YYYY")}
        {/* </div> */}
      </td>
      <td className="w-48 p-5 text-center   border-stone-500">
        {validity(credential.is_valid, credential.expiration)}
        {/* </div> */}
      </td>
      <td className="w-48 p-5 text-center   border-stone-500">
        {/* <img src={info} alt="credential" /> */}
        <InfoIcon />
        {/* </div> */}
      </td>
    </tr>
  ) : null;
};

export default CredentialRecord;
