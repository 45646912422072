import React, { useState } from "react"

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import MemberRecord from "../molecules/MemberRecord"
import EditMemberRecord from "../molecules/EditMemberRecord"

import { useUpdateMembership } from '../hooks/memberhooks'
import { MembershipSchema } from "../data/types";


type MembersListProps = {
  kitchen_capacity: number
  members: MembershipSchema[],
  isRequests?: boolean
}

const MembersList: React.FC<MembersListProps> = ({kitchen_capacity, members, isRequests}) => {
  const { updateMembership } = useUpdateMembership()

  const [allselected, updateAllSelected] = useState(false)
  const [editMember, setEditMember] = useState<string>("")

  const submitUpdate = async (membershipChanges: MembershipSchema) => {
    updateMembership(membershipChanges)
    setEditMember("")
  }

  const cancelEditMember = () => {
    setEditMember("")
  }

  return (
    <table className="table-auto my-3">
      <thead className="bg-secondary h-10 border-2 border-black font-open-sans">
        <tr>
          <th className="flex text-white p-1 border-r-0 border-l-0"> 
            {allselected ? (
              <CheckBoxIcon
                onClick={() => updateAllSelected(false)}
              />
            ) : (
              <CheckBoxOutlineBlankIcon
                onClick={() => updateAllSelected(true)}
              />
            )}
            &nbsp; <KeyboardArrowDownIcon className="m-auto" style={{ cursor: "pointer", fontSize: 15 }} />
          </th>

          <th className="headItem border-r-0 border-l-0">
            Username &nbsp; <KeyboardArrowDownIcon style={{ cursor: "pointer", fontSize: 15 }} />
          </th>

          <th className="headItem border-r-0 border-l-0">Full Name</th>

          <th className="headItem border-r-0 border-l-0">Team</th>

          <th className="headItem border-r-0 border-l-0">Membership Status</th>

          <th className="headItem border-r-0 border-l-0">Membership Plan</th>

          <th className="headItem border-r-0 border-l-0">Member Balance</th>

          <th className="headItem border-r-0 border-l-0">Joined</th>

          
          <th className="headItem border-r-0 border-l-0"></th>
        </tr>
      </thead>

      <tbody className="bg-back-500">
      {members
        ? members.map((member: MembershipSchema, i: number) => (
            (editMember && editMember === member.username) ? (
              <EditMemberRecord
                key={i}
                member={member}
                kitchen_capacity={kitchen_capacity}
                allSelected={allselected}
                isRequests={isRequests}
                submitUpdate={submitUpdate}
                cancelEditMember={cancelEditMember}
              />
            ) : (
              <MemberRecord
                key={i}
                member={member}
                allSelected={allselected}
                isRequests={isRequests}
                setEditMember={setEditMember}
              />
            )
          ))
        : "No Members"}
        </tbody>
    </table>
  );
};

export default MembersList;
