import React from "react";
// import { useKitchenState } from "../../contexts/kitchencontext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CredentialSchema, MembershipSchema } from "../../data/types";
import CredentialRecord from "../../molecules/CredentialRecord";
// import arrow from '../../assets/img/arrow.png'
// import { ReactComponent as BackArrowIcon } from '../../icons/BackArrowIcon.svg'



type CredentialViewProps = {
    currentMember: MembershipSchema
    credentials: CredentialSchema[];
    setAllCredentialsPage: (open:boolean) => void
  };

const AllCredentialsView:React.FC<CredentialViewProps> = (props) => {
    var { currentMember, credentials, setAllCredentialsPage  } = props;



  return (
    <div className=" pt-16 p-10 flex flex-col ">
      <div className="flex flex-col gap-10">
        {/* <h1 className="text-center text-3xl drop-shadow-xl">HomeCook</h1> */}
        <div className="grid grid-flow-col grid-cols-5 text-center">
          {/* <div className="grid grid-flow-col mr-16 hover:cursor-pointer" onClick={() => setAllCredentialsPage(false)}>
              <BackArrowIcon className="w-1/2 pt-1" /> 
              <h1 className="pt-3 ">
            return to profile
            </h1>
          </div> */}

          <ArrowBackIosIcon
              className="text-[#5DB6CE] hover:text-gray-500 cursor-pointer"
              onClick={() => setAllCredentialsPage(false)}
            />
       
          <h1 className="flex justify-center text-xl text-[#013847]/40 text-center place-self-center col-start-3 ">Credentials for {currentMember.first_name ? `${currentMember.first_name} ${currentMember.last_name}` : currentMember.username}</h1>
        </div>
      </div>
      <div className="flex flex-col pt-2">
        <table className=" table-auto my-3 ">
          <thead className="bg-secondary h-10 ">
            <tr>
              <th className="credentialHeadItem rounded-tl-xl">Requirement Name</th>

             

              <th className="credentialHeadItem">Credential Number</th>

              <th className="credentialHeadItem">Issuing Body</th>

              <th className="credentialHeadItem">Date Added</th>

              <th className="credentialHeadItem">Expiration Date</th>

              <th className="credentialHeadItem ">Status</th>

              <th className="credentialHeadItem rounded-tr-xl"></th>
            </tr>
          </thead>

          <tbody className="bg-back-500 h-56">
            {credentials && credentials.length !== 0 ? (
              Object(credentials).map((credential: CredentialSchema, i: number) => (
                <CredentialRecord key={i} credential={credential} />
              ))
            ) : (
              <div className="flex flex-row justify-evenly w-full">
                <div>No Credentials</div>
              </div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllCredentialsView;
