// import { useEffect } from 'react'
// // import LineGraph from '../../components/LineGraph'
// import ChartLegend from '../../components/ChartLegend'

// import SearchBox from '../../components/SearchBox'
import { InventorySchema } from '../data/types'
import { capitalizeString } from "../utils/helpers";

// import BaconIcon from '../../icons/foodIcons/Bacon.svg'
// import BeefIcon  from '../../icons/foodIcons/Beef.svg'
// import BroccoliIcon from '../../icons/foodIcons/Broccoli.svg'
// import ChickenIcon  from '../../icons/foodIcons/Chicken.svg'
// import CucumberIcon from '../../icons/foodIcons/Cucumber.svg'
// import GrapesIcon from '../../icons/foodIcons/Grapes.svg'
// import InventoryCard from '../../components/InventoryCard'
// import PeachIcon  from '../../icons/foodIcons/Peach.svg'
// import  WhiteBreadIcon from '../../icons/foodIcons/WhiteBread.svg'




// Table.tsx
interface EquipmentTableProps {
  headers: string[];
  data: InventorySchema[];
}

const EquipmentTable: React.FC<EquipmentTableProps> = ({ headers, data }) => {
  return (
    <table className="min-w-full border border-gray-300 text-left">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index} className="border-b px-4 py-2 bg-primary-200 text-[#D9D9D9]">{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex} className='bg-[#F9F9F9] hover:bg-slate-100 cursor-pointer'>
            {/* {row.map((cell, cellIndex) => (
              <td key={cellIndex} className="border-b px-4 py-2 bg-[#F9F9F9]">{cell}</td>
            ))} */}
            <td className="border-b px-4 py-2 ">{row.item_name}</td>
            <td className="border-b px-4 py-2 ">{capitalizeString(row?.category ? row?.category : "")}</td>
            <td className="border-b px-4 py-2 ">${row?.unit_price ? row.unit_price : 0}</td>
            <td className="border-b px-4 py-2 ">{row?.available_quantity ? row.available_quantity : 0} {row.measure_unit}</td>
            <td className="border-b px-4 py-2 ">{row.total_quantity} {row.measure_unit}</td>
            <td className="border-b px-4 py-2">...</td>

            {/* {row.map((cell, cellIndex) => ( */}
              
            {/* ))} */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};



export default EquipmentTable