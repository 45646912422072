import React, { useState } from 'react'
// import { useNavigate } from "react-router-dom";

import moment from "moment";
import { format, addWeeks, addMonths, startOfWeek } from "date-fns";

// import { useAuth } from "../contexts/authcontext";
// import { useMember } from "../contexts/membercontext";
// import { useKitchen } from "../contexts/kitchencontext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ReactComponent as UpArrow } from "../icons/VectorUp.svg";
import { ReactComponent as DownArrow } from "../icons/VectorDown.svg";

import { useAuthState } from '../contexts/authcontext'
import { useMemberState } from '../contexts/membercontext'
// import { useAddBooking } from '../hooks/memberhooks'
import { useAddBooking, useGetPaymentMethods } from '../hooks/memberhooks'
import { useKitchenState } from '../contexts/kitchencontext'

import  EquipmentsWidget from './EquipmentsWidget'
import LoadingScreen from "../pages/shared/LoadingScreen"

import { BookingSchema, MembershipSchema } from "../data/types";
import ReservationCalendar from "./ReservationCalendar";
import BookingConfirmation from "../molecules/BookingConfirmation";

type StartBookingProps = {
  handleClose: () => void
  isOpen?: boolean
  
};


const StartBooking: React.FC<StartBookingProps> = ({isOpen, handleClose}) => {
  // const navigate = useNavigate()
  // var { schoolId } = useParams();

  var { authUser,  isloggedin } = useAuthState()
  var { memberships, bookings } = useMemberState()
  var { addNewBooking, isLoadingBooking, isAddBookingSuccess, isAddBookingError } = useAddBooking()
  var { plans, currentKitchen } = useKitchenState()

  // useEffect(() => { 
  //   // if (loadData !== undefined){loadData()}
  //   // eslint-disable-next-line 
  // }, [])
  
  
  // const getNext30MinuteInterval = (dateTime?: any) => {
  //   dateTime = dateTime ? moment(dateTime) : moment() // 2023-06-07T08:54 vs 2023-06-07T08:10
  //   var minutes = dateTime.minutes()
  //   var remainder = 30 - minutes
  //   var next30MinMark = dateTime.minutes(0)
  //   if (remainder < 0){
  //     next30MinMark = dateTime.minutes(minutes + remainder)
  //     next30MinMark.add(30, 'minutes')
  //   } else if (remainder < 30){
  //     next30MinMark.minutes(minutes + remainder)
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const getNext30MinuteInterval = (dateTime?: any) => {
    dateTime = dateTime ? moment(dateTime) : moment(); // 2023-06-07T08:54 vs 2023-06-07T08:10
    var minutes = dateTime.minutes();
    var remainder = 30 - minutes;
    var next30MinMark = dateTime.minutes(0);
    if (remainder < 0) {
      next30MinMark = dateTime.minutes(minutes + remainder);
      next30MinMark.add(30, "minutes");
    } else if (remainder < 30) {
      next30MinMark.minutes(minutes + remainder);
    }
    return next30MinMark.format("YYYY-MM-DDTHH:mm");
  };

  const [startDate, updateStartDate] = useState(getNext30MinuteInterval());
  const [endDate, updateEndDate] = useState(startDate);
  const [nHours, updateNHours] = useState(0);
  // const [isBookingRequest, toggleBookingRequest] = useState<boolean>(false)
  const [selectedEquipments, updateSelectedEquipments] = useState<string[]>([]);
  const [member, setMember] = useState<MembershipSchema>(); // Change the type according to your data type
  // const [memberUsername, setMemberUsername] = useState<string>(""); // Change the type according to your data type
  // const [isExistingMember, setIsExistingMember] = useState<boolean>(true);
  const isExistingMember = true
  // const [view, setView] = useState<"month" | "week">("week");

  const { paymentMethods } = useGetPaymentMethods(member?.username ? member.username : "")
  const [selectedPayMethod, updateSelectedPayMethod] = useState<string>(paymentMethods && paymentMethods.length > 0 && paymentMethods.length[0]?.id ? paymentMethods.length[0]?.id : "")


  var view =  "week"
  // const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  // const [beforeBuffer, setBeforeBuffer] = useState("");
  var beforeBuffer = "0"
  // const [afterBuffer, setAfterBuffer] = useState("");
  var afterBuffer = "0"
  // const buffers = [15, 30, 45, 60];
  const [step, setStep] = useState(0);
  const [newBookings, setNewBooking] = useState<BookingSchema | undefined>();
  const [bookingNotes, updateBookingNotes] = useState<string>("")

  const next = () => {
    setCurrentDate(
      view === "month" ? addMonths(currentDate, 1) : addWeeks(currentDate, 4)
    );
  };

  const prev = () => {
    setCurrentDate(
      view === "month" ? addMonths(currentDate, -1) : addWeeks(currentDate, -4)
    );
  };
  const handleMemberChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    // setMemberUsername(value);
    if (value) {
      // console.log("value", JSON.parse(value))
      setMember(JSON.parse(value));
    } else {
      setMember({});
    }
  };

  const handleBooking = async (option: string) => {
    let memberUsername = member?.username;
    console.log(startDate.format("hh:mm"));
    console.log(endDate.format("hh:mm"));
    if (
      isloggedin &&
      currentKitchen &&
      authUser &&
      memberUsername &&
      startDate &&
      endDate &&
      endDate > startDate &&
      startDate !== endDate
    ) {
      var newBooking: BookingSchema = {
        has_reminder: false,
        community_id: currentKitchen.community_id
          ? currentKitchen.community_id
          : "",
        start_time: moment(startDate)
          .set("minutes", moment(startDate).minutes() - Number(beforeBuffer))
          .format('YYYY-MM-DDTHH:mm')
          .toString(),
        end_time: moment(endDate)
          .set("minutes", moment(endDate).minutes() + Number(afterBuffer))
          .format('YYYY-MM-DDTHH:mm').toString(),
        type: "kitchen",
        username: memberUsername,
        location: currentKitchen.address
          ? currentKitchen.address
          : currentKitchen.country
          ? currentKitchen.country
          : "",
        notes: bookingNotes,
        payment_method: selectedPayMethod
      };
      if (option === "confirm") {
        setNewBooking(newBooking);
      }

      if (option === "submit"){
          addNewBooking(newBooking)
          if (isLoadingBooking || isAddBookingSuccess){
            handleClose() 
          }
      }
    }
  };

  // const handleDateTimeChange = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   dateType: string
  // ) => {
  //   e.preventDefault();
  //   const newDate = e.target.value;
  //   var duration = moment.duration(moment(endDate)?.diff(moment(startDate)));

  //   if (dateType === "startDate" && moment(newDate) >= moment(new Date())) {
  //     // update start time
  //     updateStartDate(getNext30MinuteInterval(newDate));
  //     if (moment(newDate) > moment(endDate)) {
  //       updateEndDate(getNext30MinuteInterval(newDate));
  //     }
  //     duration = moment.duration(
  //       moment(getNext30MinuteInterval(endDate))?.diff(
  //         moment(getNext30MinuteInterval(newDate))
  //       )
  //     );
  //   } else if (dateType === "endDate" && moment(newDate) >= moment(startDate)) {
  //     // update end time
  //     updateEndDate(getNext30MinuteInterval(newDate));
  //     duration = moment.duration(
  //       moment(getNext30MinuteInterval(newDate))?.diff(
  //         moment(getNext30MinuteInterval(startDate))
  //       )
  //     );
  //   }

  //   // calculate hours diff
  //   var hoursDiff = duration.asHours();
  //   if (!isNaN(hoursDiff) && hoursDiff >= 0) {
  //     updateNHours(hoursDiff);
  //   }
  // };



  // Function to handle radio button changes
  // const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.value === "existingMember"){
  //     setIsExistingMember(true);
  //   } else {
  //     setIsExistingMember(false);
  //   }
  // };

  // const handleAfterBufferChange = (
  //   event: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   setAfterBuffer(event.target.value);
  // };

  // const handleBeforeBufferChange = (
  //   event: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   setBeforeBuffer(event.target.value);
  // };



  if (isLoadingBooking || isAddBookingError){
    return <LoadingScreen height='h-auto' bgColor='inherit' />
  } else {
    if (handleClose && (isAddBookingSuccess)){handleClose()}
    return (
      <>
      <div className='flex flex-row justify-start mt-5 ml-5'>
      <ArrowBackIosIcon
        className="text-[#5DB6CE] hover:text-gray-500 cursor-pointer"
        onClick={() => handleClose()}
      />
      </div>
      
      
      <div className="flex flex-col items-center font-open-sans text-inherit p-6 col-span-1 rounded-md justify-evenly">
        <p
          className={` font-raleway text-[25px] text-primary-300 m-2 text-center mx-auto text-2xl place-self-start  ${
            step === 1 ? "font-light px-10" : ""
          }`}
        >
          {step === 1 ? "Booking Request" : "Add New Reservation"}
        </p>
        <div className="w-full h-full">
          {step === 100 ? (
            <div>
              <div className="flex flex-row w-full m-1 justify-between p-2">
                <p className=" text-sm">Select equipment & supplies</p>
              </div>
  
              <div className="flex flex-col w-full justify-between items-center p-1">
                <EquipmentsWidget
                  selectedEquipments={selectedEquipments}
                  updateSelectedEquipments={updateSelectedEquipments}
                />
              </div>
            </div>
          ) : step === 0 ? (
            <div>
              <div className='flex flex-row w-full'>
              <div className="place-self-start pt-6 p-2 flex flex-col gap-5 mr-auto">
                <p className='text-md italic text-primary-200'>Choose the length of reservation <span className='text-red-900 italic text-sm'>*</span></p>
                <div className="grid grid-flow-col grid-cols-2 w-full">
                  <div className="flex flex-row">
                    <p className='text-sm text-primary-200 mr-6'>Number of hours</p>
                    <div className="flex flex-row gap-x-5 font-bold">
                      <DownArrow
                        onClick={() => {
                          if (nHours > 0 && moment(startDate) < moment(endDate)) {
                            updateNHours(nHours - 0.5);
                            let newEndDate = moment(endDate);
                            newEndDate.subtract(30, "minutes");
                            updateEndDate(getNext30MinuteInterval(newEndDate));
                          }
                        }}
                        className="cursor-pointer text-primary-300 hover:bg-gray-400  rounded-full hover:rounded-full"
                      />
                      {nHours.toFixed(1)}
                      <UpArrow
                        onClick={() => {
                          if (nHours < 24) {
                            updateNHours(nHours + 0.5);
                            let newEndDate = moment(endDate);
                            newEndDate.add(30, "minutes");
                            updateEndDate(getNext30MinuteInterval(newEndDate));
                          }
                        }}
                        className="cursor-pointer text-primary-300 hover:bg-gray-400  rounded-full hover:rounded-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col m-1 justify-between p-2 ml-auto">
                <div className="flex flex-col w-full font-open-sans my-2">
                {isExistingMember && member && member.hasOwnProperty("plan_name") && member.plan_name ? (
                <div className="flex flex-col w-full font-open-sans my-2">
                    <div className="flex flex-row ml-auto p-2">
                      <b>{member.plan_name.toUpperCase()} PLAN:</b> &nbsp; $
                      {member && member.plan_rate?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &nbsp; <p className='text-primary-200 italic text-sm'>({member.plan_recurrence})</p>
                    </div>
                </div>
                  ) : (<></>)
                }
                  <p className='text-md italic text-primary-200'>Choose member <span className='text-red-900 italic text-sm'>*</span></p>
                  <div className="flex flex-row w-full m-1 justify-between p-2">
                    {isExistingMember ? (
                      <select
                        className="formInput text-slate-500"
                        value={JSON.stringify(member)}
                        onChange={handleMemberChange}
                      >
                        <option value="">Select existing member</option>
                        {memberships
                          ? memberships.map((m, i) => (
                              <option key={i} value={JSON.stringify(m)}>
                                {m.username}
                              </option>
                            ))
                          : null}
                      </select>
                    ) : (
                      <div className="flex flex-col">
                        <div className="flex">
                          <input
                            placeholder="Full Name"
                            className="formInput mr-1"
                            name="name"
                            type="text"
                          />
                          <input
                            placeholder="Email address"
                            className="formInput"
                            name="email"
                            type="email"
                          />
                        </div>
                        <select className="formInput text-slate-500 mt-1">
                          <option value="">Select a plan</option>
                          {plans
                            ? plans.map((plan, i) => (
                                <option key={i} value={plan.plan_name}>
                                  {plan.plan_name}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
                </div> 
              </div>

              <div className="flex flex-col w-full m-1 justify-between p-2">

  
                <p className='text-md italic text-primary-200'>Choose desired date and timeframe <span className='text-red-900 italic text-sm'>*</span></p>
  
                <h2 className="text-lg font-semibold text-center mt-10">
                  {view === "month"
                    ? format(currentDate, "MMMM yyyy")
                    : `${format(startOfWeek(currentDate), " MMMM yyyy")}`}
                </h2>
  
                <div className="mb-4 flex justify-between items-center text-slate-400">
                  <button onClick={prev}>Previous</button>
                  <button onClick={next}>Next</button>
                </div>
                <ReservationCalendar
                  currentDate={currentDate}
                  bookings={bookings}
                  nHours={nHours}
                  updateStartTime={updateStartDate}
                  updateEndTime={updateEndDate}
                  currentKitchen={currentKitchen}

                />
              </div>
            </div>
          ) : step === 1 && newBookings ? (
            <div>
              <BookingConfirmation
                currentBooking={newBookings}
                bookingNotes={bookingNotes}
                updateBookingNotes={updateBookingNotes}
                paymentMethods={paymentMethods}
                selectedPayMethod={selectedPayMethod}
                updateSelectedPayMethod={updateSelectedPayMethod}
                member={member}
                beforeBuffer={beforeBuffer}
                afterBuffer={afterBuffer}
                setStep={setStep}
                equipment={selectedEquipments}
                isOpen={isOpen}
                handleClose={handleClose}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className={`flex flex-row gap-10 ${
            step === 1 ? "!flex-col gap-2 w-[200px] self-end md:mr-28" : ""
          }`}
        >
          <button
            className={` bg-tertiary p-2 text-black hover:bg-back-300 rounded-lg shadow-xl ${
              step === 1 ? "order-2 " : ""
            }`}
            onClick={() => {
              if (step === 1) {
                setStep(step - 1);
              } else {
                handleClose();
              }
            }}
          >
            Cancel Request
          </button>
          <button
            disabled={(member?.username && nHours > 0) ? false : true}
            className={`buttonsPrimary p-2 text-black ${
              step === 1 ? "order-1 !bg-primary-200 !text-back-400" : ""
            }`}
            onClick={() => {
              if (step === 0) {
                handleBooking("confirm");
                setStep(step + 1);
              } else if (step === 1) {
                handleBooking("submit");
              } else {
                setStep(step + 1);
              }
            }}
          >
            {step === 1 ? "Submit Request" : "Continue"}
          </button>
        </div>
      </div>
      </>
    );
  }

};

export const useDate = () => {
  const locale = "en";
  const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

  React.useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  const day = today.toLocaleDateString(locale, { weekday: "long" });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, {
    month: "long",
  })}\n\n`;

  const hour = today.getHours();
  const wish = `Good ${
    (hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
  }, `;

  const time = today.toLocaleTimeString(locale, {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  return {
    date,
    time,
    wish,
  };
};
export default StartBooking;