import { useEffect} from 'react'
import { BookingSchema } from "../data/types";
import BookingCard from '../components/BookingCard'

type BookingsGridProps = {
  bookings: BookingSchema[]
}

const BookingsGrid: React.FC<BookingsGridProps>  = ({bookings}) => {

  useEffect(() => { 
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  var bookingsView = (<div className='flex flex-row justify-evenly font-light text-center text-[#013847]/40 '>No reservations to show yet</div>)

  if (bookings && bookings.length > 0){
    bookingsView =  (
      <div className={`grid ${(bookings && bookings.length < 3) ? `sm:grid-cols-${bookings.length} grid-cols-1` : 'md:grid-cols-3 grid-cols-1'} h-full w-full space-y-6 grid-flow-row-dense items-center`}>
          {
            bookings?.map((booking: BookingSchema, i: number) => <BookingCard key={i} booking={booking} />)
          }
      </div>
    )
  }
   

  return (
    <div className='flex flex-col items-center w-full h-full'>
      {bookingsView}
    </div>
  )
}


export default BookingsGrid