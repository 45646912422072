import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js"

Chart.register(...registerables);



interface LineGraphProps {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      borderColor: string;
      fill: boolean;
    }[];
  };
}

const LineGraph: React.FC<LineGraphProps> = ({ data }) => {

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis grid lines
        },
        display: true,
      },
      y: {
        grid: {
          display: false, // Hide y-axis grid lines
        },
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      // tooltip: {
      //   enabled: false, // Disable tooltips
      // },
    },
    // elements: {
    //   point: {
    //     radius: 0, // Hide data points
    //   },
    // },
    // layout: {
    //   padding: {
    //     left: 0,
    //     right: 0,
    //     top: 0,
    //     bottom: 0,
    //   },
    // },
    // maintainAspectRatio: false, // Allow chart to resize with container
    backgroundColor: "transparent", // Set the chart background to 'transparent'
  };

  return (
    <div className="w-full">
      <Line data={data} options={chartOptions} />
    </div>
  );
};




export default LineGraph