import { useNavigate } from 'react-router-dom';
import Wizard from "../../molecules/Wizard";
import {ExperienceStep1, ExperienceStep2, LocationStep1, LocationStep2, KitcheSpaceStep1, KitcheSpaceStep2, BookingStep1, BookingStep2, MiscellaneousStep1, MiscellaneousStep2, MiscellaneousStep3 } from "../../molecules/Questionaire";

export default function EditPreference() {
  const steps = [
    { component: ExperienceStep1 },
    { component: ExperienceStep2 },
    { component: LocationStep1 },
    { component: LocationStep2 },
    { component: KitcheSpaceStep1 },
    { component: KitcheSpaceStep2 },
    { component: BookingStep1 },
    { component: BookingStep2 },
    { component: MiscellaneousStep1 },
    { component: MiscellaneousStep2 },
    { component: MiscellaneousStep3},
    
  ]
  const navigate = useNavigate()

  return (
    <div className='flex flex-col font-open-sans bg-[#D9D9D9] h-[900px] sm:h-screen p-4 overflow-y-scroll'>
      <div className="grid grid-cols-1 gap-y-4 md:gap-y-10 justify-center align-middle p-10">
        <h1 className="mt-5 text-center text-3xl">HomeCook</h1>
        <h2 className="text-start text-xl text-gray-600">
          <span className="cursor-pointer text-gray-500 hover:text-gray-600" onClick={()=>navigate('/kitchens/preferences')}>Preferences</span> 
          <span className="text-gray-400">&nbsp;/&nbsp;</span> Edit
        </h2>
        <div className="grid items-center align-middle justify-center">
          <div className="w-screen px-28">
            <Wizard steps={steps} />
          </div>
        </div>
      </div>
    </div>
  );
}
