import homecook_logo from '../assets/img/homecook_logo.png'

const Navbar = () => {

  return (
    <div className="bg-primary-200 fixed w-full z-10">
      <div className="flex flex-row items-center">
      
        <img src={homecook_logo} alt="Kitchen Logo" className="w-16 " />
   
        <h1 className='text-white text-xl'>HomeCook</h1>
      </div>
    </div>
  );
}

export default Navbar;
