import { useEffect} from "react";
import { useNavigate } from 'react-router-dom';

import { usePreferences } from "../../contexts/preferencecontext";
import { preferenceSettings } from "../../utils/enums";

const Preferences = () => {
  const preference_settings = preferenceSettings;
  const groupedPreferences = chunkArray(Object.values(preference_settings), 2);
  const {preferences, loadData} = usePreferences()
  
  const loadPreferences = async () => {if (loadData !== undefined) {loadData()}}

  const navigate = useNavigate()

  useEffect(()=> {
    document.title = 'HomeCook | Kitchen Preferences'
    loadPreferences()
    // eslint-disable-next-line
 }, [])
  const renderPreferences = (preferenceName: String, ) => {
    if (preferenceName === preferenceSettings.step1.category) {
      return (
        <div>
          {Object.values(preferenceSettings.step1.questions).map((question: any, index: number) => (
            <div key={index} className="grid gap-y-2 text-lg">
              <p className="text-gray-800">
                {question.title}
              </p>
              <p>
                {index === 0 ? preferences?.ownership: preferences?.experience}
              </p>
              <hr className="border-black" />
            </div>
          ))}
        </div>
      );
    }
    if (preferenceName === preferenceSettings.step2.category) {
      return (
        <div>
          {Object.values(preferenceSettings.step2.questions).map((question: any, index: number) => (
            <div key={index} className="grid gap-y-2 text-lg">
              <p className="text-gray-800">
                {question.title}
              </p>
              <p>
                {index === 0 ? preferences?.requirements: preferences?.community_type}
              </p>
              <hr className="border-black" />
            </div>
          ))}
        </div>
      );
    }
    if (preferenceName === preferenceSettings.step3.category) {
      return (
        <div>
          {Object.values(preferenceSettings.step3.questions).map((question: any, index: number) => (
            <div key={index} className="grid gap-y-2 text-lg">
              <p className="text-gray-800">
                {question.title}
              </p>
              <p>
               {index === 0 ? preferences?.location: preferences?.size}
              </p>
              <hr className="border-black" />
            </div>
          ))}
        </div>
      );
    }
    if (preferenceName === preferenceSettings.step4.category) {
      return (
        <div>
          {Object.values(preferenceSettings.step4.questions).map((question: any, index: number) => (
            <div key={index} className="grid gap-y-2 text-lg">
              <p className="text-gray-800">
                {question.title}
              </p>
              <p>
                {index === 0 ? preferences?.urgency: preferences?.availability}
              </p>
              <hr className="border-black" />
            </div>
          ))}
        </div>
      );
    }
  }
  console.log(preferences);
  console.log(preferences? preferences.experience: null);
  const navigateToEdit = (category: string) => {
    navigate(`/kitchens/preferences/edit/${encodeURIComponent(category)}`);
  };
  return (
    <div className="grid grid-cols-1 gap-y-4 md:gap-y-10 justify-center align-middle p-10">
      <h1 className="mt-5 text-center text-4xl">HomeCook</h1>
      <h2 className="text-xl text-gray-600">Preferences</h2>
      <div className="md:grid md:grid-cols-3 md:gap-10">
        {/* Update grid-cols-3 */}
        {groupedPreferences.map((group, rowIndex) => (
          <div key={rowIndex} className="grid grid-rows-2 md:gap-20 gap-y-10">
            {group.map((preference: any, colIndex: number) => (
              <div key={colIndex} className="w-full">
                {" "}
                {/* Set width to full */}
                <div className="container">
                  <div className="grid grid-flow-col pb-5">
                    <h1 className="text-2xl">{preference.category}</h1>
                    <span onClick={() => navigateToEdit(preference.category)} className="cursor-pointer"> 
                      <h1 className="text-right underline text-lg">edit</h1>
                    </span>
                  </div>
                </div>
                {renderPreferences(preference.category)}
              </div>
            ))}
          </div>
        ))}
        <div className="md:col-span-1 w-ful">
          <div className="grid gap-y-5">
            <div className="grid grid-flow-col">
              <h1 className="text-2xl">Miscellaneous</h1>
              <span onClick={() => navigateToEdit("Miscellaneous")}  className="cursor-pointer">
                <h1 className="text-right underline text-lg">edit</h1>
              </span>
            </div>
            <ul className="text-gray-700 bg-white rounded-md p-5 md:row-span-2 grid gap-y-5 grid-flow-row">
              <li>
                Kitchen Capacity - {preferences?.capacity}
              </li>
              <li>
              Kitchen Budget - {preferences?.budget}
              </li>
              <li>
              Payment Frequency - {preferences?.frequency} 
              </li>
            </ul>
            {/* <div className="grid grid-flow-col justify-end gap-x-6">
              <span onClick={() => navigate("/kitchens/preferences")}  className="cursor-pointer">
                <button className="rounded-lg px-3 bg-back-300 hover:bg-back-200 drop-shadow-lg">
                  Cancel
                </button>
              </span>
              <span onClick={() => navigate("/kitchens/preferences/edit")}  className="cursor-pointer">
                <button className="buttonsPrimary px-2 text-black">
                  Update All
                </button>
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

function chunkArray(array: any, size: any) {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size));
  }
  return chunkedArray;
}

export default Preferences;
