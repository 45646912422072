import { Route, Routes } from 'react-router-dom'
// import LoginHome from "../pages/unauth/LoginHome";
import Login from "../pages/unauth/Login";
import OTPForm from '../pages/unauth/Login/otpform'
import { useAuthState } from '../contexts/authcontext'


const UnAuthView = () => {
  let { isloggedin, isverified } = useAuthState()
  var view_otp_form = isloggedin && !isverified

  if (view_otp_form) {
    return <OTPAuthView />
  } else {
    return <LoginView />
  }
}


const OTPAuthView = () => {
  return (
    <div className='w-full mt-12 sm:w-full'>
      <Routes>
          <Route path="*" element={<OTPForm  />}  />
      </Routes>
    </div>
  )
}


const LoginView = () => {
  return (
    <div className='w-full mt-12 sm:w-full'>
      <Routes>
        <Route path="*" element={<Login />}  />
      </Routes>
    </div>
  )
}

export default UnAuthView;