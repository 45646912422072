import { useEffect} from "react"
import AuthView from './Auth'
import UnAuthView from './UnAuth'
import Navbar from "../components/Navbar";
import SideBar from "../components/SideBar"
import LoadingScreen from "../pages/shared/LoadingScreen"

import { useAuthState } from '../contexts/authcontext'
import { useLoadAuthUser } from "../hooks/authhooks";
import { useLoadCommunityKitchen } from '../hooks/kitchenhooks'

const DashboardRouter = () => {
  const { loadAuthUser, isLoading } = useLoadAuthUser()
  let { isLoadingKitchens } = useLoadCommunityKitchen()
  
  useEffect(() => {
    loadAuthUser()
     // eslint-disable-next-line
  }, [])

  var { isloggedin, isverified, primaryCommunity } = useAuthState()
  var view_postonboard = isloggedin && isverified

  if (isLoading || isLoadingKitchens){
    return (
      <LoadingScreen />
    )
  }
  

  return (
  <div className={`flex h-screen ${isloggedin?'md:ml-20':''} `}>
    {
      isloggedin ? <SideBar /> : <Navbar />
    }
    {
      view_postonboard ? <AuthView primaryCommunity={primaryCommunity} /> : <UnAuthView />
    }
  </div>
  )
}


export default DashboardRouter;
