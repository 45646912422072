import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useKitchenState } from "../contexts/kitchencontext";

type SearchProps = {
  searchType?: 'members' | 'requests' | 'kitchens' | 'reservations'  | 'messages' | 'inventory'
}


const Search: React.FC<SearchProps> = ({searchType}) => {
  const [input, setInput] = useState("");
  const { handleSearch } = useKitchenState();

  const handleClick = () => {
    if (handleSearch !== undefined){
      handleSearch(input)
    }
  };

  let placeholder = "Search by Kitchen Name";
  if (searchType === 'members'){
    placeholder = "Search members";
  } else if (searchType === 'reservations'){
    placeholder = 'Search Reservations'
  } else if (searchType === 'requests'){
    placeholder = 'Search Requests'
  } else if (searchType === 'messages'){
    placeholder = 'Search Messages'
  } else if (searchType === 'inventory'){
    placeholder = 'Search Inventory'
  }
  

  return (
    <div className={`flex ${(searchType === "messages") ? 'w-full' : 'w-4/5 md:w-2/5'}`}>
      <input
        type="text"
        className="form-input m-1 px-4 py-3 rounded w-full max-h-full bg-back-500"
        onChange={(event) => {
          setInput(event.target.value);
        }}
        placeholder={placeholder}
      />
      <button
        data-testid="searchButton"
        className={`buttonsPrimary ${(searchType === "messages") ? 'm-1' : 'ml-2'}  h-12 w-12 z-0`}
        onClick={handleClick}
      >
        <SearchIcon />
      </button>
    </div>
  );
};

export default Search;