import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom';
import ErrorIcon from "@mui/icons-material/Error";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReactComponent as CredentialIcon } from "../icons/credentialIcon.svg";
import { ReactComponent as PaymentIcon } from "../icons/PaymentIcon.svg";
import { useAddCredential, useGetCredentials, useGetPaymentMethods } from "../hooks/memberhooks";
import { CredentialSchema, MembershipSchema } from "../data/types"
// import { useAuthState } from '../contexts/authcontext';
import { CredentialDetailsModal, AddCredentialModal } from "../components/AppModal";
// import LoadingScreen from "../../pages/shared/LoadingScreen"
import { RequirementSchema } from '../data/types';


// import NotFound from "../pages/shared/notfound";
import LoadingScreen from "../pages/shared/LoadingScreen"
// import AllCredentialsView from "../pages/shared/AllCredentialsView";



type RequirementsChecklistProps = {
  // isLoadingCredentials: boolean
  // currentMember: MembershipSchema
  // currentCommunity: CommunitySchema
  currentMember: MembershipSchema
  requirements: RequirementSchema[]
  setAllCredentialsPage: React.Dispatch<React.SetStateAction<boolean>>
}


const RequirementsChecklist: React.FC<RequirementsChecklistProps> = ({currentMember, requirements, setAllCredentialsPage}) => {
  // const navigate = useNavigate();
  // var { authUser } = useAuthState()
  const emptyCredential: CredentialSchema = {};
  const [viewCredential, setViewCredential] = useState<CredentialSchema>(emptyCredential);

  const memberUsername = currentMember?.username ? currentMember.username : "";

  const { paymentMethods } = useGetPaymentMethods(memberUsername)

  const { addMemberCredential, isLoadingCredential  } = useAddCredential()
  const { memberCredentials, isLoadingCredentials } = useGetCredentials(memberUsername)

  

  // var communityRequirements = currentCommunity?.requirements ? currentCommunity.requirements : []
  const pay_requirements_length = (currentMember && currentMember?.plan_recurrence && !['hourly', 'daily'].includes(currentMember.plan_recurrence) && (requirements?.length || requirements?.length === 0)) ? requirements.length + 1 : requirements?.length

  // const percentRequirementsMet = () => {
  //   let count = 0;
  //   const attributeSet = new Set<any>();
  //   if (requirements && requirements.length > 0){
  //     // Loop through requirements and add unique attribute values to a set
  //     for (const req of requirements) {
  //       attributeSet.add(req['name']);
  //     }
  //   }

  //   if (memberCredentials){
  //     // Loop through memberCredentials and check for matches in the set
  //     for (const cred of memberCredentials) {
  //       if (attributeSet.has(cred['category']) && cred.is_valid) {
  //         count++;
  //         attributeSet.delete(cred['category']); // Avoid double counting duplicates within list2
  //       }
  //     }
  //   }

    
  //   return pay_requirements_length ? (count / pay_requirements_length) * 100 : 0
  // }


  const percentRequirementsMet = () => {
    let count = 0;
    const attributeSet = new Set<any>();
    if (requirements && requirements.length > 0){
      // Loop through requirements and add unique attribute values to a set
      for (const req of requirements) {
        attributeSet.add(req['name']);
      }
    }

    if (memberCredentials){
      // Loop through memberCredentials and check for matches in the set
      for (const cred of memberCredentials) {
        if (attributeSet.has(cred['category']) && cred.is_valid) {
          count++;
          attributeSet.delete(cred['category']); // Avoid double counting duplicates within list2
        }
      }
    }

    if (currentMember && currentMember?.plan_recurrence && !['hourly', 'daily'].includes(currentMember.plan_recurrence) && (requirements?.length || requirements?.length === 0)){
      if (paymentMethods && paymentMethods.length > 0){
        count++;
      }
    }

    return pay_requirements_length ? (count / pay_requirements_length) * 100 : 0
  }

  const requirementsMet = percentRequirementsMet()
  // const capitalizeString = (string: string) => string?.replace(/\w/, (c) => c?.toUpperCase());

  const handleSubmitCredential = (credential: CredentialSchema) => {
    if (memberUsername && credential) {
      credential.username = memberUsername;
      addMemberCredential(memberUsername, credential);
    }
  }

  const getCurrentCredential = (requirement: string) => {
    let credential = memberCredentials?.find(credential => credential.category === requirement)
    return credential ? credential : {} as CredentialSchema
  }
  
  const meetsRequirement = (requirement: string) => {
    let credential = memberCredentials?.find(credential => credential.category === requirement)
    return credential ? credential.is_valid : false
  }
  
  const [credentialModalIsOpen, setCredentialModalIsOpen] = useState<{
    addModal: boolean;
    viewModal: boolean;
  }>({ addModal: false, viewModal: false });
  
  return (
    <>
    <div className="flex flex-col justify-start items-center bg-white rounded-md h-auto w-auto sm:w-4/12 m-1 p-4 shadow-sm font-bold text-[18px]">
    <span className="text-md text-[#013847]/60">REQUIREMENTS MET - <span className={`${requirementsMet < 80 ? 'text-red-950/70' : 'text-green-800/70'} text-xl font-bold`}>{Math.round(requirementsMet)}%</span></span>
      {
        (isLoadingCredentials) ? ( 
          <div className="flex flex-col items-start my-5 mx-auto w-[80%] h-full">
            <LoadingScreen bgColor="inherit" height="h-full" />
          </div> 
        ) : (
          <div className="flex flex-col items-start my-5 mx-auto w-9/10 h-full">
            {
              (currentMember && currentMember?.plan_recurrence && !['hourly', 'daily'].includes(currentMember.plan_recurrence)) ? (
                <>
                <div
                  className={`flex flex-row my-5 justify-start w-full ${
                    false
                      ? "hover:bg-[#eef7fa]/80 hover:text-[#013847]/70"
                      : "hover:bg-red-50/50 hover:text-red-950/70"
                  } cursor-pointer rounded-md p-2`}

                  onClick={() => {
                    setViewCredential(getCurrentCredential("Payment Method"));
                    setCredentialModalIsOpen({
                      addModal: false,
                      viewModal: true,
                    });
                  }}
                  
                >
                  {/* {
                    (meetsRequirement('Payment Method')) ? (
                      <CheckCircleIcon style={{fontSize: 35}} color="success" className="my-auto mx-2" />
                    ) : (
                      (getCurrentCredential('Payment Method').id) ? (
                          <ReportProblemIcon color="warning" style={{fontSize: 35}}  className="my-auto mx-2" />
                      ) : (
                          <ErrorIcon color="error" style={{fontSize: 35}}  className="my-auto mx-2" />
                      )
                    )
                  } */}

                  {
                    (paymentMethods && paymentMethods.length > 0) ? (
                      <CheckCircleIcon style={{fontSize: 20}} color="success" className="my-auto ml-auto mx-1" />
                    ) : (
                      (getCurrentCredential('Payment Method').id) ? (
                          <ReportProblemIcon color="warning" style={{fontSize: 20}}  className="my-auto ml-auto mx-1" />
                      ) : (
                          <ErrorIcon color="error" style={{fontSize: 20}}  className="my-auto ml-auto mx-1" />
                      )
                    )
                  }
                  
                  <div className="flex flex-col mx-4 my-auto">
                    
                    <p className="font-normal text-sm text-primary-200/80">
                      {'Payment Method'.toUpperCase()}
                    </p>
                  </div>
                  <PaymentIcon className="ml-auto my-auto h-8 w-8" />
            
                </div>
                <CredentialDetailsModal
                  credential={viewCredential}
                  isOpen={credentialModalIsOpen.viewModal}
                  closeAppModal={() =>
                    setCredentialModalIsOpen({
                      addModal: false,
                      viewModal: false,
                    })
                  }
                  handleUpdateCredential={handleSubmitCredential}
                />
              </>
              ) : (<></>)
            }
            {
              requirements && requirements.length > 0 ? (
                requirements?.map((requirement, i) => {
                  return (
                  <>
                    <div
                      key={i}
                      className={`flex flex-row my-5 justify-start w-full ${
                        false
                          ? "hover:bg-[#eef7fa]/80 hover:text-[#013847]/70"
                          : "hover:bg-red-50/50 hover:text-red-950/70"
                      } cursor-pointer rounded-md p-2`}

                      onClick={() => {
                        setViewCredential(getCurrentCredential(requirement?.name ? requirement?.name : ""));
                        setCredentialModalIsOpen({
                          addModal: false,
                          viewModal: true,
                        });
                      }}
                      
                    >
                      {
                        (requirement && requirement?.name && meetsRequirement(requirement?.name)) ? (
                          <CheckCircleIcon style={{fontSize: 35}} color="success" className="my-auto mx-2" />
                        ) : (
                          (requirement?.name && getCurrentCredential(requirement?.name).id) ? (
                              <ReportProblemIcon color="warning" style={{fontSize: 35}}  className="my-auto mx-2" />
                          ) : (
                              <ErrorIcon color="error" style={{fontSize: 35}}  className="my-auto mx-2" />
                          )
                        )
                      }
                      
                      <div className="flex flex-col mx-4 my-auto">
                        
                        <p className="font-normal text-sm text-primary-200/80">
                          {
                            requirement?.name ? requirement.name?.toUpperCase() : ""
                          }
                        </p>
                      </div>
                      <CredentialIcon className="ml-auto my-auto h-8 w-8" />
                
                    </div>
                    <CredentialDetailsModal
                      credential={viewCredential}
                      isOpen={credentialModalIsOpen.viewModal}
                      closeAppModal={() =>
                        setCredentialModalIsOpen({
                          addModal: false,
                          viewModal: false,
                        })
                      }
                      handleUpdateCredential={handleSubmitCredential}
                    />
                  </>
                  );
                })
              ) : (
                (currentMember && currentMember?.plan_recurrence && !['hourly', 'daily'].includes(currentMember.plan_recurrence)) ? (<></>
                ) : (
                  <p className='text-xs text-gray-400 italic'>No Membership Requirements</p>
                )
              )
            }
            {
              (isLoadingCredential) ? ( 
                <div className="flex flex-col items-start my-5 mx-auto w-[80%] h-full">
                  <LoadingScreen bgColor="inherit" height="h-full" />
                </div> 
              ) : (<></>)
            }
          </div>
        )
      }
      <div className="flex flex-row justify-between mt-24 w-full">
        <div
          className="font-light text-[16px] text-[#5DB6CE] cursor-pointer hover:text-[#013847]"
          onClick={() =>
            setCredentialModalIsOpen({
              addModal: true,
              viewModal: false,
            })
          }
        >
          + New Credential
        </div>
        <AddCredentialModal
          isOpen={credentialModalIsOpen.addModal}
          closeAppModal={() =>
            setCredentialModalIsOpen({
              addModal: false,
              viewModal: false,
            })
          }
          handleSubmitCredential={handleSubmitCredential}
        />
        <div
          className="font-light text-[16px] text-[#5DB6CE] cursor-pointer hover:text-[#013847]"
          onClick={() => setAllCredentialsPage(true)}
        >
          All Credentials
        </div>
      </div>
    </div>
    </>
  )
}


export default RequirementsChecklist