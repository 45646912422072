import { BrowserRouter as Router } from 'react-router-dom'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

import DashboardRouter from "./routers";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Router basename="/">
          <DashboardRouter />
        </Router>
      </div>
    </QueryClientProvider>
  );
}

export default App;