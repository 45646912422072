import React, { useState, useEffect } from "react";
import { states } from "../../../utils/enums";
import homecook_logo from '../../../assets/img/homecook_logo.png'
import { AccountSchema, CommunitySchema, LoginProps } from "../../../data/types";


// const homecook_logo = 'homecookapp.com.s3-website-us-east-1.amazonaws.com/static/media/homecook_logo.1ca10013af71605db8e8.png'


const SignUp: React.FC<LoginProps> = ({toggleSignIn, registerBusiness}) => {
    useEffect(()=> {
        document.title = 'Signup | HomeCook'
    }, [])
    // let { registerUser, toggleSignIn }: LoginProps = props
    const [account, updateAccount] = useState<AccountSchema>({})
    const [business, setBusiness] = useState<CommunitySchema>({});
    const [isCommunityForm, toggleCommunityForm] = useState<boolean>(false)

    const goToNext = async () => {
        // if (account && account.email && registerUser !== undefined){
        //     var user_to_register: AccountSchema = account
        //     registerUser(user_to_register)
        // }
        toggleCommunityForm(true)
    }

    const goBack = async () => {
        toggleCommunityForm(false)
    }

    const goToOTP = async () => {
        if (account && account.email && business && business.logo && business.bgPhoto && registerBusiness !== undefined){
            registerBusiness(business, account)
        }
    }

    return (
        <div className='flex w-screen mx-auto p-auto items-start justify-evenly h-[900px] sm:h-screen overflow-y-scroll bg-[#D9D9D9] md:relative'>
            <div className="relative mx-auto">
                <div className="flex flex-col justify-evenly align-middle w-[320px] mx-auto">
                    <img src={homecook_logo} alt="HomeCook" className='w-[100px] mx-auto cursor-pointer' onClick={()=> null} />
                    <h4 className=" text-center m-[1%] text-[20px] text-[#2D3748]">Do You Own A Kitchen?</h4>
                    {
                        (isCommunityForm) ? (
                            <CommunitySignUp account={account} business={business} setBusiness={setBusiness} goBack={goBack} goToOTP={goToOTP} />
                        ) : (
                            <EmailSignUp account={account} updateAccount={updateAccount} goToNext={goToNext} />
                        )
                    }
                    <p className="text-[12px] text-[#a9b2c0] text-center font-extralight cursor-pointer hover:text-[#5DB6CE]" onClick={toggleSignIn}><span className="text-[#013847]">Already have an Account? </span>Sign in</p>
                </div>
           </div>
        </div>
    )
}



type EmailSignUpProps = {
    account: AccountSchema
    updateAccount: React.SetStateAction<React.Dispatch<AccountSchema>>
    goToNext: () =>void
}

const  EmailSignUp: React.FC<EmailSignUpProps> = props => {
    // const [account, updateAccount] = useState<AccountSchema>({})
    var {account, updateAccount, goToNext} = props
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target
        e.preventDefault()
        updateAccount((prevAccount) => ({
            ...prevAccount,
            [name]: value,
        }))
    }

    return (
    <>
    <p className="text-[16px] text-[#969eab] text-center">Enter Contact Information</p>
      <div className="flex-row w-[100%]">
        <input placeholder="First Name" value={account.first_name} className="h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000] text-start" name="first_name" type="text" onChange={handleChange} />
        <input placeholder="Last Name" value={account.last_name} className="h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000] text-start" name="last_name" type="text" onChange={handleChange} />
        <input placeholder="Email Address" value={account.email} className='h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000] text-start' name="email" type="email" onChange={handleChange} onKeyDown={e => (e.key === 'Enter' && account.email) ? goToNext() : null } />
      </div>
      <button className="bg-[#5DB6CE] text-[#013847]  m-3 border-0 font-open-sans buttonsPrimary h-10" disabled={!(account && account.email)}  onClick={() => goToNext()}>NEXT</button>
    </>
    )
}



type CommunitySignUpProps = {
    account: AccountSchema
    business: CommunitySchema
    setBusiness: React.SetStateAction<React.Dispatch<CommunitySchema>>
    goBack: ()=> void
    goToOTP: ()=> void
}

const CommunitySignUp: React.FC<CommunitySignUpProps> = props => {

    var { account, business, setBusiness, goBack, goToOTP } = props

    const [selectedState, setSelectedState] = useState<string>("")
    const [width, setWidth] = useState<string>("0")
    const [length, setLength] = useState<string>("0")
    const [selectedbgPhoto, setSelectedbgPhoto] = useState("");
    const [selectedLogo, setSelectedLogo] = useState("");

    

    const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedState(event.target.value);
        setBusiness((prevKitchen) => ({
          ...prevKitchen,
          state: event.target.value,
        }));
    };
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setBusiness((prevKitchen) => ({
                ...prevKitchen,
                [name]: value,
            })
        )
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, files } = event.target;
        if (name && files && files.length > 0) {
            let file_data = files[0];
            setBusiness((prevKitchen) => ({
                ...prevKitchen,
                [name]: file_data,
            }))
        }
    }


    const handleImageChange = (
        imgType: string,
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const file = event.target.files?.[0];
        if (file) {
          // Read the selected image file as a data URL
          const reader = new FileReader();
          reader.onload = (e) => {
            if (imgType === "logo") {
              setSelectedLogo(e.target?.result as string);
            } else if (imgType === "bgPhoto") {
              setSelectedbgPhoto(e.target?.result as string);
            }
          };
          reader.readAsDataURL(file);
        } else {
          if (imgType === "logo") {
            setSelectedLogo("");
          } else if (imgType === "bgPhoto") {
            setSelectedbgPhoto("");
          }
        }
        handleFileChange(event);
      };

    const handleLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setLength(value);
        setBusiness((prevKitchen) => ({
            ...prevKitchen,
            size: `${width}x${value}`
        }))
    }

    const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setWidth(value);
        setBusiness((prevKitchen) => ({
            ...prevKitchen,
            size: `${value}x${length}`
        }))
    }

    return (
        <>
        <p className="text-[16px] text-[#969eab] text-center">Enter Business Information</p>
        <div className="w-full grid grid-flow-row gap-y-5">
            <input
                placeholder="Business Name"
                name="name"
                className="h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000]  text-start"
                onChange={handleInputChange}
                value={business.name}
            />
            <div className="grid grid-cols-3 gap-x-5">
                <input
                    placeholder="Address"
                    className="h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000]  text-start col-span-2"
                    name="address"
                    value={business.address}
                    onChange={handleInputChange}
                />
                <input
                    placeholder="City"
                    className="h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000]  text-start"
                    name="city"
                    value={business.city}
                    onChange={handleInputChange}
                />
                {/* <input
                    placeholder="County"
                    className="h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000]  text-start"
                    name="county"
                    onChange={handleInputChange}
                /> */}
            </div>
            <div className="grid grid-cols-2 gap-x-5 w-full">
                <select
                    value={selectedState}
                    onChange={handleStateChange}
                    className="h-[50px] w-full m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847]  text-start text-slate-500"
                >
                    <option value="">Select a state</option>
                    {Object.values(states).map((state) => (
                    <option key={state} value={state}>
                        {state}
                    </option>
                    ))}
                </select>
                <input
                    placeholder="Zipcode"
                    className="h-[50px] w-full m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000]  text-start"
                    name="zipcode"
                    value={business.zipcode}
                    onChange={handleInputChange}
                />
            </div>
            <div className="flex flex-row text-sm justify-between">
                {/* <label className="text-slate-500">Background Image</label>
                <input
                    type="file"
                    placeholder="Background Image URL"
                    className="h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000]  text-start"
                    name="bgPhoto"
                    onChange={handleFileChange}
                /> */}

                <input
                    type="file"
                    accept="image/*"
                    onChange={e => handleImageChange("bgPhoto", e)}
                    className="hidden"
                    id="image-input-bg-photo"
                    name="bgPhoto"
                    required
                />

                {
                    (selectedbgPhoto) ? (
                      <img
                        id="image-input-bg-photo"
                        src={selectedbgPhoto}
                        alt="Kitchen Logo"
                        className="h-16 relative my-auto mx-2 max-w-sm max-h-32"
                    />) : (<></>)
                }
                <label
                    htmlFor="image-input-bg-photo"
                    className="text-xs rounded-md cursor-pointer my-auto bg-[#bbb] hover:bg-[#999] border-0 text-primary-300 hover:text-primary-200 p-1 px-2"
                >
                Add Background Image
                </label>

                <input
                    type="file"
                    accept="image/*"
                    onChange={e => handleImageChange("logo", e)}
                    className="hidden"
                    id="image-input-logo"
                    name="logo"
                    required
                />

                {
                  (selectedLogo) ?  (
                      <img
                        id="image-input-logo"
                        src={selectedLogo}
                        alt="Kitchen Logo"
                        className="h-16 relative my-auto mx-2 max-w-sm max-h-32"
                    />) : (<></>)
                }
                <label
                    htmlFor="image-input-logo"
                    className="text-xs rounded-md cursor-pointer my-auto bg-[#bbb] hover:bg-[#999] border-0 text-primary-300 hover:text-primary-200 p-1 px-2"
                >
                Add Logo
                </label>
            {/* 
                <label className="text-slate-500">Logo</label>
                <input
                    type="file"
                    placeholder="Logo URL"
                    className="h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000]  text-start"
                    name="logo"
                    onChange={handleFileChange}
                /> */}
            </div>
            <input
                placeholder="Website"
                className="h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000]  text-start"
                name="website"
                value={business.website}
                onChange={handleInputChange}
            />
            <div className="grid grid-cols-2 gap-x-5">
                <div className="grid grid-cols-2 gap-x-5">
                    <input
                        placeholder="Width"
                        className="h-[50px] w-full m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000]  text-start"
                        name="width"
                        value={width}
                        onChange={handleWidthChange}
                    />
                    <input
                        placeholder="Length"
                        className="h-[50px] w-full m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000]  text-start"
                        name="length"
                        value={length}
                        onChange={handleLengthChange}
                    />
                </div>
                <input
                    placeholder="Max Capacity"
                    className="h-[50px] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000]  text-start"
                    name="capacity"
                    value={business.capacity}
                    onChange={handleInputChange}
                />
            </div>
            <textarea
                placeholder="Description"
                className="h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000]  text-start"
                name="description"
                value={business.description}
                onChange={handleInputChange}
            />

            <div className="flex flex-row w-full justify-evenly">
                <button className="bg-[#777] text-[#eee] hover:bg-[#eee] hover:text-[#777]  m-3 border-0 font-open-sans buttonsPrimary h-10 w-full" onClick={() => goBack()}>BACK</button>
                <button className="bg-[#5DB6CE] text-[#013847]  m-3 border-0 font-open-sans buttonsPrimary h-10 w-full" disabled={!(account && account.email)}  onClick={() => goToOTP()}>SIGN UP</button>
            </div>
        </div>
        </>
    )
}


export default SignUp
