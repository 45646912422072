import React, { ReactNode,createContext, useContext, useState } from "react";
import { PreferenceSchema} from '../data/types'
import { getPreferences } from "../api/crud";


type PreferencesContextType = {
  communityId: string;
  preferences: PreferenceSchema | undefined;
  setPreferences: React.Dispatch<React.SetStateAction<PreferenceSchema | undefined>>;
  loadData: any;
  isClicked: Boolean;
  setClicked: React.Dispatch<React.SetStateAction<Boolean>>;
}

type PropsChildren = {
  children?: ReactNode;
  communityId: string
};

const PreferencesContext = createContext<PreferencesContextType | undefined>(undefined);

export const PreferencesProvider = ({ children, communityId }: PropsChildren) => {
  const [preferences, setPreferences] = useState<PreferenceSchema>();
  const [isClicked,setClicked] = useState<Boolean>(false);
  
  const loadData = async () => {
    let loadedPreferences = await getPreferences({communityId}); 
    setPreferences(loadedPreferences || []);
  };

  // Assuming getPreferences is a valid function

  return (
    <PreferencesContext.Provider value={{ communityId, preferences, setPreferences, loadData, isClicked,setClicked }}>
      {children}
    </PreferencesContext.Provider>
  );
};

export const usePreferences = () => {
  const context = useContext(PreferencesContext);
  if (context === undefined) {
    throw new Error("usePreferences must be used within a PreferencesProvider");
  }
  return context;
};



