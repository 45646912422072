import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import React, {useState} from 'react'
import { useMemberState } from '../contexts/membercontext'
import { useLoadMessages } from '../hooks/memberhooks'
import Search from './Search'
// import NewSearch from '../components/NewSearch'
import { GroupedMessageListType, MessageSchema } from "../data/types";
import { ReactComponent as Avatar } from '../icons/ChatbotIcon.svg'
import { ReactComponent as NewMessageIcon } from '../icons/NewMessage.svg'
import { NewMessageModal } from '../components/AppModal';

const capitalizeString = (string: string) => string?.replace(/\w/, c => c?.toUpperCase())


const UserListWindow = () => {
    var { currentChatMember, allThreadsByMembers, messageOpen,setMessageOpen } = useMemberState()

    return (
        <div className="">
            <div className='flex flex-row z-0'>
                <Search searchType='messages' />
                <NewMessageIcon className='text-primary-200 hover:text-primary-300 pt-2 mt-2 m-1 cursor-pointer' onClick={() => setMessageOpen(true)}/>
            </div>
            <NewMessageModal isOpen={messageOpen} closeAppModal={() => setMessageOpen(false)}/>

            <div>
                {/* CHATBOT MESSAGES */}
                <ChatUser currentChatMember={currentChatMember} member="chatbot" allThreadsByMembers={allThreadsByMembers}  />
                {/* MEMBER MESSAGES */}
                {
                    allThreadsByMembers !== undefined ? Object.keys(allThreadsByMembers).map((member: string, i: number) => {
                        return (member !== 'chatbot') ? (
                            <ChatUser key={i} currentChatMember={currentChatMember} member={member} allThreadsByMembers={allThreadsByMembers}  />
                        ) : null
                    }) : null
                }
            </div>
        </div>

    )
}


type ChatUserProps = {
    member: string
    currentChatMember: string
    allThreadsByMembers: GroupedMessageListType
}

const ChatUser: React.FC<ChatUserProps> = ({member, currentChatMember, allThreadsByMembers}) => {

    let chatbot_messages: MessageSchema[] = allThreadsByMembers !== undefined ? allThreadsByMembers['chatbot'] : []
    let chatbot_last_message = allThreadsByMembers && chatbot_messages ? chatbot_messages[chatbot_messages.length - 1] : null

    const {switchMember} = useLoadMessages()

    const handleClick = (member: string) => {
        switchMember(member)
    }

    const get_primary_text = (member: string) => {
        return (
            <p className="font-open-sans font-extrabold text-[14px] text-[#2D3748]">{(member === 'chatbot') ? capitalizeString(member) : member}</p>
        )
    }
    
    const get_secondary_text = (last_message: MessageSchema) => {
        return (
             <p className='text-[#7A7A7A] font-normal text-[10px]'>
               {
                   (last_message && (last_message.read_status || last_message.type === 'sent')) ? (<>last_message.content</>) : (
                       <>
                           <FiberManualRecordIcon style={{fontSize: 10, color: '#5db6ce'}}/> &nbsp;
                           <span style={{color: '#000'}}>{ (last_message?.content && last_message.content.length > 53) ? `${last_message.content.slice(0, 53)}...` : last_message?.content}</span>
                       </>
                   )
               }
             </p>
        )
    }


    
    if (member !== 'chatbot'){
        let messages: MessageSchema[] = allThreadsByMembers !== undefined ? allThreadsByMembers[member] : []
        let last_message = allThreadsByMembers && messages ? messages[messages.length - 1] : null
        return (
            <div style={{backgroundColor: (member === currentChatMember) ? '#eef7fa' : '#fff'}} className={`flex justify-start m-1 p-5 cursor-pointer h-[80px] rounded`} 
                    onClick={()=> {
                    handleClick(member)
                }}>
                    <Avatar className={`h-auto w-auto ${(member === 'chatbot') ? 'text-[#51a0b5]/90' : 'text-[#013847]/90'}`} />
                    <div className="flex flex-col mx-4">
                        <div>{get_primary_text(member)}</div>
                        {last_message ? <div>{get_secondary_text(last_message)}</div> : null}
                    </div>
                    
            </div>
        )
    } else {
        return(
        <div style={{backgroundColor: ('chatbot' === currentChatMember) ? '#eef7fa' : '#fff'}} className={`flex justify-start m-1 p-5 cursor-pointer h-[80px] rounded`} 
            onClick={()=> {
                handleClick('chatbot')
            }}>
            <Avatar className={`h-auto w-auto text-[#51a0b5]/90`} />
            <div className="flex flex-col mx-4">
                <div>{get_primary_text('chatbot')}</div>
                {chatbot_last_message ? <div>{get_secondary_text(chatbot_last_message)}</div> : null}
            </div>                  
        </div>
        )
    }
}


export default UserListWindow
