import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment'

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from '@mui/icons-material/Visibility';

// import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

// import { DeleteConfirmationModal } from "../components/AppModal";
import { useUpdateMembership } from '../hooks/memberhooks'
import { statusColors } from "../utils/enums";
import { styleThousandCommas, getMemberStatusColor } from "../utils/helpers";
// import { MembershipSchema } from "../data/types";


const colors: {[key: string]: string} = statusColors();
// const account_avatar = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`

const circle = (status: string) => {
  return (
    <div
      className={`z-0 w-2 h-2 animate-pulse ${colors[status]} rounded-full`}
    ></div>
  );
};

type MemberRecordProps = {
  member: any
  allSelected: boolean
  isRequests?: boolean
  setEditMember: React.Dispatch<React.SetStateAction<string>>
}

const MemberRecord: React.FC<MemberRecordProps> = (props) => {
  var navigate = useNavigate();

  // const [is_open, openCheck] = useState(false);
  // const [isClicked, setClick] = useState(false);
  const [selected, updateSelect] = useState(false);

  var { member, isRequests, setEditMember } = props
  // const [newMember, setMember] = useState<DSCommunitySchema>(member);
  const { updateMembership } = useUpdateMembership()

  const updateOpenView = () => {
    navigate(`/kitchens/members/${member.id}`) 
    // openCheck(true);
  }

  // const updateCloseView = () => {
  //   openCheck(false);
  // };

  // const approveRequest = () => {
  //  if (updateMembership !== undefined) {
  //     updateMembership({username: member.username, status: 'approved'})
  //  } 
  // }

    // const submitUpdate = (membershipChanges: MembershipSchema) => {
    //   updateMembership(membershipChanges)
    // }

    const declineRequest = () => {
      if (updateMembership !== undefined) {
        updateMembership({username: member.username, status: member.status === 'declined' ? 'removed' : 'declined'})
     } 
    }



  return member ? (
    <tr className="font-open-sans border-x-2 border-s-2 border border-stone-500">
      <td className="pl-5s w-auto p-1">
        {/* Uses the allselected prop from the kitchen list to set the check box*/}
        {props.allSelected ? (
          <CheckBoxIcon
            style={{ cursor: "pointer" }}
            onClick={() => updateSelect(false)}
          />
        ) : selected ? (
          <CheckBoxIcon
            style={{ cursor: "pointer" }}
            onClick={() => updateSelect(false)}
          />
        ) : (
          <CheckBoxOutlineBlankIcon
            style={{ cursor: "pointer" }}
            onClick={() => updateSelect(true)}
          />
        )}
      </td>

      {/* <td className="w-48 pr-5 text-center border-r-0 border-stone-500"> 
        {member.id}
      </td> */}

      <td className="recordItem w-auto border-r-0">
        {/* This provides a test if the edit button has been checked and if so to open a text field to change the value. */}
        {
          member.username
        }
      </td>

      <td className="recordItem w-auto border-r-0">
        {/* This provides a test if the edit button has been checked and if so to open a text field to change the value. */}
        {member?.first_name ? member.first_name : ''} &nbsp;
        {member?.last_name ? member.last_name : ''}
      </td>

      <td className="recordItem w-auto border-r-0"> {member?.team_name ? member?.team_name : "-"} </td>

      <td className="recordItem w-auto border-r-0">
        {
          <div className="flex justify-center items-center">
            {circle(member.status)}
            <span className="ml-2 p-1 w-full">
              {/* <div style={{background: getBookingStatusColor(member.status)}} className="rounded-full h-2 w-2"></div> */}
              <FiberManualRecordIcon style={{color: getMemberStatusColor(member.status).bgColor, fontSize: 15}} />
              &nbsp; {member.status.replace(/\b\w/g, (status: string) => status.toUpperCase())}
              </span>
          </div>
        }
      </td>

      <td className="recordItem w-auto border-r-0">
        {
          (member && member?.plan_rate && member?.plan_rate.toString().includes(".") )? (
            `$${styleThousandCommas(member.plan_rate.toString())} (${member.plan_recurrence})`
          ) : (
            `$${styleThousandCommas(member?.plan_rate.toString())}.00 (${member.plan_recurrence})`
          )
        }
      </td>

      <td className="recordItem w-auto border-r-0">
        {
          (member && (member?.member_balance || member?.member_balance === 0))? (
            `${styleThousandCommas(member.member_balance.toString())} hours`
          ) : (
            ` - `
          )
        }
      </td>

      <td className="recordItem w-auto border-r-0">
        {moment.utc(member?.joined).fromNow()}
      </td>

      <td className={`grid ${isRequests ? 'grid-cols-3' : 'grid-cols-1'}  text-slate-400`}>
        {
          (isRequests) ? (
            <>
              <EditIcon
                className="cursor-pointer text-[#013847]"
                onClick={() => setEditMember(member.username)}
              />

              
              {
                (member?.status === 'declined') ? (
                  <DoNotDisturbIcon 
                    className="cursor-pointer text-[#EB001B]"
                    onClick={() => declineRequest()}
                  />
                ) : (
                  <DeleteIcon
                    className="cursor-pointer text-[#EB001B]"
                    onClick={() => declineRequest()}
                  />
                )
              }
              
              <InfoIcon
                style={{ cursor: "pointer", color: "#5DB6CE"}}
                onClick={() => updateOpenView()}
              />
        
            </>
          ) : (
            <div className="flex flex-row justify-evenly">
              <EditIcon
                className="cursor-pointer text-[#013847]"
                onClick={() => setEditMember(member.username)}
              />
              <VisibilityIcon
                className="cursor-pointer text-[#5DB6CE]"
                onClick={() => updateOpenView()}
              />
            </div>
          )
        }
        

        {/* {is_open ? (
          <RemoveCircleIcon
            style={{ cursor: "pointer", color: "#777", margin: 2 }}
            onClick={() => updateCloseView()}
          />
        ) : (
          <InfoIcon
            style={{ cursor: "pointer", color: "#5DB6CE" }}
            onClick={() => updateOpenView()}
          />
        )} */}
        
      </td>
    </tr>
  ) : null;
};

export default MemberRecord;
