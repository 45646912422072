import { useState, useEffect } from "react";

import SearchBox from "../../components/SearchBox";
import MembersList from "../../components/MembersList";
import { AddMemberFormModal } from "../../components/AppModal";
import { useMemberState } from "../../contexts/membercontext";
import { useKitchenState } from "../../contexts/kitchencontext";
import { useLoadMemberships } from "../../hooks/memberhooks";
import EditMultipleMembers from '../../molecules/EditMultipleMembers'

const MembersPage = () => {
  var { memberships } = useLoadMemberships();
  const { membershipRequests, addingMembers, setAddingMembers } = useMemberState();
  const [add_member, toggleNewMember] = useState(false);

  const { currentKitchen } = useKitchenState()
  let kitchen_capacity = currentKitchen?.capacity ? parseInt(currentKitchen.capacity) : 1
  


  const closeAppModal = () => {
    toggleNewMember(false);
    setAddingMembers(false)
  };

  useEffect(() => {
    document.title = "HomeCook | Member List";
    // eslint-disable-next-line
  }, [memberships, membershipRequests]);

  return addingMembers ? (
    <div className="md:p-[3%] w-full bg-back-400 pt-16 md:pt-16 font-open-sans flex flex-col gap-10">
      <div>
        <h1 className="text-3xl font-bold text-center justify-self-center">Add New Member Details</h1>
        <h1 className="text-md text-center justify-self-center">Include plans & other membership details for new members.</h1>
      </div>
      <EditMultipleMembers closeAppModal={closeAppModal} cancelEditMember={() => setAddingMembers(false)}/>
    </div>
  ) : (
    <div>
      <div className="md:p-[1%] w-full bg-back-400 pt-16 md:pt-16 font-open-sans">
        <div className="grid">
          <SearchBox searchType="members" setIsOpen={toggleNewMember} />
          {membershipRequests && membershipRequests.length > 0 ? (
            <>
              <h1 className="flex justify-center text-xl text-[#AAA]">
                Membership Requests (
                {membershipRequests && membershipRequests.length
                  ? membershipRequests.length
                  : "0"}
                )
              </h1>
              {/* <div className="flex" style={{color: '#AAA'}}>
                        {(membershipRequests && membershipRequests.length) ? membershipRequests.length : "0"} Membership Requests
                    </div> */}
              <MembersList kitchen_capacity={kitchen_capacity} members={membershipRequests} isRequests />
            </>
          ) : (
            <></>
          )}

          {memberships && memberships.length ? (
            <>
              <h1 className="flex justify-center text-xl text-[#555]">
                Active Members (
                {memberships && memberships.length ? memberships.length : "0"})
              </h1>
              <MembersList kitchen_capacity={kitchen_capacity} members={memberships ? memberships : []} />
            </>
          ) : (
            <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/40 ">
              No members to show yet
            </div>
          )}
        </div>
      </div>

      {add_member ? (
        <AddMemberFormModal closeAppModal={closeAppModal} isOpen={add_member}  />
      ) : (
        <></>
      )}
    </div>
  );
};

export default MembersPage;
