import React, {ReactNode, useState, useContext, createContext} from 'react'
// import { useAuthState } from './authcontext'
import { BookingSchema, MembershipSchema, MessageSchema, GroupedMessageListType, MembershipStateType } from "../data/types";
// import { useKitchenState } from './kitchencontext';

const defaultContextVal: any = {}
const MemberContext = createContext(defaultContextVal);



const MemberStateProvider: React.FC<{children: ReactNode, communityId: string}> = props => {
    // let { authUser } = useAuthState()
    let { communityId } = props

    const [memberships, setMemberships] = useState<MembershipSchema[]>([]);
    const [membershipRequests, setMembershipRequests] = useState<MembershipSchema[]>([]);
    const [bookings, updateBookings] = useState<BookingSchema[]>([])
    const [currentBooking, updateCurrentBooking] = useState<BookingSchema>()
    const [currentMember, updateCurrentMember] = useState<MembershipSchema>()

    const [currentChatMember, changeChatMember] = useState<string>('')
    const [newMessageMember, changeNewMessageMember] = useState<string>('')
    const [messageOpen, setMessageOpen] =  useState(false)


    const [allMessages, updateAllMessages] = useState<MessageSchema[]>([])
    const [messageThread, updateMessageThread] = useState<MessageSchema[]>([])
    const [allThreadsByMembers, updateAllsThreadsByMember] = useState<GroupedMessageListType>({})
    const [addingMembers, setAddingMembers] = useState(false);
    const [emails, setEmails] = useState<string[]>([]);


    // var communityId = (authUser && 'primary_community' in authUser && authUser.primary_community) ? authUser.primary_community : ""


    let data = {
        communityId, memberships, bookings, membershipRequests, currentBooking, currentMember,
        setMemberships, setMembershipRequests, updateBookings, updateCurrentBooking, updateCurrentMember,

        addingMembers,emails,setAddingMembers,setEmails,
        messageOpen, setMessageOpen,
        currentChatMember, newMessageMember, 
        changeChatMember, changeNewMessageMember,

        allMessages, messageThread, allThreadsByMembers, 
        updateAllMessages, updateMessageThread, updateAllsThreadsByMember
     
    }
    return <MemberContext.Provider value={data} {...props} />
}

const useMemberState = () => {
    const context: MembershipStateType = useContext(MemberContext)
    if (context === undefined ){
        throw new Error(`useMemberState must be used within MemberStateProvider`)
    }
    return context
}

export { MemberStateProvider, useMemberState }
