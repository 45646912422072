import React from 'react'

import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

// import credentialIcon from '../icons/credentialIcon.svg'
import { ReactComponent as CredentialIcon } from '../icons/credentialIcon.svg'

import {  CredentialSchema } from "../data/types";

type CredentialDetailsModalProps = {
    credential: CredentialSchema
    handleUpdateCredential: (credential: CredentialSchema) => void
  }

const CredentialDetails: React.FC<CredentialDetailsModalProps> = ({credential, handleUpdateCredential}) => {

    const isExpired = (dateString: string) => {
        // Parse the 'MM/YYYY' formatted date into a TypeScript Date object
        const dateParts = dateString.split('/');
        if (dateParts.length === 2) {
        const month = parseInt(dateParts[0], 10); // Parse the month part
        const year = parseInt(dateParts[1], 10);  // Parse the year part

            // Check if month and year are valid numbers
            if (!isNaN(month) && !isNaN(year)) {
                // Create a Date object using the parsed values
                const parsedDate = new Date(year, month - 1, 1); // Subtract 1 from the month since months are zero-based

                // Get the current date
                const currentDate = new Date();

                // Compare the parsed date with the current date
                if (parsedDate < currentDate) {
                    return true
                }   
            }
        }
        return false
    }

    const capitalizeWords = (str: string) => {
        if (str){
          // Split the string into an array of words
          const words = str.split(' ');
          // Capitalize the first letter of each word
          const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
          // Join the capitalized words back into a string
          const capitalizedString = capitalizedWords.join(' ');
          return capitalizedString;      
        }
    }

    return (
    <div  className='flex flex-col justify-around'>
        <span className='flex flex-row p-1 m-auto'>
            <CredentialIcon className='my-auto mx-2 h-16 w-16' />
            <div className='my-auto mx-2 text-[18px] text-primary-200'>Credential Details</div>
        </span>
        <hr className='m-4 border-gray-300'/>
        {
            (credential?.is_valid) ? <span className='m-auto my-auto text-[20px] text-green-700'><CheckCircleIcon /> Valid</span> : <span  className='m-auto my-auto text-[20px] text-red-800'><ErrorIcon /> Needs Attention</span> 
        }
        {
            credential?.credential_number ? (
            <p className='font-normal text-[15px] m-auto text-primary-300'><b className='text-gray-700'>Credential Number:</b> {credential?.credential_number ? credential?.credential_number : ""}</p>
            ) : (<></>)
        }
        <p className='font-normal text-[14px] m-auto text-primary-300 '><b className='text-gray-700'>File:</b> {credential?.file_key ? credential.file_key.split("/").pop() : ""}</p>
        <p className='font-normal text-[15px] m-auto text-primary-300'><b className='text-gray-700'>Category:</b> {capitalizeWords(credential?.category ? credential?.category : "")}</p>
        <p className={`font-light text-[13px] m-auto my-2 text-gray-500 `}><span className={`${isExpired(credential?.expiration ? credential.expiration : ``) ? 'text-red-900' : 'italic'}`}>{ (credential?.expiration) ? `exp. ${credential.expiration}` : `No Expiration Date` }</span></p>
        <p>{credential.is_valid}</p>
        <hr className='m-4 border-gray-300'/>
        <div className='flex flex-row justify-center m-auto text-[12px]'>
            <span className='flex flex-col text-gray-400 hover:text-gray-400/70 cursor-pointer  m-2 p-1 items-center'><FullscreenIcon  /> Preview </span>
            <span className='flex flex-col text-green-800 hover:text-green-800/70 cursor-pointer  m-2 p-1 items-center'><PublishedWithChangesIcon  /> Re-verify </span>
            <span className='flex flex-col text-primary-300 hover:text-primary-300/70 cursor-pointer  m-2 p-1 items-center'><UploadIcon /> Re-upload </span>
            <span className='flex flex-col text-red-900 hover:text-red-900/70 cursor-pointer  m-2 p-1 items-center'><DeleteIcon /> Delete </span>
        </div>
        
    </div>
    )
}

export default CredentialDetails