import { useState, useEffect } from 'react'
// import LineGraph from '../../components/LineGraph'
import ChartLegend from '../../components/ChartLegend'

import SearchBox from '../../components/SearchBox'
import { InventorySchema } from '../../data/types'
import EquipmentTable from '../../molecules/EquipmentTable'
import { AddInventoryModal } from "../../components/AppModal";

import  { useLoadInventoryList } from '../../hooks/kitchenhooks'

import BeefIcon  from '../../icons/foodIcons/Beef.svg'
import BroccoliIcon from '../../icons/foodIcons/Broccoli.svg'
import CucumberIcon from '../../icons/foodIcons/Cucumber.svg'
import GrapesIcon from '../../icons/foodIcons/Grapes.svg'
import InventoryCard from '../../components/InventoryCard'


const tableHeaders: string[] = ['Name', 'Category', 'Unit Price (USD)', 'Qty (Available)', 'Qty (Total)', 'Action'];



const foodLegends: {name: string, color: string, Icon: string, currentPrice: string}[] = [
  {name: 'Grapes', color: '#8B3656', Icon: GrapesIcon, currentPrice: '3.49'},
  {name: 'Broccoli', color: '#9CCE5D', Icon: BroccoliIcon, currentPrice: '3.28'},
  {name: 'Cucumber', color: '#D9D9D9', Icon: CucumberIcon, currentPrice: '1.20'},
  {name: 'Beef', color: '#F34F26', Icon: BeefIcon, currentPrice: '4.86'}
]



const Inventory = () => {
  useEffect(() => {
    document.title = `HomeCook`
  }, [])

  const [addView, toggleAddView] = useState<boolean>(false)
  const [listView, toggleListView] = useState<boolean>(true)

  const { inventoryList } = useLoadInventoryList()

  return (
    <div className="md:p-[1%] pt-16 md:pt-16 mx-auto w-full flex flex-col font-open-sans h-[900px] sm:h-screen overflow-y-scroll bg-[#D9D9D9]">
      <SearchBox searchType="inventory" setIsOpen={()=> toggleAddView(true)} />

      <div className='flex flex-row text-primary-200'>
        <span 
          onClick={() => toggleListView(true)} 
          className={`${!listView ? 'hover:text-primary-200 text-primary-300 cursor-pointer' : 'text-gray-500 '} text-xs text-center my-auto`}
          >
            List View
        </span>
        &nbsp;
        | 
        &nbsp;
        <span 
          onClick={() => toggleListView(false)} 
          className={`${listView ? 'hover:text-primary-200 text-primary-300 cursor-pointer' : 'text-gray-500 '} text-xs text-center my-auto`}
        >
          Icons View
        </span>
      </div>
      
      <div className='flex'>
            {
              listView ? (
                <div className="flex w-full mx-auto">
                  <div className="container mx-auto mt-8">
                    <EquipmentTable headers={tableHeaders} data={inventoryList ? inventoryList : []} />
                  </div>
                </div>
              ) : (
                <div className="flex mx-auto">
                  <div className={`container mx-auto mt-8 grid ${(inventoryList && inventoryList.length < 3) ? `md:grid-cols-${inventoryList.length} grid-cols-1` : 'md:grid-cols-3 grid-cols-1'} grid-flow-row-dense`}>
                    {
                      inventoryList && inventoryList.map((inv: InventorySchema, i: number) => <InventoryCard key={i} inventory={inv} />)
                    }
                  </div>
                </div>
              )
            }
        <div className="flex justify-evenly items-start mt-20 w-1/6 mx-auto">
          <div className='bg-[#013847] w-11/12 rounded-md'>
            <ChartLegend chartLegends={foodLegends} />
          </div>
        </div>
      </div>

      <AddInventoryModal isOpen={addView} closeAppModal={() => toggleAddView(false)} />
    </div>
    )
}

export default Inventory