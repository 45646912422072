import moment from "moment";
import { FilterOptionSchema } from "../data/types";


export const filterItemsByCategoryAndName = (data: FilterOptionSchema[], category: string, itemName: string): string[] => {
  console.log("data: ", data)
  const categoryData = data.find(cat => cat.category.toLowerCase() === category.toLowerCase());

  console.log("categoryData: ", categoryData)
  
  if (!categoryData) {
    return [];
  }
  
  return categoryData.items.filter(item => item.toLowerCase().includes(itemName.toLowerCase()));
}

const getDayName = (dateString: string): string => {
  // Parse the date string
  const date = moment(dateString, 'YYYY-MM-DD');
  // Get the day name
  return date.format('dddd');
}


const getDayOfMonth = (dateString: string): number  => {
  // Parse the date string
  const date = moment(dateString, 'YYYY-MM-DD');
  // Get the day of the month
  return date.date();
}
// import { membershipStatuses } from "../utils/enums";

const getBookingStatusColor = (status: string) => {
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case "in progress":
        return "#F69E35";
      case "approved":
        return "#9CCE5D";
      case "pending":
        return "#85bccb";
      case "canceled":
        return "#CE5D5D";
      case "completed":
        return "#013847";
      default:
        return "#38B2AC";
    }
  }
  return "#38B2AC"
};


const capitalizeString = (string: string) => string?.replace(/\w/, c => c?.toUpperCase())


type StatusOptionsType = 'pending' | 'verified' | 'declined' | 'inactive' | 'approved' | 'active' | 'removed'

const getMemberStatusOptions = (status: string): StatusOptionsType[] => {
  // var allOptions = membershipStatuses
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case "pending":
        return [status, "declined"]
        case "verified":
          return [status, "approved", "declined", "removed"]
      case "declined":
        return [status, "approved"]
      case "inactive":
        return [status, "approved"]
      case "approved":
        return [status, "removed"]
      case "active":
        return [status, "removed"]
      case "removed":
        return [status, "declined"]
      default:
        return [status as StatusOptionsType]
    }
  }
  return []
};

const getMemberStatusColor = (status: string): {bgColor: string, primaryTextColor: string, secondaryTextColor: string} => {
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case "active":
        return {bgColor: "#b9dc8d", primaryTextColor: '#4a5838', secondaryTextColor: '#819a62'};
      case "approved":
        return {bgColor: "#399f48", primaryTextColor: '#163f1c', secondaryTextColor: '#afd8b5'};
      case "verified":
        return {bgColor: "#F69E35", primaryTextColor: '#623f15', secondaryTextColor: '#fce1c2'};
      case "pending":
        return {bgColor: "#51a0b5", primaryTextColor: '#204048', secondaryTextColor: '#a8cfda'};
      case "declined":
        return {bgColor: "#CE5D5D", primaryTextColor: '#522525', secondaryTextColor: '#e6aeae'};
      case "inactive":
        return {bgColor: "#9a9a9a", primaryTextColor: '#3d3d3d', secondaryTextColor: '#d6d6d6'};
      case "removed":
        return {bgColor: "#668790", primaryTextColor: '#202124', secondaryTextColor: '#696969'};
      default:
        return {bgColor: "#bbb", primaryTextColor: '#202124', secondaryTextColor: '#696969'};
    }
  }
  return {bgColor: "#bbb", primaryTextColor: '#202124', secondaryTextColor: '#696969'};
};


const lightenColor = (hex: string, percent: number): string => {
  const color = parseInt(hex.replace(/^#/, ''), 16);
  const R = ((color >> 16) + percent);
  const G = (((color >> 8) & 0x00FF) + percent);
  const B = ((color & 0x0000FF) + percent);
  const newColor = ((R << 16) | (G << 8) | B).toString(16);
  return `#${newColor.padStart(6, '0')}`;
}


const darkenColor = (hex: string, percent: number): string => {
  const color = parseInt(hex.replace(/^#/, ''), 16);
  const R = ((color >> 16) - percent);
  const G = (((color >> 8) & 0x00FF) - percent);
  const B = ((color & 0x0000FF) - percent);
  const newColor = ((R << 16) | (G << 8) | B).toString(16);
  return `#${newColor.padStart(6, '0')}`;
}


type AnyObject = Record<string, any>; // A type for any object with string keys

const getChangedAttributes = <T extends AnyObject>(
  originalObj: T,
  newObj: T
): T => {
  const changedAttributes: T = {} as T;

  for (const key in originalObj) {
    if (originalObj.hasOwnProperty(key)) {
      if (newObj.hasOwnProperty(key) && newObj[key] !== originalObj[key]) {
        changedAttributes[key] = newObj[key];
      }
    }
  }

  return changedAttributes;
}


const styleThousandCommas = (number: string | number) => {
  if (typeof number === 'number'){
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else if (typeof number === 'string'){
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return number
}


const maxHours = (capacity: number, recurrence: string | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'annual') => {
  if (recurrence && capacity){
    switch (recurrence){
      // case 'daily':
      //   // 24 hrs
      //   return Math.round(16 / capacity)
      case 'weekly':
        // 168 hrs
        return Math.round(115 / capacity)
      case 'monthly':
        // 730 hrs
        return Math.round(500 / capacity)
      case 'annual':
        // 8760 hrs
        return Math.round(6000 / capacity)
      default:
        return 0
    }
  }
  return 0
}

export {
  getDayName,
  getDayOfMonth,
  capitalizeString,
  maxHours,
  getMemberStatusOptions,
  lightenColor,
  darkenColor,
  getBookingStatusColor,
  getMemberStatusColor,
  getChangedAttributes,
  styleThousandCommas
}