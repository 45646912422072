import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineLink } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

// import moment from 'moment' 

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import { ReactComponent as ScrollUpIcon } from '../../icons/ScrollUpIcon.svg'
// import { ReactComponent as ScrollDownIcon } from '../../icons/ScrollDownIcon.svg'

import { ReactComponent as LocationIcon } from "../../icons/locationIcon.svg";

// import { useAuthState } from '../../contexts/authcontext'
// import { useMemberState } from "../../contexts/membercontext";
import { useKitchenState } from "../../contexts/kitchencontext";

// import EditKitchenInfo from "./EditKitchenInfo"
import EditKitchenDetails from "./EditKitchenDetails";
import { AddPlanModal, AddRequirementModal } from "../../components/AppModal";

import { useLoadCurrentKitchen, useKitchenHook } from "../../hooks/kitchenhooks";
import { useLoadMemberships } from "../../hooks/memberhooks";
import { styleThousandCommas, getChangedAttributes } from "../../utils/helpers";
import { KitchenSpaceSchema, KitchenOptionsType, selectedDaysType, KitchenOptionFields } from "../../data/types";

// import AllCredentialsView from "./AllCredentialsView";

// import {CreateBooking } from '../../molecules/views/CreateBooking'
// import  EquipmentsWidget from '../../molecules/views/EquipmentsWidget'

// TO BE LOADED FROM COMMUNITIES / REQUIRED DOCS

// const credentialCategories = ["ID", "license", "certificate", "insurance", "other"]
import config from '../../config';

const KitchenWebUrl = config?.webUrl ? config.webUrl : ""

const account_avatar: string = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`;

const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};

type RouteParams = {
  kitchenid: string;
};

const KitchenDetails = () => {
  var navigate = useNavigate();
  const { kitchenid } = useParams<RouteParams>();

  var { plans, requirements } = useKitchenState()
  // var { memberships } = useMemberState();
  var {memberships, isLoadingMemberships} = useLoadMemberships()
  var { currentKitchen, isLoadingCurrentKitchen, reloadCurrentKitchen } = useLoadCurrentKitchen(kitchenid ? kitchenid : "")
  const kitchenName = currentKitchen?.name ? currentKitchen.name : "";

  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenReq, setIsOpenReq] = useState(false);

  

  const currentKitchenOptions = (currentKitchen && currentKitchen?.kitchen_options) ? currentKitchen.kitchen_options : {} as KitchenOptionsType
  // console.log("KitchenDetails currentKitchenOptions: ", currentKitchenOptions)

  // const [kitchenOptions, setKitchenOptions] = useState<KitchenOptionsType>(currentKitchenOptions);
  // console.log("KitchenDetails kitchenOptions: ", kitchenOptions)

  // const defaultSelectedDays = {
  //   sun: (currentKitchenOptions?.sun && currentKitchenOptions.sun?.open_time && currentKitchenOptions.sun?.close_time) ? true :  false,
  //   mon: (currentKitchenOptions?.mon && currentKitchenOptions.mon?.open_time && currentKitchenOptions.mon?.close_time) ? true :  false, 
  //   tue: (currentKitchenOptions?.tue && currentKitchenOptions.tue?.open_time && currentKitchenOptions.tue?.close_time) ? true :  false,
  //   wed: (currentKitchenOptions?.wed && currentKitchenOptions.wed?.open_time && currentKitchenOptions.wed?.close_time) ? true :  false, 
  //   thu: (currentKitchenOptions?.thu && currentKitchenOptions.thu?.open_time && currentKitchenOptions.thu?.close_time) ? true :  false, 
  //   fri: (currentKitchenOptions?.fri && currentKitchenOptions.fri?.open_time && currentKitchenOptions.fri?.close_time) ? true :  false, 
  //   sat: (currentKitchenOptions?.sat && currentKitchenOptions.sat?.open_time && currentKitchenOptions.sat?.close_time) ? true :  false
  // }

  // const [selectedDays, setSelectedDays] = useState<selectedDaysType>(defaultSelectedDays)

  const { updateKitchenSpace, updateOptions, isLoading } = useKitchenHook()

  const updateKitchenData = (kitchen: KitchenSpaceSchema, {kitchenOptions, selectedDays}: {kitchenOptions: KitchenOptionsType, selectedDays: selectedDaysType}) => {
    // var planChanges = getChangedAttributes(currentPlan,newPlan)
    if (kitchenid && currentKitchen){
      var changes = getChangedAttributes(currentKitchen, kitchen);
      
      if (Object.keys(changes).length > 0){
        updateKitchenSpace(kitchenid, changes);
      }
      
      // console.log("updateKitchenOptions currentKitchenOptions", currentKitchenOptions);
      // console.log("updateKitchenOptions kitchenOptions", kitchenOptions);
      // console.log("selectedDays", selectedDays)
      for (const day in selectedDays) {
        // console.log("day selectedDays ", day)
        // console.log("kitchenOptions[day] selectedDays", kitchenOptions[day as KitchenOptionFields])
        // console.log(`${day}: ${selectedDays[day]}`);
        let kitchenOption = kitchenOptions[day as KitchenOptionFields]
        if (!selectedDays[day] && kitchenOption && kitchenOptions[day as KitchenOptionFields]){
          delete kitchenOptions[day as KitchenOptionFields]
        }
      }
      // console.log("kitchenOptions 1", kitchenOptions)
      // var operatingHrsChanges = getChangedAttributes(currentKitchenOptions, kitchenOptions);
      // console.log("updateKitchenOptions operatingHrsChanges", operatingHrsChanges);

      if (kitchenOptions && Object.keys(kitchenOptions).length > 0){
        // remove non-operating hours
        if (kitchenOptions?.equipments) {delete kitchenOptions['equipments']}
        if (kitchenOptions?.closed_hours) {delete kitchenOptions['closed_hours']}
        if (kitchenOptions?.services) {delete kitchenOptions['services']}
        if (kitchenOptions?.features) {delete kitchenOptions['features']}
        if (kitchenOptions?.last_updated) {delete kitchenOptions['last_updated']}
        
        // console.log("kitchenOptions 2", kitchenOptions)
        
        updateOptions(kitchenid, kitchenOptions)
      }
    }
    setIsEditing(false);
  };

  
  useEffect(
    () => {
      document.title = `${kitchenName ? kitchenName : "Kitchen"} | Details`;
    }, // eslint-disable-next-line
    [currentKitchen, currentKitchenOptions ]
  );
  const reload = async() => {
    await reloadCurrentKitchen()
  }
  //   console.log("currentKitchen", currentKitchen)
  if (isLoadingCurrentKitchen || isLoadingMemberships || isLoading){
    reload()
    return (
      <div
        role="status"
        className="grid items-center justify-center h-screen md:p-[1%] w-full bg-back-400 pt-16 md:pt-16"
      >
        <svg
          aria-hidden="true"
          className="w-28 h-28 mr-2 text-gray-200 animate-spin  fill-primary-400"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  } else if (currentKitchen && currentKitchen?.name && isEditing) {
    return (
      <EditKitchenDetails
        setIsEditing={setIsEditing}
        currentKitchen={currentKitchen}
        plans={plans}
        updateKitchenData={updateKitchenData}
        // selectedDays={selectedDays}
        // setSelectedDays={setSelectedDays}
      />
    );
  } else if (currentKitchen) {
    return (
      <div className="flex mx-auto w-full p-4 h-[900px] sm:h-screen overflow-y-scroll bg-[#D9D9D9]">
        <div className="font-raleway flex flex-col w-full relative items-start p-4">
          <>
            <ArrowBackIosIcon
              className="text-[#5DB6CE] hover:text-gray-500 mb-2 cursor-pointer"
              onClick={() => navigate("/kitchens")}
            />

            <div className="flex flex-row text-2xl text-[#000]">
              <img
                className="h-16 relative my-auto mx-2"
                alt={currentKitchen.logo}
                src={currentKitchen.logo}
              />
              <p className="my-auto">
                {currentKitchen ? `${currentKitchen.name}` : null}
              </p>
            </div>

            <p className="font-raleway w-full text-md flex flex-row mx-2 text-primary-200">
              <LocationIcon className="w-4 h-4 m-1" /> &nbsp;
              {currentKitchen && currentKitchen.address
                ? `${currentKitchen.address}, `
                : ``}
              {currentKitchen && currentKitchen.city
                ? `${currentKitchen.city}, `
                : ``}
              {/* {currentKitchen && currentKitchen.county
                ? `${currentKitchen.county}, `
                : ``} */}
              {currentKitchen && currentKitchen.state
                ? `${currentKitchen.state} `
                : ``}
              {currentKitchen && currentKitchen.zipcode}{" "}
              {/* {currentKitchen && currentKitchen.country} */}
              {/* <span
                className="flex flex-row text-sm text-[#013847]  hover:text-gray-400 cursor-pointer ml-4"
                onClick={() =>
                  currentKitchen && currentKitchen.website
                    ? openInNewTab(currentKitchen.website)
                    : null
                }
              >
                <AiOutlineLink className="mx-1" />
                Visit Website
              </span> */}
              <span
                className="flex flex-row text-xs font-bold bg-gray-400/50 hover:bg-gray-400/30 rounded-md p-1 text-primary-200  hover:text-primary-300 cursor-pointer ml-4"
                onClick={() =>
                  currentKitchen && currentKitchen.community_id
                    ? openInNewTab(`${KitchenWebUrl}/community/${currentKitchen.community_id}/0`)
                    : null
                }
              >
                <AiOutlineLink className="m-1" />
                <span className="m-1">Share Page</span>
              </span>
            </p>

            <div className="flex flex-row mx-2 mt-2 w-full">
              <p className="flex flex-row text-sm mx-2">
                {memberships &&
                memberships.length !== 0 &&
                memberships.length < 4 ? (
                  Array.from({ length: memberships.length }).map((_, i) => {
                    return (
                      <img
                        className="relative h-5 rounded-full -ml-1"
                        key={i}
                        alt={account_avatar}
                        src={account_avatar}
                      />
                    );
                  })
                ) : (
                  <img
                    className="relative h-5 rounded-full"
                    alt={account_avatar}
                    src={account_avatar}
                  />
                )}
                &nbsp;{" "}
                {memberships && memberships.length ? memberships.length : "0"}
                {memberships && memberships.length === 1
                  ? " Member"
                  : " Members"}
              </p>

              <button
                className="flex flex-row justify-evenly py-1 px-2 py-auto text-sm z-0 ml-auto bg-[#bbb] hover:bg-[#999] border-0 text-[#013847] rounded-md"
                onClick={() => setIsEditing(true)}
              >
                <FiEdit className="m-auto" /> &nbsp;
                <p className="m-auto">Edit</p>
              </button>
            </div>

            <div className="flex flex-row justify-evenly w-full h-[300px] my-2">
              {currentKitchen?.bgPhoto ? (
                <img
                  className="object-cover w-full"
                  src={currentKitchen?.bgPhoto}
                  alt={currentKitchen?.name}
                />
              ) : (
                <>
                  <div className="h-[280px] w-9/12 rounded-md m-1  bg-slate-400"></div>
                  <div className="flex flex-col h-[280px] w-3/12 rounded-md m-1">
                    <div className="h-1/2 w-auto rounded-md m-1  bg-slate-400"></div>
                    <div className="h-1/2 w-auto  rounded-md m-1 bg-slate-400"></div>
                  </div>
                </>
              )}
            </div>

            <div className="flex flex-col sm:flex-row justify-between sm:w-full">
              <div className="sm:grid sm:grid-cols-4 flex flex-row w-full grid-flow-row-dense">
                <div className="col-span-4 sm:w-full">
                  <div className="flex flex-col mt-4 font-raleway">
                    <p className=" font-bold text-[#000000]  text-lg">
                      Description
                    </p>
                    <p className=" text-[#555] text-md font-open-sans">
                      {currentKitchen ? currentKitchen.description : null}
                    </p>
                  </div>

                  <div className="flex flex-col mt-4">
                    <p className="font-raleway font-bold text-[#000] text-lg">
                      Membership Plans{" "}
                      <span
                        className="cursor-pointer border border-primary-300 p-1 rounded-lg text-primary-300  hover:bg-primary-400 hover:text-white sm:ml-4 px-3"
                        onClick={() => setIsOpen(true)}
                      >
                        + Add New Plan
                      </span>
                    </p>
                    {plans ? (
                      <div className="flex flex-row justify-items-start font-normal">
                        {plans.map((plan, i) => (
                          <div key={i} className="font-open-sans text-md sm:mr-auto">
                            <div>
                              <span className="font-medium text-[#555]">
                                {plan.plan_name && plan.plan_name.toUpperCase()}
                              </span>
                              <div className="text-[#555]/80">
                                ${styleThousandCommas(plan?.plan_rate ? plan.plan_rate : "")} ({plan.plan_recurrence})
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex flex-col mt-4">
                    <p className="font-raleway font-bold text-[#000] text-lg">
                      Membership Requirements{" "}
                      <span
                        className="cursor-pointer border border-primary-300 p-1 rounded-lg text-primary-300 hover:bg-primary-400 hover:text-white sm:ml-4 px-3"
                        onClick={() => setIsOpenReq(true)}
                      >
                        + Add New Requirement
                      </span>
                    
                    </p>

                    {requirements ? (
                      <ul className="flex flex-row w-full sm:w-2/3 justify-items-start font-normal list-disc sm:ml-4">
                        {requirements.map((requirement, i) => (
                          <li key={i} className="font-open-sans text-md mr-auto">
                              <span className="font-medium text-[#555]">
                                {requirement.name &&
                                  requirement.name.toUpperCase()}
                              </span>
                              {/* <div>{requirement.description}</div> */}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="flex flex-col mt-4">
                    <p className="font-raleway font-bold text-[#000] text-lg">
                      Kitchen Size
                    </p>
                    <span className="text-[#555] font-open-sans">{currentKitchen.size} sqft</span>
                  </div>

                  <div className="flex flex-col mt-4">
                    <p className="font-raleway font-bold text-[#000]  text-lg">
                      Kitchen Capacity
                    </p>
                    <span className="text-[#555] font-open-sans">
                      {currentKitchen.capacity}
                      {currentKitchen.capacity === "1" ? " Person" : " People"}
                    </span>
                  </div>
                  {/* <div className='flex flex-col mt-4'>
                                      <p className='font-raleway font-bold text-[#000]  text-lg'>Kitchen Requirements</p>
                                      <div className="grid grid-cols-2 justify-items-start font-normal w-1/2">
                                      {
                                        (credentialCategories && credentialCategories.length > 0) ? (
                                              credentialCategories.map((cat, i) => <div className="font-raleway text-md mr-auto" key={i}>{cat.toUpperCase()}</div>)
                                        ) : (<></>)
                                      }
                                      </div>
                                  </div> */}
                </div>

                <div className="sm:w-full"></div>
                <AddPlanModal
                  kitchen={currentKitchen}
                  isOpen={isOpen}
                  closeAppModal={() => setIsOpen(false)}
                  />
                <AddRequirementModal
                  isOpen={isOpenReq}
                  closeAppModal={() => setIsOpenReq(false)}
                  />
              </div>
              {/* <div className="border-r-2 border-primary-200"></div> */}

              <div className="grid sm:grid-cols-2 grid-cols-1 w-full sm:w-max grid-flow-row-dense">
                  {
                    (
                      currentKitchenOptions?.sun || currentKitchenOptions?.mon || currentKitchenOptions?.tue
                      || currentKitchenOptions?.wed || currentKitchenOptions?.thu || currentKitchenOptions?.fri 
                      || currentKitchenOptions?.sat
                    ) ? (
                    <div className="flex flex-col font-open-sans mt-4 bg-slate-200 rounded-md p-3 w-max h-max">
                        <p className="font-raleway font-bold text-lg">
                          Operating Hours
                        </p>
                        <div className="flex flex-row w-full text-[#555] font-open-sans">
                          <div className="flex flex-col text-md font-bold p-2">
                            {/* {(currentKitchenOptions?.sun && currentKitchenOptions?.sun?.open_time) ? (<span className="my-2">Sun &nbsp; &nbsp; &nbsp; </span>): <div className="my-2"></div>}
                            {(currentKitchenOptions?.mon && currentKitchenOptions?.mon?.open_time) ? (<span className="my-2">Mon &nbsp; &nbsp; &nbsp; </span>): <div className="my-2"></div>} 
                            {(currentKitchenOptions?.tue && currentKitchenOptions?.tue?.open_time) ? (<span className="my-2">Tue &nbsp; &nbsp; &nbsp; </span>): <div className="my-2"></div>}
                            {(currentKitchenOptions?.wed && currentKitchenOptions?.wed?.open_time) ? (<span className="my-2">Wed &nbsp; &nbsp; &nbsp; </span>): <div className="my-2"></div>}
                            {(currentKitchenOptions?.thu && currentKitchenOptions?.thu?.open_time) ? (<span className="my-2">Thu &nbsp; &nbsp; &nbsp; </span>): <div className="my-2"></div>}
                            {(currentKitchenOptions?.fri && currentKitchenOptions?.fri?.open_time) ? (<span className="my-2">Fri &nbsp; &nbsp; &nbsp; </span>): <div className="my-2"></div>}
                            {(currentKitchenOptions?.sat && currentKitchenOptions?.sat?.open_time) ? (<span className="my-2">Sat &nbsp; &nbsp; &nbsp; </span>): <div className="my-2"></div>} */}
                            <span className="my-2">Sun &nbsp; &nbsp; &nbsp; </span>
                            <span className="my-2">Mon &nbsp; &nbsp; &nbsp; </span>
                            <span className="my-2">Tue &nbsp; &nbsp; &nbsp; </span>
                            <span className="my-2">Wed &nbsp; &nbsp; &nbsp; </span>
                            <span className="my-2">Thu &nbsp; &nbsp; &nbsp; </span>
                            <span className="my-2">Fri &nbsp; &nbsp; &nbsp; </span>
                            <span className="my-2">Sat &nbsp; &nbsp; &nbsp; </span>
                          </div>
                          <div className="flex flex-col text-md font-bold p-2">
                            {
                              (currentKitchenOptions && currentKitchenOptions?.sun) ? (<span className="text-left font-normal my-2 text-green-900">
                                {currentKitchenOptions?.sun?.open_time}
                                &nbsp; - &nbsp; 
                                {currentKitchenOptions?.sun?.close_time}
                                &nbsp; 
                              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
                            }

                            {
                              (currentKitchenOptions && currentKitchenOptions?.mon) ? (<span className="text-left font-normal my-2 text-green-900">
                                {currentKitchenOptions?.mon?.open_time}
                                &nbsp; - &nbsp; 
                                {currentKitchenOptions?.mon?.close_time}
                                &nbsp; 
                              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
                            }

                            {
                              (currentKitchenOptions && currentKitchenOptions?.tue) ? (<span className="text-left font-normal my-2 text-green-900">
                                {currentKitchenOptions?.tue?.open_time}
                                &nbsp; - &nbsp; 
                                {currentKitchenOptions?.tue?.close_time}
                                &nbsp; 
                              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
                            }

                            {
                              (currentKitchenOptions && currentKitchenOptions?.tue) ? (<span className="text-left font-normal my-2 text-green-900">
                                {currentKitchenOptions?.wed?.open_time}
                                &nbsp; - &nbsp; 
                                {currentKitchenOptions?.wed?.close_time}
                                &nbsp; 
                              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
                            }

                            {
                              (currentKitchenOptions && currentKitchenOptions?.thu) ? (<span className="text-left font-normal my-2 text-green-900">
                                {currentKitchenOptions?.thu?.open_time}
                                &nbsp; - &nbsp; 
                                {currentKitchenOptions?.thu?.close_time}
                                &nbsp; 
                              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
                            }

                            {
                              (currentKitchenOptions && currentKitchenOptions?.thu) ? (<span className="text-left font-normal my-2 text-green-900">
                                {currentKitchenOptions?.fri?.open_time}
                                &nbsp; - &nbsp; 
                                {currentKitchenOptions?.fri?.close_time}
                                &nbsp; 
                              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
                            }

                          {
                              (currentKitchenOptions && currentKitchenOptions?.sat) ? (<span className="text-left font-normal my-2 text-green-900">
                                {currentKitchenOptions?.sat?.open_time}
                                &nbsp; - &nbsp; 
                                {currentKitchenOptions?.sat?.close_time}
                                &nbsp; 
                              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
                            }
                          </div>
                        </div>
                    </div>
                    ) : (
                      <></>
                    )
                  }
              </div>
            </div>
          </>
        </div>
      </div>
    );
  } else {
    return (<></>)
  }
};

export default KitchenDetails;
