import React, { useState } from 'react';
import { format, addWeeks, addMonths, startOfWeek } from 'date-fns';


import MonthView from '../components/MonthView';
import WeekView from '../components/WeekView';
import { BookingSchema } from "../data/types";

type CalendarProps = {
    toggleCalendarView: React.Dispatch<React.SetStateAction<boolean>>
    bookings: BookingSchema[]
}

const Calendar: React.FC<CalendarProps> = ({bookings}) => {
  const [view, setView] = useState<'month' | 'week'>('week');
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const next = () => {
    setCurrentDate(view === 'month' ? addMonths(currentDate, 1) : addWeeks(currentDate, 1));
  };

  const prev = () => {
    setCurrentDate(view === 'month' ? addMonths(currentDate, -1) : addWeeks(currentDate, -1));
  };

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <div></div>
        <h2 className="text-lg font-semibold">
          {view === 'month'
            ? format(currentDate, 'MMMM yyyy')
            : `${format(startOfWeek(currentDate), 'dd MMM')} - ${format(
                addWeeks(startOfWeek(currentDate), 1),
                'dd MMM yyyy'
              )}`}
        </h2>
        <div></div>
      </div>

      {/* Toggle buttons */}
      <div className="mt-4 text-center">
        <button
          onClick={() => setView('week')}
          className={`${
            view === 'week' ? 'bg-primary-200 text-white' : 'bg-gray-200 text-gray-600'
          } py-2 px-4 rounded-r-md`}
        >
          Week
        </button>
        <button
          onClick={() => setView('month')}
          className={`${
            view === 'month' ? 'bg-primary-200 text-white' : 'bg-gray-200 text-gray-600'
          } py-2 px-4 rounded-l-md`}
        >
          Month
        </button>
      </div>

      <div className="mb-4 flex justify-between items-center text-slate-400">
        <button onClick={prev}>Previous</button>
        <button onClick={next}>Next</button>
      </div>

    {/* Render your calendar here based on the view */}
    {
        (view === 'month') ? (
            // Render the month view
            <MonthView currentDate={currentDate} bookings={bookings} />
        ) : (
            // Render the week view
            <WeekView currentDate={currentDate} bookings={bookings} />
        )
    }
    </div>
  );
};

export default Calendar;
