// import { useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { ReactComponent as LocationIcon } from "../icons/locationIcon.svg";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// import { useMember } from "../contexts/membercontext";
// import { useAuth } from "../contexts/authcontext";
import NotFound from "../pages/shared/notfound";
import LoadingScreen from "../pages/shared/LoadingScreen";

import { getBookingStatusColor, getDayOfMonth, styleThousandCommas, capitalizeString } from "../utils/helpers";
import { BookingSchema, MembershipSchema } from "../data/types";
import { useKitchenState } from "../contexts/kitchencontext";
import { useAddBooking } from '../hooks/memberhooks'

type BookingConfirmationProps = {
  currentBooking: BookingSchema | undefined;
  bookingNotes: string,
  paymentMethods: any[]
  updateSelectedPayMethod: React.Dispatch<React.SetStateAction<string>>
  selectedPayMethod: string
  updateBookingNotes: React.Dispatch<React.SetStateAction<string>>
  member: MembershipSchema | undefined;
  beforeBuffer: string;
  afterBuffer: string;
  setStep: (step: number) => void;
  equipment: string[];
  isOpen: boolean | undefined;
  handleClose: () => void;
  isMultiple?: boolean;
};
const BookingConfirmation = ({
  currentBooking,
  bookingNotes,
  paymentMethods,
  updateSelectedPayMethod,
  selectedPayMethod,
  updateBookingNotes,
  member,
  beforeBuffer,
  afterBuffer,
  setStep,
  isOpen,
  handleClose,
  equipment,
  isMultiple = false,
}: BookingConfirmationProps) => {
  var { currentKitchen } = useKitchenState();

  //   const loadCurrentBooking = async () => {
  //     var communityId =
  //       authUser && "primary_community" in authUser && authUser.primary_community
  //         ? authUser.primary_community
  //         : "";
  //     if (getBookingByID !== undefined && communityId && bookingId) {
  //         getBookingByID(communityId, bookingId);
  //     }
  //   };

  //   const approveBooking = () => {
  //     if (bookingId && currentBooking && updateBooking !== undefined ){
  //       let bookingUpdate: any = {status: "approved"}
  //       // delete bookingUpdate.start_time
  //       updateBooking(bookingId, bookingUpdate)
  //     }
  //   }

  //   const rejectBooking = () => {
  //     if (bookingId && currentBooking && updateBooking !== undefined ){
  //       let bookingUpdate: any = {status: "canceled"}
  //       updateBooking(bookingId, bookingUpdate)
  //     }
  //   }

  // const capitalizeWords = (str: string) => {
  //   if (str) {
  //     // Split the string into an array of words
  //     const words = str.split(" ");
  //     // Capitalize the first letter of each word
  //     const capitalizedWords = words.map(
  //       (word) => word.charAt(0).toUpperCase() + word.slice(1)
  //     );
  //     // Join the capitalized words back into a string
  //     const capitalizedString = capitalizedWords.join(" ");
  //     return capitalizedString;
  //   }
  // };

  // const getDayName = (dateString: string): string => {
  //   // Parse the date string
  //   const date = moment(dateString, 'YYYY-MM-DD');
  //   // Get the day name
  //   return date.format('dddd');
  // }
  
  
  // const getDayOfMonth = (dateString: string): number  => {
  //   // Parse the date string
  //   const date = moment(dateString, 'YYYY-MM-DD');
  //   // Get the day of the month
  //   return date.date();
  // }

  // const [bookingNotes, updateBookingNotes] = useState<string>("")

  const calculateDateDifference = (fromDate: string, toDate: string, booking_recurrence: string) => {
    var nOccurrences = 0
    if (fromDate && toDate){
      // Parse the dates
      const start = moment(fromDate, 'YYYY-MM-DD');
      const end = moment(toDate, 'YYYY-MM-DD');
      switch(booking_recurrence){
        case 'daily':
          nOccurrences = end.diff(start, 'days') + 1;
          return nOccurrences
        case 'weekly':
          nOccurrences = end.diff(start, 'weeks');
          let startDay = getDayOfMonth(fromDate)
          let endDay = getDayOfMonth(toDate)
          if (endDay >= startDay){
            nOccurrences += 1
          }
          return nOccurrences
        case 'monthly':
          nOccurrences = end.diff(start, 'months') + 1;
          return nOccurrences
        default:
          return 1
      }
    }
    return nOccurrences
  }

  //   useEffect(() => {
  //     loadCurrentBooking();
  //     document.title = `HomeCook | Reservation Details`;
  //   }, []); // eslint-disable-line react-hooks/exhaustive-deps

  var duration = currentBooking
    ? moment.duration(
        moment(currentBooking?.end_time)?.diff(
          moment(currentBooking?.start_time)
        )
      )
    : null;
  var hoursDiff = duration ? duration.asHours() : 0;

  // var hourlyCost =
  //   currentBooking?.plan_rate && currentBooking?.plan_recurrence === "hourly"
  //     ? currentBooking.plan_rate * hoursDiff
  //     : currentBooking?.plan_rate;
  // hourlyCost = hourlyCost ? hourlyCost : 0;

  // console.log("member?.plan_rate", member?.plan_rate)
  // console.log("member?.plan_recurrence", member?.plan_recurrence)
  var hourlyCost =
    member?.plan_rate && member?.plan_recurrence === "hourly"
      ? member.plan_rate * hoursDiff
      : member?.plan_rate;
  // console.log("hourlyCost 1", hourlyCost)
  hourlyCost = hourlyCost ? hourlyCost : 0;
  // console.log("hourlyCost 2", hourlyCost)

  hourlyCost = (hourlyCost && member?.plan_recurrence  === 'hourly') ? hourlyCost : 0
  // console.log("hourlyCost 2", hourlyCost)

  const cleaningCost = 5
  const serviceCost = 3
  const equipmentCost = 0
  const totalCost = hourlyCost + equipmentCost + cleaningCost + serviceCost


  const maxHours = (capacity: number, recurrence: string | 'daily' | 'weekly' | 'monthly' | 'annual',  hours_left: number) => {
    if (recurrence && capacity){
      switch (recurrence){
        case 'daily':
          // 24 hrs
          hours_left = hours_left ? hours_left : Math.round(16 / capacity)
          return hours_left
        case 'weekly':
          // 168 hrs
          hours_left = hours_left ? hours_left : Math.round(115 / capacity)
          return hours_left
        case 'monthly':
          // 730 hrs
          hours_left = hours_left ? hours_left : Math.round(500 / capacity)
          return hours_left
        case 'yearly':
          // 8760 hrs
          hours_left = hours_left ? hours_left : Math.round(6000 / capacity)
          return hours_left
        default:
          return 0
      }
    }
    return 0
  }

  let hoursBalance = (member?.plan_recurrence && member?.member_balance && currentKitchen?.capacity) ? maxHours(parseInt(currentKitchen.capacity), member.plan_recurrence, member?.member_balance) - hoursDiff : 0


  var { isLoadingBooking, isAddBookingError, isAddBookingSuccess } =
    useAddBooking();
  if (isLoadingBooking || isAddBookingError) {
    return <LoadingScreen height="h-auto" bgColor="inherit" />;
  } else {
    if (handleClose && isOpen && isAddBookingSuccess) {
      handleClose();
    }
    return currentBooking ? (
      <div className="flex w-full p-10  overflow-y-scroll bg-[#D9D9D9]">
        <div className="flex flex-col w-full relative items-start p-4">
          <div className="flex flex-row gap-2">
            <div className="font-raleway text-[25px] text-[#000]  ">
              Details
            </div>
            <div
              className="font-raleway text-[25px] text-[#000] text-sm underline pt-3 hover:cursor-pointer"
              onClick={() => setStep(0)}
            >
              edit
            </div>
          </div>
          {/* TODO: BOOKING REQUEST TITLE */}
          <div className="grid md:grid-cols-4 sm:grid-cols-1 w-full rid-flow-row-dense">
            <div className="col-span-2 w-full">
              <div className="flex flex-col mt-4">
                <p className="font-open-sans font-semibold text-[#000] text-[16px]">
                  Member
                </p>
                <p className="font-open-sans font-normal text-[#000000] text-[13px]">
                  {member?.first_name} {member?.last_name} ({member?.username})
                </p>
                <p className="font-open-sans font-normal text-primary-200 text-[11px] italic">
                  {member?.plan_name} Plan
                </p>
              </div>

              <div className="flex flex-col mt-4">
                <p className="font-open-sans font-semibold text-[#000] text-[16px]">
                  Location
                </p>
                <p className="flex flex-row font-open-sans font-normal text-[#000000] text-[13px]">
                  {/* {currentBooking?.summary} &nbsp; - &nbsp;{" "}
                  {currentBooking.location} */}
                  <LocationIcon className="w-3 h-3 m-1 text-primary-300" />{" "}
                  &nbsp;
                  {currentKitchen?.address ? `${currentKitchen.address}, ` : ``}
                  {currentKitchen?.city ? `${currentKitchen.city}, ` : ``}
                  {/* {kitchen.county ? `${kitchen.county}, `: ``}   */}
                  {currentKitchen?.state ? `${currentKitchen.state} ` : ``}
                  {currentKitchen?.zipcode}
                </p>
              </div>
              
              <div className="flex flex-row gap-10">
                {isMultiple ? (
                  <div className="flex flex-col mt-4">
                    <p className=" font-open-sans font-semibold text-[#000] text-[16px]">
                      Reservation Date
                    </p>
                    <p className="font-open-sans font-light text-[#000] text-[13px]">
                      {currentBooking &&
                      currentBooking?.start_time &&
                      currentBooking?.end_time
                        ? moment(new Date(currentBooking.start_time)).format(
                            "ddd, MMMM Do YYYY"
                          )
                          //  +
                          // " " +
                          // moment(new Date(currentBooking.end_time)).format(
                          //   "MMMM Do YYYY"
                          // )
                        : ""}{" "}
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-col mt-4">
                    <p className=" font-open-sans font-semibold text-[#000] text-[16px]">
                      Reservation Date
                    </p>
                    <p className="font-open-sans font-light text-[#000] text-[13px]">
                      {currentBooking && currentBooking?.start_time
                        ? moment(new Date(currentBooking.start_time)).format(
                            "ddd, MMMM Do YYYY"
                          )
                        : ""}{" "}
                    </p>
                    {/* <div className="flex flex-row text-[8px] gap-5">
                      <p>(start date)</p>
                      <p>(end date)</p>
                    </div> */}
                  </div>
                )}
                <div className="flex flex-col mt-4">
                  <p className=" font-open-sans font-semibold text-[#000] text-[16px]">
                    Reservation Time
                  </p>
                  <p className="font-open-sans font-light text-[#000] text-[13px]">
                    {currentBooking && currentBooking?.start_time
                      ? moment(new Date(currentBooking.start_time))
                          .set(
                            "minutes",
                            moment(
                              new Date(currentBooking.start_time)
                            ).minutes() + Number(beforeBuffer)
                          )
                          .format("h:mm a")
                      : ""}{" "}
                    &nbsp; - &nbsp;{" "}
                    {currentBooking && currentBooking?.end_time
                      ? moment(new Date(currentBooking.end_time))
                          .set(
                            "minutes",
                            moment(
                              new Date(currentBooking.end_time)
                            ).minutes() - Number(afterBuffer)
                          )
                          .format("h:mm a")
                      : ""}
                  </p>
                  {/* <div className="flex flex-row text-[8px] gap-5">
                    <p>-{beforeBuffer} min buffer before</p>
                    <p>+{afterBuffer} min buffer after</p>
                  </div> */}
                </div>
              </div>

              {isMultiple ? (
                <div className="flex flex-row gap-10">
                <div className="flex flex-col mt-4">
                  <p className=" font-open-sans font-semibold text-[#000] text-[16px]">
                    Recurrence
                  </p>
                  <p className="font-open-sans font-light text-[#000] text-[13px]">
                    {currentBooking && currentBooking?.booking_recurrence && capitalizeString(currentBooking?.booking_recurrence) }
                     {
                        currentBooking && currentBooking?.end_recurrence && currentBooking?.booking_recurrence && (
                        <p className="text-primary-300 text-xs"> 
                          (
                            {calculateDateDifference(currentBooking.start_time, currentBooking?.end_recurrence, currentBooking?.booking_recurrence)}
                            &nbsp;
                            {/* {capitalizeString(currentBooking?.booking_recurrence)}
                            &nbsp; */}
                            Occurrences)
                        </p>
                        )
                      }
                  </p>
                </div>
                <div className="flex flex-col mt-4">
                  <p className=" font-open-sans font-semibold text-[#000] text-[16px]">
                    End Recurrence
                  </p>
                  <p className="font-open-sans font-light text-[#000] text-[13px]">
                    {moment(currentBooking?.end_recurrence).format('ddd, MMMM Do YYYY') }
                    
                  </p>
               </div>
                </div>
              ) : null}

              {/* <div className='flex flex-col mt-4'>
                        <p className=' font-open-sans font-bold text-[#000] dark:text-white/60 text-[16px]'>Storage & Equipments</p>
                        <p className='font-open-sans font-light text-[#000000] dark:text-white text-[13px]'>No storage or equipments available</p>
                     </div> */}
              {/* <h1 className="pt-3 text-lg font-semibold">Reservation Summary</h1> */}
              <div className="flex flex-col mt-4">
                <p className="font-open-sans font-semibold text-[#000]  text-[16px]">
                  Additional Equipments
                </p>
                <p className="font-open-sans font-light text-[#000000]  text-[13px]">
                  {equipment}
                </p>
              </div>

              <div className="flex flex-col mt-4">
                <p className="font-open-sans font-semibold text-[#000]  text-[16px]">
                  Notes
                </p>
                {/* <p className="font-open-sans font-light text-[#000000] text-[13px]">
                  {currentBooking?.notes
                    ? currentBooking.notes
                    : "No notes currently"}
                </p> */}
                <p className="flex flex-col font-open-sans font-light text-gray-500 text-sm">
                  Add Reservation Notes
                  {/* {currentBooking?.notes ? currentBooking.notes : "No notes currently"} */}
                    <textarea name="notes" value={bookingNotes} onChange={e => {
                        updateBookingNotes(e.target.value)}
                      } className="border-0 rounded-md w-1/2 h-16 text-sm text-gray-400" />
                </p>
              </div>

              <div>
                  <p className="font-open-sans font-semibold text-[#000]  text-[16px]">Payment</p>
                
                  {
                    (paymentMethods && paymentMethods.length > 0) ? 
                    (
                      <select className='formInput text-gray-500  h-10  w-max mx-1 text-sm ' value={selectedPayMethod} onChange={e => updateSelectedPayMethod(e.target.value)}>
                        <option value="" disabled>
                          -- Select Existing Card --
                        </option>
                        {
                          paymentMethods.map((method: any, i: number) => {
                              return (
                                <>
                                  <option key={i} value={method.id}>
                                      {method.card.brand} {method.type} ending in {method.card.last4 || '---'} &nbsp;
                                      (Exp: {method.card.exp_month}/{method.card.exp_year})
                                  </option>
                                  {/* {getCardIcon(method.card.brand)} &nbsp; */}
                                </>
                              )
                          })
                        }
                      </select>
                    ) : (<p className="font-open-sans font-light text-gray-500 text-sm">No Existing Payment Method</p>)
                  }
                
              </div>

              {/* <div className='flex flex-col mt-4'>
                      <button className='border-[#5DB6CE] bg-white text-[#5DB6CE] w-80'>Contact Community</button>
                     </div> */}
            </div>

            <div className="col-span-1 w-full"></div>

            <div className="flex flex-col items-center">
              {currentBooking?.status ? (
                <div
                  style={{
                    backgroundColor: getBookingStatusColor(
                      currentBooking?.status
                    ),
                  }}
                  className={`rounded-md p-2 m-1 text-[11px] ml-[50%] text-[#fff]/80`}
                >
                  {capitalizeString(currentBooking?.status)}
                </div>
              ) : null}
  
              <div className="flex flex-col font-open-sans text-[#D9D9D9] p-4 col-span-1 bg-[#555555] rounded-md w-8/12">
                <div className="flex flex-row w-full justify-between p-2 m-1">
                  <p className=" font-normal text-[#FFF] text-xl">
                    Price Details
                  </p>
                </div>

                {member?.plan_rate && member?.plan_name ? (
                  <div className="flex flex-row w-full justify-between p-2 m-1">
                    <div className="flex flex-col font-light text-[#FFF] text-left w-full">
                      <span className="text-sm text-white/60 ">
                        {/* <b>{member?.plan_name?.toUpperCase()} PLAN:</b>{" "} */}
                        <b>PLAN</b>
                        &nbsp;
                      </span>
                    </div>
                    <p className=" font-light text-[#FFF]/80 text-[13px] text-right w-full">
                      ${styleThousandCommas(member?.plan_rate)} ({member?.plan_recurrence})
                      {/* <p 
                        className="text-primary-400 text-xs text-right cursor-pointer"
                        onClick={ () => {
                            handleClose()
                            toggleUpdatePlan(true)
                          }
                        }
                      >
                        Change
                      </p> */}
                    </p>
                  </div>
                ) : null}

                <div className="flex flex-row w-full justify-between p-2 m-1">
                  <div className="flex flex-col font-light text-[#FFF] ">
                    Kitchen Rental{" "}
                    <span className="text-[11px] text-white/60 ">
                      {hoursDiff
                        ? "(" +
                          hoursDiff.toString() +
                          ` hour${hoursDiff > 1 ? "s" : ""})`
                        : ""}
                    </span>
                  </div>
                  {/* <p className=" font-light text-[#FFF]/80 ">${styleThousandCommas(hourlyCost)}</p> */}
                  <p className=" font-light text-[#FFF]/80 text-sm">{!hourlyCost && hoursBalance ? `${hoursBalance} hours left` : `$${styleThousandCommas(hourlyCost)}`}</p>
                  
                </div>

                <div className="flex flex-row w-full justify-between p-2 m-1">
                  <p className=" font-light text-[#FFF] ">Equipment</p>
                  <p className=" font-light text-[#FFF]/80 ">{equipmentCost}</p>
                </div>

                <div className="flex flex-row w-full justify-between p-2 m-1">
                  <p className=" font-light text-[#FFF] ">Cleaning Fee</p>
                  <p className=" font-light text-[#FFF]/80 ">{cleaningCost}</p>
                </div>

                <div className="flex flex-row w-full justify-between p-2 m-1">
                  <p className=" font-light text-[#FFF] ">Service Fee</p>
                  <p className=" font-light text-[#FFF]/80 ">{serviceCost}</p>
                </div>

                <div className="flex flex-row w-full justify-between p-2 m-1">
                  <p className=" font-light text-[#5DB6CE] ">Member Discount</p>
                  <p className=" font-light text-[#5DB6CE]/80 ">- 0</p>
                </div>

                <div className="flex flex-row w-full justify-between p-2 m-1 border-t-[1px] border-white/60">
                  <p className=" font-light text-[#fff] ">Total (USD)</p>
                  <p className=" font-light text-[#fff]/80 ">
                    $ {styleThousandCommas(totalCost)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="bg-back-400 min-h-screen w-screen">
        <NotFound
          dataName="Reservation"
          previousPath="/kitchens/reservations"
        />
      </div>
    );
  }
};

export default BookingConfirmation;
