import { useQuery, useMutation} from '@tanstack/react-query'
import { 
        getMemberships, getMembershipRequests, 
        fetchMemberByID, addCommunityMembers,
        updateCommunityMembership, getMemberReviews,
        addMembershipCredentials, fetchMemberCredentials,
        createBooking, updateBookingData,
        fetchCommunityBookings, fetchBookingByID, getAllMessages,
        sendMessageToUser, generateAIResponse,
        fetchPaymentMethods
     } from '../api/crud'
import { MembershipSchema, BookingSchema, MessageSchema, GroupedMessageListType, CredentialSchema } from "../data/types";
import { useMemberState } from '../contexts/membercontext'


const staleTime = 1000
const isEnabled = true

export const useLoadRequests = () => {
    let { communityId, setMembershipRequests } = useMemberState()
    const {data: membershipRequests, refetch: reloadRequests, isLoading: isLoadingRequests} = useQuery({ 
        queryKey: ['membershipRequests', communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching membership requests")
                const res = await getMembershipRequests(communityId)
                setMembershipRequests(res)
                return res
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return {isLoadingRequests, membershipRequests, reloadRequests}
}


export const useLoadMemberships = () => {
    let { communityId, setMemberships } = useMemberState()
    const {data: memberships, refetch: reloadMemberships, isLoading: isLoadingMemberships} = useQuery({ 
        queryKey: ['memberships', communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching all memberships")
                const res = await getMemberships(communityId)
                setMemberships(res)
                return res
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return {isLoadingMemberships, memberships, reloadMemberships}
}


export const useLoadMember = (username: string) => {
    let { communityId, updateCurrentMember } = useMemberState()
    const {data: currentMember, isLoading: isLoadingCurrentMember} = useQuery({ 
        queryKey: ['currentMember', communityId, username], 
        queryFn: async () => {
            console.log("fetching membership by username")
            const res = await fetchMemberByID(communityId, username)
            updateCurrentMember(res)
            return res
        }
    })
    return {isLoadingCurrentMember, currentMember}
}


export const useAddMembership = () => {
    let { communityId } = useMemberState()
    const { reloadMemberships } = useLoadMemberships()
    const { reloadRequests } = useLoadRequests()

    const mutation = useMutation({ 
        mutationFn: async (membership: MembershipSchema[]) => {
            console.log("add member")
            const res = await addCommunityMembers(communityId, membership)
            await reloadMemberships()
            await reloadRequests()
            return res
    }})

    const addMembership = (membershipUpdate: MembershipSchema[]) => {
        mutation.mutate(membershipUpdate)
        return mutation.isSuccess
    }

    const isLoadingMembership = mutation.isPending
    const isAddMembershipError = mutation.isError
    const isAddMembershipIdle = mutation.isIdle
    const isAddMembershipSuccess = mutation.isSuccess
    return { addMembership, isLoadingMembership, isAddMembershipSuccess, isAddMembershipError, isAddMembershipIdle }
}


export const useUpdateMembership = () => {
    var { communityId } = useMemberState()
    const { reloadMemberships } = useLoadMemberships()
    const { reloadRequests } = useLoadRequests()

    const mutation = useMutation({ 
        mutationFn: async (updateData: MembershipSchema) => {
            console.log("update member")
            const res = await updateCommunityMembership(communityId, updateData)
            console.log("update member res", res)
            reloadMemberships()
            reloadRequests()
            return res
    }})

    const updateMembership = (membershipUpdate: MembershipSchema) => {
        mutation.mutate(membershipUpdate)
        return mutation?.isSuccess
    }

    const isLoadingMembership = mutation.isPending
    return { updateMembership, isLoadingMembership }
}


export const useAddCredential = () => {
    var { communityId } = useMemberState()
    type mutationProps = {
        username: string
        credential: CredentialSchema
    }
    const mutation = useMutation({ 
        mutationFn: async ({username, credential}: mutationProps) => {
            console.log("add member credentials")
            const res = await addMembershipCredentials(communityId, username, credential)
            return res
    }})

    const addMemberCredential = (username: string, credential: CredentialSchema) => {
        mutation.mutate({username, credential})
        return mutation?.data ? true : false
    }

    const isLoadingCredential = mutation.isPending
    return { addMemberCredential, isLoadingCredential }
}


export const useGetCredentials = (memberUsername: string) => {
    var { communityId } = useMemberState()

    const {data: memberCredentials, refetch: reloadCredentials, isLoading: isLoadingCredentials} = useQuery({ 
        queryKey: ['credentials', communityId, memberUsername], 
        queryFn: async () => {
            console.log("fetching credentials")
            const res = await fetchMemberCredentials(communityId, memberUsername)
            return res
        },
        staleTime,
        enabled: isEnabled
    })

    return { isLoadingCredentials, memberCredentials, reloadCredentials}
}


export const useGetMemberReviews = (memberUsername: string) => {
    const {data: memberReviews, refetch: reloadReviews, isLoading: isLoadingReviews} = useQuery({ 
        queryKey: ['reviews', memberUsername], 
        queryFn: async () => {
            console.log("fetching member reviews")
            const res = await getMemberReviews(memberUsername)
            return res
        },
        staleTime,
        enabled: isEnabled
    })

    return { isLoadingReviews, memberReviews, reloadReviews}
}


export const useLoadBookings = () => {
    let { communityId, updateBookings } = useMemberState()
    const {data: bookings, refetch: reloadBookings, isLoading: isLoadingBookings} = useQuery({ 
        queryKey: ['bookings', communityId], 
        queryFn: async () => {
            console.log("fetching bookings")
            const res = await fetchCommunityBookings(communityId)
            updateBookings(res)
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingBookings, bookings, reloadBookings }
}


export const useLoadBooking = (bookingId: string) => {
    let { communityId, updateCurrentBooking } = useMemberState()
    const {data: currentBooking, refetch: reloadCurrentBooking, isLoading: isLoadingBooking} = useQuery({ 
        queryKey: ['currentBooking', communityId, bookingId], 
        queryFn: async () => {
            console.log("fetching booking by id")
            const res = await fetchBookingByID(communityId, bookingId)
            updateCurrentBooking(res)
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingBooking, currentBooking, reloadCurrentBooking}
}



export const useAddBooking = () => {
    var { reloadBookings } = useLoadBookings()

    const mutation = useMutation({ 
        mutationFn: async (booking: BookingSchema) => {
            console.log("add booking") 
            const res = await createBooking(booking)
            reloadBookings()
            return res
    }})

    const addNewBooking = (bookingData: BookingSchema) => {
        mutation.mutate(bookingData)
        return mutation?.data ? true : false
    }

    const isLoadingBooking = mutation.isPending
    const isAddBookingSuccess = mutation.isSuccess
    const isAddBookingError = mutation.isError
    return { addNewBooking, isLoadingBooking, isAddBookingSuccess, isAddBookingError }
}


export const useUpdateBooking = (bookingId: string) => {
    var { communityId } = useMemberState()
    var { reloadCurrentBooking } = useLoadBooking(bookingId)

    const mutation = useMutation({ 
        mutationFn: async (updateData: BookingSchema) => {
            console.log("update booking") 
            const res = await updateBookingData(communityId, bookingId, updateData)
            reloadCurrentBooking()
            return res
    }})

    const updateBooking = (bookingUpdate: BookingSchema) => {
        mutation.mutate(bookingUpdate)
        return mutation?.data ? true : false
    }

    const isLoadingBooking = mutation.isPending
    return { updateBooking, isLoadingBooking }
}


export const useLoadMessages = () => {
    let {  communityId, currentChatMember,
            changeChatMember,
            updateMessageThread, updateAllMessages, 
            updateAllsThreadsByMember } = useMemberState()

    const handleGroupMessages = (allMessages: MessageSchema[]): GroupedMessageListType => {
        const groupMessages = allMessages.reduce((result: GroupedMessageListType, message: MessageSchema) => {
            const { username } = message; // Destructure the object, excluding the 'username' property
            if (!result[username]) {
                result[username] = []; // Create an empty array for the username if it doesn't exist
            }
            result[username].push(message); // Add the object to the corresponding username array
            return result;
        }, {})
        return groupMessages
    }

    const {data: messages, refetch: reloadMessages, isLoading: isLoadingMessages} = useQuery({ 
        queryKey: ['messages', communityId], 
        queryFn: async () => {
            console.log("fetching messages")
            const res = await getAllMessages(communityId)
            updateAllMessages(res)

            let groupMessages = handleGroupMessages(res)
            updateAllsThreadsByMember(groupMessages)

            var chatbotMessages = groupMessages[currentChatMember]
            // if (!currentChatMember){
            //     changeChatMember('chatbot')
            //     chatbotMessages = groupMessages['chatbot']
            // }
            
            chatbotMessages = chatbotMessages ? chatbotMessages : []
            updateMessageThread(chatbotMessages)
            return res
        },
        staleTime,
        enabled: isEnabled
    })

    const switchMember = async (member: string) => {
        if (member !== currentChatMember){
            await changeChatMember(member)
            reloadMessages()
        }
      
    }
    return {isLoadingMessages, messages, switchMember, reloadMessages}
}



export const useSendMessage = () => {
    var { communityId } = useMemberState()
    var { reloadMessages } = useLoadMessages()

    type messageMutationType = {
        username: string
        content: string
    }

    const mutation = useMutation({ 
        mutationFn: async ({username, content}: messageMutationType) => {
            console.log("send message") 
            if (username === "chatbot"){
                const res = await generateAIResponse(communityId, content) 
                await reloadMessages()
                return res
            } else {
                const res = await sendMessageToUser(communityId, username, content) 
                await reloadMessages()
                return res
            }
            
    }})

    const sendMessage = (username: string, content: string) => {
        mutation.mutate({username, content})
        return mutation.isSuccess
    }

    // const getAIMessage = (username: string, content: string) => {
    //     mutation.mutate({username, content})
    //     return true
    // }
    const isLoadingMessage = mutation.isPending
    return { sendMessage, isLoadingMessage }
}



export const useGetPaymentMethods = (username: string) => {
    // var {authUser}  = useAuthState()
    // let memberUsername = authUser?.username ? authUser.username : ''
    const {data: paymentMethods, refetch: reloadPaymentMethods, isLoading: isLoadingPaymentMethods} = useQuery({ 
        queryKey: ['payMethods', username], 
        queryFn: async () => {
            console.log("fetching payMethods")
            const res = await fetchPaymentMethods(username)
            return res ? res : []
        },
        staleTime,
        enabled: isEnabled
    })

    const getPaymentMethod = (booking: BookingSchema) => {
        let paymentMethodId = booking.payment_method
        return paymentMethods ? paymentMethods.find((obj: any) => obj['id'] === paymentMethodId) : {}

    }

    return { isLoadingPaymentMethods, paymentMethods, getPaymentMethod, reloadPaymentMethods}
}