import { ReactNode } from 'react';
import { MemberStateProvider } from './membercontext'
import { KitchenProvider } from './kitchencontext'
import { AuthProvider, useAuthState } from './authcontext'
import {PreferencesProvider} from './preferencecontext'
// import { MemberProvider}  from './membercontext'

type PropsChildren = {children?: ReactNode}

const ApplicationProvider = (props: PropsChildren) => {
    return (
        <AuthProvider>
            <CommunityProvider>
                {props.children}
            </CommunityProvider>
        </AuthProvider>
    )
}


const CommunityProvider = (props: PropsChildren) => {
    var { authUser, primaryCommunity } = useAuthState()
    var communityId = (primaryCommunity && primaryCommunity.id) ? primaryCommunity.id : ""
    return (
        <PreferencesProvider communityId={communityId}>
             {/* primaryCommunity={primaryCommunity ? primaryCommunity : {}} */}
            <KitchenProvider authUser={authUser ? authUser : {}} communityId={communityId}> 
                <MemberStateProvider communityId={communityId}>
                    {/* <MemberProvider> */}
                        {props.children}
                    {/* </MemberProvider> */}
                </MemberStateProvider>
            </KitchenProvider>
        </PreferencesProvider>
    )
}

export default ApplicationProvider
