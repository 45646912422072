// import { HelpOutlineOutlined } from '@mui/icons-material';
// import React, {useState} from 'react'

const ProgressBar = ({ currentStep, setCurrentStep }: any) => {
  const sections = 5
  var defaultNOfQuestions = 2
  var activeSection = Math.floor(currentStep / defaultNOfQuestions)
  const percentSteps = (defaultNofQuestions: number) => 100 / defaultNofQuestions
  var progress = ((currentStep % defaultNOfQuestions) + 1) * percentSteps(defaultNOfQuestions); // 0 for step 0, 50 for step 1
  

  if (currentStep >= 8){
    activeSection = 4
    defaultNOfQuestions = 3
    if (currentStep === 8) {
      progress = percentSteps(defaultNOfQuestions)
    } else {
      progress = (((currentStep % 3) + 1) * percentSteps(defaultNOfQuestions)) + percentSteps(defaultNOfQuestions) 
    }
  }

  // const [clickedSection, setSection] = useState();
  // const [isClicked, setClicked] = useState(false);

  const progressBarStyle = {
    width: `${progress}%`,
    height: '5px'
  };


  const categoryLabels = [
    "Experience",
    "Location",
    "Kitchen",
    "Booking",
    "Miscellaneous"
  ]
  
  const handleSectionClick = (index: number) => {
    switch(index){
      case 0:
        return(
          <div>{categoryLabels[index]}</div>
        )
      case 1:
        return(
          <div>{categoryLabels[index]}</div>
        )
      case 2:
        return(
          <div>{categoryLabels[index]}</div>
        )
      case 3:
        return(
          <div>{categoryLabels[index]}</div>
        )
      case 4:
        return(
          <div>{categoryLabels[index]}</div>
        )
    }
  
  }

  const circles = (amount: number) => {
    const circles = [];
    for (let i = 0; i < amount; i++) {
      circles.push(
        <div key={i} className="w-1 h-1 rounded-full bg-black mx-1"></div>
      );
    }
    return <div className="flex">{circles}</div>;
  };

  const handleClick = (index:number) => {
    switch(index){
      case 0:
        setCurrentStep(0);
        break;
      case 1:
        setCurrentStep(2);
        break;
      case 2:
        setCurrentStep(4);
        break;
      case 3:
        setCurrentStep(6);
        break;
      case 4:
        setCurrentStep(8);
        break;
    }
  }

  return (
   
      <div className="mx-60 my-5">
        <div className="grid grid-flow-col ">
          {[...Array(sections)].map((_, sectionIndex) => (
            <div key={sectionIndex} className={`${(sectionIndex === activeSection) ? 'text-primary-300' : ''} cursor-pointer text-center`} onClick={() => handleClick(sectionIndex)}>
              <h1 className="mb-4 pr-48">{handleSectionClick(sectionIndex)}</h1>
              <div className='grid grid-flow-col'>
                <div className={`w-16 h-16 rounded-full ${(sectionIndex <= activeSection) ? "bg-primary-400" : "bg-gray-300" }`}>
                  {sectionIndex === activeSection && (
                    <div
                        className={`h-12 bg-primary-300 absolute top-0 left-0 rounded-l-full`}
                      style={progressBarStyle}
                    ></div>
                  )}

                    {/* <div
                        className={`h-12 ${sectionIndex === activeSection ? 'bg-gray-300' : ' bg-primary-300'}  absolute top-0 left-0 rounded-l-full`}
                        style={progressBarStyle}
                    >
                    
                    </div> */}

                  <div className=" text-black text-center justify-center text-4xl mt-3 mr-1">
                    {sectionIndex + 1}
                  </div>
                </div>
                {(sectionIndex < 4) ? (
                  <div className='mt-8'>{circles(5)}</div>
                  
                ): null}
                
              </div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default ProgressBar;