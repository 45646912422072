import React from 'react'
import { useNavigate } from 'react-router-dom';
import moment from 'moment'

import { BookingCardProps } from "../data/types";
import { getBookingStatusColor } from "../utils/helpers";


const BookingCard: React.FC<BookingCardProps> = props => {
  var navigate = useNavigate();

  var { booking }: BookingCardProps = props

  const handleViewDetail = async () => {
    if (booking && booking?.id){
      navigate(`/kitchens/reservations/${booking.id}`)
    }
    
  }

  const capitalizeWords = (str: string) => {
    if (str){
      // Split the string into an array of words
      const words = str.split(' ');
      // Capitalize the first letter of each word
      const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      // Join the capitalized words back into a string
      const capitalizedString = capitalizedWords.join(' ');
      return capitalizedString;      
    }
  }


  var booking_status = booking?.status ? booking.status : ""

  return (
    <div className='flex flex-col text-[#013847] bg-white h-[300px] sm:h-[500px] w-[300px] sm:w-[370px] m-auto shadow-md justify-evenly items-center rounded-md p-1'>
      
      {
        (booking?.community_logo) ? (
        <img className='h-[150px] rounded-md m-1' src={booking.community_logo} alt="booking_logo" />
        ) : (
        <div className='h-[150px] w-[80%] bg-[#eee] rounded-md m-1'></div>
        )
      }
      <div className='text-center text-[15px]'>{booking.summary}</div>
      <div className='text-center text-[14px]'>{booking.location}</div>
      <div className='flex flex-row justify-evenly w-full text-[14px] font-bold'>
          <div>Start Date</div>
          <div>End Date</div>
      </div>
      <div className='flex flex-row justify-evenly w-full text-[12px]'>
          <div>{moment(new Date(booking?.start_time)).format("MMMM Do YYYY, h:mm a")}</div>
          <div>{moment(new Date(booking?.end_time)).format("MMMM Do YYYY, h:mm a")}</div>
      </div>
      
      <div style={{backgroundColor: getBookingStatusColor(booking_status)}}  className={`${(booking.status === "completed") ? 'text-[#D9D9D9]' : 'text-[#fff]/80'} mx-auto px-2 rounded-md`}>{capitalizeWords(booking_status)}</div>
      <button className='bg-[#013847] text-[#D9D9D9] border-0 m-1 px-2 rounded-md' onClick={() => handleViewDetail()}>View Details</button>
      {
        (booking.status === "canceled" || booking.status === "completed") ? null : <button className='bg-[#D9D9D9] text-[#013847] border-0 m-1 px-2 rounded-md'>Modify Reservation</button>
      }
    </div>
    )
}


export default BookingCard