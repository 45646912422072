import React, {useEffect} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

import { FaUserFriends } from 'react-icons/fa'
import {
    //  AiFillHome, AiOutlineClose, 
     AiOutlineUser } from 'react-icons/ai'
import { BiExit } from "react-icons/bi";

// import KitchenIcon from '@mui/icons-material/Kitchen';

import { useAuthState } from '../contexts/authcontext'
import { useLoadRequests } from "../hooks/memberhooks";
import { useLoadMemberships } from "../hooks/memberhooks";
import { useLoadAuthUser } from "../hooks/authhooks";

// import { ReactComponent as HomeIcon } from '../icons/Home.svg'
import { ReactComponent as CommunityIcon } from '../icons/Community.svg'
import { ReactComponent as KitchenIcon } from '../icons/KitchenIcon.svg'
import { ReactComponent as MessageIcon } from '../icons/Message.svg'
import { ReactComponent as BookingsIcon } from '../icons/Bookings.svg'
import { ReactComponent as SettingsIcon } from '../icons/Settings.svg'
// import { ReactComponent as LoginIcon } from '../icons/Login.svg'
import { ReactComponent as OrderIcon } from '../icons/OrderIcon.svg'
import homecook_logo from '../assets/img/homecook_logo.png'



// import homecook_logo from '../assets/img/homecook_logo.png'
import DarkModeSwitcher from '../components/switcher'
const account_avatar: string = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`



type SideBarOptions = {
    name: string,
    path: string,
    icon: React.FC<React.SVGProps<SVGSVGElement>>
    image?: string
}


var unauthDrawerItems: SideBarOptions[] = [
    {
        name: 'Logout',
        path: '',
        icon: BiExit
    }
]

var authDrawerItems: SideBarOptions[] = [
    {
        name: 'Home',
        path: '/',
        icon: CommunityIcon
    },
    {
        name: 'Kitchen',
        path: '/kitchens',
        icon: KitchenIcon
    },
    {
        name: 'Members',
        path: '/kitchens/members',
        icon: FaUserFriends
    },
    {
        name: 'Inventory',
        path: '/inventory',
        icon: OrderIcon
    },
    {
        name: 'Reservations',
        path: '/kitchens/reservations',
        icon: BookingsIcon
    },
    {
        name: 'Messages',
        path: '/messages',
        icon: MessageIcon
    },
    {
        name: 'Settings',
        path: '/settings',
        icon: SettingsIcon
    },
    {
        name: 'Account',
        path: '/account',
        icon: AiOutlineUser,
        image: account_avatar
    },
    {
        name: 'Logout',
        path: '',
        icon: BiExit
    }
]



const SideBar = () => {

 var navigate = useNavigate();

 var { isloggedin, isverified, primaryCommunity } = useAuthState()
 useLoadMemberships()
 var { membershipRequests } = useLoadRequests()
 var { signOutUser } = useLoadAuthUser()
//  console.log("membershipRequests", membershipRequests)

 var view_postonboard = isloggedin && isverified
 var nMembershipRequests: number = membershipRequests ? membershipRequests.length : 0
//  var view_otp_form = isloggedin && !isverified

useEffect(()=> {

}, [nMembershipRequests, view_postonboard])


 const clickNav = (route: string, itemName: string) => {
    navigate(route)
    if (itemName === "Logout") {
        // Handle logout logic here
        // For example, reset the logged-in state to false
        signOutUser()
      }
}

   const location = useLocation()
   var currentLocation = location.pathname
   

   var drawerItems = view_postonboard ? authDrawerItems : unauthDrawerItems

  return (
    <div className='w-1/12 sm:w-20 top-0 left-0 bottom-0 mt-0 mb-0 ml-0 sm:h-screen fixed bg-[#013847] duration-300 '>
            <img src={homecook_logo} className='relative mx-auto w-20' alt='/' />
            <nav>
                <ul className='flex flex-col text-gray-800'>
                    { 
                        drawerItems.map((item, i) => {
                        return (
                                    <li key={i} className={`text-xl py-3.5 flex cursor-pointer font-open-sans`} onClick={() => clickNav(item.path, item.name)}>
                                        {
                                            (item.name === 'Settings') ? (
                                                <item.icon className={`relative m-auto w-6 mt-40 ${currentLocation === item.path ? 'text-[#5DB6CE]' : 'text-[#D9D9D9] hover:text-[#b8dae4]'}`} />
                                            ) : (<>
                                             {
                                                (item.name === 'Account') ? (
                                                    <img className='relative m-auto w-6 rounded-full' alt={item.name} src={primaryCommunity?.logo ? primaryCommunity.logo : homecook_logo} />  
                                                    ) : ( <>
                                                        {
                                                            (item.name === 'Members' && nMembershipRequests) ? (
                                                                <div className='flex flex-row ml-auto'>
                                                                    <item.icon className={`relative w-6 ${currentLocation === item.path ? 'text-[#5DB6CE]' : 'text-[#D9D9D9] hover:text-[#b8dae4]'}`} />
                                                                    <span className="font-open-sans flex bg-[#0B9821] text-slate-50 mx-1 p-auto w-6 h-6 justify-center rounded-lg text-[11px]">
                                                                        {nMembershipRequests}
                                                                    </span> 
                                                                </div>
                                                            ) : (
                                                            <> 
                                                            {
                                                                (item.name === 'Logout') ? (
                                                                    <item.icon className={` rotate-180 relative m-auto w-6 ${currentLocation === item.path ? 'text-[#5DB6CE]' : 'text-[#D9D9D9] hover:text-[#b8dae4]'}`} />
                                                                ) : (<>
                                                                    {
                                                                        (item.name === 'Kitchen') ? (
                                                                            <item.icon className={` rotate-180 relative m-auto w-5 ${currentLocation === item.path ? 'text-[#5DB6CE]' : 'text-[#D9D9D9] hover:text-[#b8dae4]'}`} />
                                                                        ) : (<>
                                                                            <item.icon className={`relative m-auto w-6 ${currentLocation === item.path ? 'text-[#5DB6CE]' : 'text-[#D9D9D9] hover:text-[#b8dae4]'}`} />
                                                                        </>)
                                                                    }
                                                                </>)
                                                            }
                                                            </>)
                                                        }
                                                    </> )
                                             }

                                        
                                            
                                            </>)
                                        }

                                       
                                           
                                    </li>
                                )
                        })
                    }
           
                    <DarkModeSwitcher />

                </ul> 
            </nav>
        </div>
  )
}

export default SideBar


// {
//     (item.name === 'Logout') ? (
//         <item.icon className={` rotate-180 relative m-auto w-6 ${currentLocation === item.path ? 'text-[#5DB6CE]' : 'text-[#D9D9D9]'}`} />
//     ) : (
//         (currentLocation === 'Kitchen') ? (
//             <item.icon className={`relative m-auto w-6 ${currentLocation.includes(item.path) ? 'text-[#5DB6CE]' : 'text-[#D9D9D9]'}`} />
//         ) : (
//             <item.icon className={`relative m-auto w-6 ${currentLocation === item.path ? 'text-[#5DB6CE]' : 'text-[#D9D9D9]'}`} />
//         )
//     )
// }