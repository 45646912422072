import { useState } from "react";
import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";

import Search from "../molecules/Search";
import { DefaultModal } from "./AppModal";
import { useKitchenState } from "../contexts/kitchencontext";
// import StaggeredDropDown from "../molecules/Dropdown";

type SearchBoxProps = {
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  searchType?:
    | "members"
    | "requests"
    | "kitchens"
    | "reservations"
    | "inventory";
};

const SearchBox: React.FC<SearchBoxProps> = ({ setIsOpen, searchType}) => {
  let { kitchens } = useKitchenState();
  const [defaultIsOpen, defaultSetIsOpen] = useState(false);
  const navigate = useNavigate();
  const numberOfKitchens = kitchens?.length;
  let buttonLabel = (
    <>
      <AddIcon />
      Add New Kitchen
    </>
  );
  if (searchType === "members") {
    buttonLabel = (
      <>
        <AddIcon />
        Add Member
      </>
    );
  } else if (searchType === "reservations") {
    buttonLabel = (
      <>
        <AddIcon />
        New Reservation
      </>
    );
  } else if (searchType === "requests") {
    buttonLabel = <>View Active Members</>;
  } else if (searchType === "inventory") {
    buttonLabel = (
      <>
        <AddIcon />
        Add Supplies / Inventory
      </>
    );
  }
  const renderAddButton =
    searchType !== "kitchens" ||
    (searchType === "kitchens" && numberOfKitchens === 0);

  return (
    <div className="flex w-full">
      <div className="w-1/2 ">
        <Search searchType={searchType} />
      </div>
      <div className="pt-2 text-right w-1/2 ">
        {renderAddButton && !(searchType === "reservations") && (
          <button
            onClick={() =>
              setIsOpen !== undefined
                ? setIsOpen(true)
                : navigate("/kitchens/members")
            }
            className="buttonsPrimary w-30 h-10 p-2"
            data-testid="add-button"
          >
            {buttonLabel}
          </button>
        )}
      </div>
      <DefaultModal
        isOpen={defaultIsOpen}
        closeAppModal={() => defaultSetIsOpen(false)}
      />
    </div>
  );
};

export default SearchBox;
